<template>
  <v-container>
    <v-row class="my-1 mb-5 pa-0 ma-0 justify-end">
      <v-col cols="6" class="pt-5 text-center"> </v-col>

      <v-col cols="3" class="text-right mr-2">
        <v-btn
          class="py-6"
          :to="{ name: 'Yard.Information' }"
          rounded
          color="#f4bb00"
          dark
          style="text-transform: none !important"
          width="200px"
        >
          <v-icon class="pt-1">mdi-magnify</v-icon><b> ดูข้อมูล Yard</b>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-right">
        <v-btn
          class="py-6"
          :to="{ name: 'Yard.Add' }"
          rounded
          color="app-theme"
          dark
          style="text-transform: none !important"
          width="200px"
        >
          <v-icon>mdi-plus</v-icon>
          <b> เพิ่ม Yard</b>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          single-expand
          hide-default-footer
          :headers="headers"
          :items="items"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="pageSize"
          @page-count="pageCount = $event"
          :header-props="{
            sortIcon: 'mdi-menu-up',
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            'items-per-page-options': rowsPerPage,
          }"
        >
          <template v-slot:item.companyAddress="{ item }">
            <div class="text-left no-wrap" style="width: 90%">
              <span>
                {{ item.companyAddress }}
              </span>
            </div>
          </template>
          <template v-slot:item.companyAddrTax="{ item }">
            <div class="text-left no-wrap" style="width: 90%">
              <span>
                {{ item.companyAddrTax }}
              </span>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <router-link
              :to="{ name: 'Yard.Edit', params: { id: item.id } }"
              class="text-decoration-none mr-3"
            >
              <v-btn dark color="app-theme"><b>แก้ไข</b></v-btn>
            </router-link>

            <v-btn
              dark
              @click="deleteDialog(item.id)"
              color="app-danger"
              min-width="20"
              class="btn-p-10"
              ><v-icon color="app-primary">mdi-trash-can</v-icon></v-btn
            >
          </template>
          <template v-slot:footer> </template>
        </v-data-table>
      </v-col>
    </v-row>

    <DialogDelete :dialog.sync="showDialog" :deleteId="deleteId"></DialogDelete
    ><pagination
      ref="Pagination"
      :page="page"
      :page-size="pageSize"
      @changePage="page = $event"
      @changePageSize="pageSize = $event"
    />
  </v-container>
</template>
<style scoped>
.nowrap {
  white-space: nowrap;
}
</style>
<script>
import DialogDelete from "@/components/DialogDelete";
import Pagination from "@/components/Pagination";
export default {
  components: {
    DialogDelete,
    Pagination,
  },

  data: () => ({
    name: "",
    companyName: "",

    tel: "",
    email: "",
    showDialog: false,
    deleteId: null,
    page: 1,
    pageCount: 0,
    pageSize: 10,
    itemsPerPage: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "ชื่อ Yard",
        align: "center",
        value: "yardName",
      },
      {
        text: "ที่อยู่ที่ตั้ง",
        align: "center",
        value: "companyAddress",
        width: "20%",
      },
      {
        text: "ที่อยู่ออกใบกำกับภาษี",
        align: "center",
        value: "companyAddrTax",
        width: "20%",
      },
      {
        text: "เบอร์โทรศัพท์",
        align: "center",
        value: "tel",
        width: "15%",
      },
      {
        text: "จัดการ",
        sortable: false,
        align: "center",
        value: "actions",
        width: "180px",
      },
    ],
    items: [],
  }),
  mounted() {
    this.$store.state.title = "จัดการข้อมูล Yard";
    this.$store.state.showBackBtn = false;
    this.get();
  },
  watch: {
    page() {
      this.get();
    },
    pageSize() {
      this.get();
    },
  },
  methods: {
    search() {
      this.$store.state.loading = true;
      const formData = new FormData();
      this.$root
        .appApi({
          method: "POST",
          url: "admin/yard/search",
          data: formData,
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                const addr = `${item.addr1} ${item.addr2} ${item.addrChangwat.nameTh} ${item.addrAmphoe.nameTh} ${item.addrTambon.nameTh} ${item.addrZipCode}`;
                let addrTax = `${item.addrTax1} ${item.addrTax2} ${item.addrTaxChangwat.nameTh} ${item.addrTaxAmphoe.nameTh} ${item.addrTaxTambon.nameTh} ${item.addrTaxZipCode}`;
                if (item.isSameAddr) {
                  addrTax = "ที่อยู่เดียวกับที่ตั้ง";
                }
                return {
                  id: item.id,
                  yardName: item.name,
                  companyAddress: addr,
                  companyAddrTax: addrTax,
                  tel: item.tel,
                };
              });
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data.length
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard/all",
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                const addr = `${item.addr1} ${item.addr2} ${item.addrChangwat.nameTh} ${item.addrAmphoe.nameTh} ${item.addrTambon.nameTh} ${item.addrZipCode}`;
                let addrTax = `${item.addrTax1} ${item.addrTax2} ${item.addrTaxChangwat.nameTh} ${item.addrTaxAmphoe.nameTh} ${item.addrTaxTambon.nameTh} ${item.addrTaxZipCode}`;
                if (item.isSameAddr) {
                  addrTax = "ที่อยู่เดียวกับที่ตั้ง";
                }
                return {
                  id: item.id,
                  yardName: item.name,
                  companyAddress: addr,
                  companyAddrTax: addrTax,
                  tel: item.tel,
                };
              });
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data.length
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    confirmDelete(id) {
      this.$store.state.loading = true;
      this.$root
        .appApi({ method: "DELETE", url: "admin/yard/" + id })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.$root.getSystemMessage(resp.data.message);
          }
          this.get();
        })
        .catch((err) => {
          if (err.message) {
            this.$root.getErrorSystemMessage(err.message);
          } else {
            this.$root.getErrorSystemMessage(err);
          }
        });
    },
    deleteDialog(id) {
      this.showDialog = true;
      this.deleteId = id;
    },
  },
};
</script>
