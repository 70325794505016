<template>
  <v-container>
    <v-row class="my-1 mb-5" align="center">
      <v-col cols="1" class="pt-5 text-center">
        <h3 style="color: #041e41">ค้นหา</h3>
      </v-col>
      <v-col cols="6" class="pr-15">
        <v-row>
          <v-col cols="8">
            <v-text-field
              label="ค้นหาบริษัทลูกค้า"
              hide-details="auto"
              :append-icon="'mdi-magnify'"
              color="app-theme"
              outlined
              v-model="keyword"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4"> </v-col>
        </v-row>
      </v-col>

      <v-col cols="5" class="text-right">
        <v-btn
          class="pa-6 mr-5"
          outlined
          rounded
          color="app-theme"
          dark
          style="text-transform: none !important"
        >
          <b> ค้นหา</b>
        </v-btn>
        <v-btn
          class="py-6"
          :to="{ name: 'CustomerCompany.Add' }"
          rounded
          color="app-theme"
          dark
          style="text-transform: none !important"
        >
          <v-icon>mdi-plus</v-icon>
          <b> เพิ่มบริษัทลูกค้า</b>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      single-expand
      hide-default-footer
      :headers="headers"
      :items="items"
      class="elevation-1"
      :page.sync="page"
      :items-per-page="pageSize"
      @page-count="pageCount = $event"
      :header-props="{
        sortIcon: 'mdi-menu-up',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        'items-per-page-options': rowsPerPage,
      }"
    >
      <template v-slot:item.actions="{ item }">
        <router-link
          :to="{ name: 'CustomerCompany.Edit', params: { id: item.id } }"
          class="text-decoration-none mr-3"
        >
          <v-btn dark color="app-theme"><b>แก้ไข</b></v-btn>
        </router-link>

        <v-btn
          dark
          @click="deleteDialog(item.id)"
          color="app-danger"
          min-width="20"
          class="btn-p-10"
          ><v-icon color="app-primary">mdi-trash-can</v-icon></v-btn
        >
      </template>
      <template v-slot:footer> </template>
    </v-data-table>

    <DialogDelete :dialog.sync="showDialog" :deleteId="deleteId"></DialogDelete>
    <pagination
      ref="Pagination"
      :page="page"
      :page-size="pageSize"
      @changePage="page = $event"
      @changePageSize="pageSize = $event"
    />
  </v-container>
</template>
<style scoped>
.v-data-table >>> tr > td {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
</style>
<script>
import DialogDelete from "@/components/DialogDelete";
import Pagination from "@/components/Pagination";
export default {
  components: {
    DialogDelete,
    Pagination,
  },
  data: () => ({
    keyword: "",
    showDialog: false,
    deleteId: null,
    page: 1,
    pageCount: 0,
    pageSize: 10,
    itemsPerPage: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "บริษัทลูกค้า",
        align: "center",
        value: "name",
      },
      {
        text: "ที่อยู่ที่ตั้ง",
        align: "center",
        value: "companyAddress",
      },
      {
        text: "ที่อยู่ออกใบกำกับภาษี",
        align: "center",
        value: "companyAddrTax",
      },

      {
        text: "จัดการ",
        sortable: false,
        align: "center",
        value: "actions",
        width: "180px",
      },
    ],
    items: [],
  }),
  mounted() {
    this.$store.state.title = "จัดการบริษัทลูกค้า";
    this.$store.state.showBackBtn = false;
    this.get();
  },

  watch: {
    page() {
      this.search();
    },
    pageSize() {
      this.search();
    },
    keyword() {
      this.search();
    },
  },
  methods: {
    search() {
      if (this.keyword.trim() === "") {
        this.get();
        return;
      }
      const formData = new FormData();
      formData.append("keyword", this.keyword.trim());
      this.$root
        .appApi({
          method: "POST",
          url: "admin/user-company/search",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                const addr = `${item.addr1} ${item.addr2} ${item.addrChangwat.nameTh} ${item.addrAmphoe.nameTh} ${item.addrTambon.nameTh} ${item.addrZipCode}`;
                let addrTax = `${item.addrTax1} ${item.addrTax2} ${item.addrTaxChangwat.nameTh} ${item.addrTaxAmphoe.nameTh} ${item.addrTaxTambon.nameTh} ${item.addrTaxZipCode}`;
                if (item.isSameAddr) {
                  addrTax = "ที่อยู่เดียวกับที่ตั้ง";
                }
                return {
                  id: item.id,
                  name: item.name,
                  companyAddress: addr,
                  companyAddrTax: addrTax,
                };
              });
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data.length
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/user-company/all",
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                const addr = `${item.addr1} ${item.addr2} ${item.addrChangwat.nameTh} ${item.addrAmphoe.nameTh} ${item.addrTambon.nameTh} ${item.addrZipCode}`;
                let addrTax = `${item.addrTax1} ${item.addrTax2} ${item.addrTaxChangwat.nameTh} ${item.addrTaxAmphoe.nameTh} ${item.addrTaxTambon.nameTh} ${item.addrTaxZipCode}`;
                if (item.isSameAddr) {
                  addrTax = "ที่อยู่เดียวกับที่ตั้ง";
                }
                return {
                  id: item.id,
                  name: item.name,
                  companyAddress: addr,
                  companyAddrTax: addrTax,
                };
              });
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data.length
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    confirmDelete(id) {
      this.$store.state.loading = true;
      this.$root
        .appApi({ method: "DELETE", url: "admin/user-company/" + id })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.$root.getSystemMessage(resp.data.message);
          }
          this.get();
        })
        .catch((err) => {
          if (err.message) {
            this.$root.getErrorSystemMessage(err.message);
          } else {
            this.$root.getErrorSystemMessage(err);
          }
        });
    },
    deleteDialog(id) {
      this.showDialog = true;
      this.deleteId = id;
    },
  },
};
</script>

<style></style>
