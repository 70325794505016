import Vue from "vue";
import Vuex from "vuex";
import axios from "../axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    loading: false,
    dialog: {},
    userId: localStorage.getItem("user") || "",
    title: "จัดการผู้ดูแลระบบ",
    showBackBtn: false,
    isBackToName: false,
    isRootAdmin: JSON.parse(localStorage.getItem("root")),
    isAdmin: JSON.parse(localStorage.getItem("admin")),
    isRootAdminMenu: JSON.parse(localStorage.getItem("root")),
    fullName: localStorage.getItem("fullName"),

    laborScheduleStartDate: "",
    laborScheduleEndDate: "",
    laborScheduleYardId: "",
    laborSchedulePage: 1,
    isBackSchedule: false,

    stockYardId: "",
    stockPhaseId: "",
    stockZoneId: "",
    stockVinNo: "",
    stockPage: 1,

    tempStockPage: 1,
    tempStockId: "",
    tempStockYardId: "",
    tempStockPhaseId: "",
    tempStockZoneId: "",
    tempStockVinNo: "",

    isBackToStock: false,
    isStockPage: false,

    navigateTo: false,
    navigateToRoute: "",
    fromIndexStockPage: false,
  },
  mutations: {
    fromIndexStockToStockVinNoPage(state) {
      state.fromIndexStockPage = true;
    },
    setTempStock(state, value) {
      state.tempStockId = value.tempStockId;
      state.tempStockYardId = value.tempStockYardId;
      state.tempStockPhaseId = value.tempStockPhaseId;
      state.tempStockZoneId = value.tempStockZoneId;
    },
    clearStock(state) {
      state.isBackToStock = false;
      state.isStockPage = false;
      state.stockYardId = "";
      state.stockPhaseId = "";
      state.stockZoneId = "";
      state.stockVinNo = "";
      state.stockPage = 1;

      state.tempStockPage = 1;
      state.tempStockId = "";
      state.tempStockYardId = "";
      state.tempStockPhaseId = "";
      state.tempStockZoneId = "";
      state.tempStockVinNo = "";

      state.fromIndexStockPage = false;
    },
    clearTempStock(state) {
      state.isStockPage = false;
      state.tempStockPage = 1;
      state.tempStockId = "";
      state.tempStockYardId = "";
      state.tempStockPhaseId = "";
      state.tempStockZoneId = "";
      state.tempStockVinNo = "";
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { token }) {
      state.status = "success";
      state.token = token;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    dialog(state, { message, type }) {
      state.dialog = { message, type, state: true };
    },
  },
  actions: {
    loginRootAdmin({ commit }, loginData) {
      return new Promise((resolve, reject) => {
        axios({
          url: "admin/login",
          method: "POST",
          data: loginData,
          timeout: 0,
          processData: false,
          contentType: false,
        })
          .then((resp) => {
            const token = resp.data.data.token;
            localStorage.setItem("token", token);
            localStorage.setItem(
              "fullName",
              `${resp.data.data.firstname} ${resp.data.data.lastname}`
            );
            this.state.fullName = `${resp.data.data.firstname} ${resp.data.data.lastname}`;
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            commit("auth_success", { token });
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");

            if (err.response.data.errors) {
              reject(err.response.data.errors);
            } else if (err.message === "Network Error") {
              Promise.reject(new Error("กรุณาตรวจสอบการเชื่อมต่ออินเตอร์เนต"));
            } else {
              Promise.reject(new Error(err.message));
            }
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        axios({
          method: "POST",
          url: "admin/logout",
        });

        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("admin");
        localStorage.removeItem("root");
        localStorage.removeItem("fullName");

        delete axios.defaults.headers.common["Authorization"];

        resolve();
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    userId: (state) => state.userId,
    title: (state) => state.title,
    showBackBtn: (state) => state.showBackBtn,
    isBackToName: (state) => state.isBackToName,
    isRootAdmin: (state) => state.isRootAdmin,
    isAdmin: (state) => state.isAdmin,
    isRootAdminMenu: (state) => state.isRootAdminMenu,
    fullName: () => localStorage.getItem("fullName"),
  },
  modules: {},
});
