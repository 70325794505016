<template>
  <div class="my-10">
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title">
          <b>ระบุ Color Code</b>
        </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="code"
            color="app-theme"
            placeholder="Color Code"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุ Color Code']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title">
          <b>ระบุ Code Description</b>
        </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="description"
            color="app-theme"
            placeholder="Code Description"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุ Code Description']"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/vehicle/color/management/')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAdd: this.$route.name === "VehicleColor.Add",
      code: "",
      description: "",
    };
  },
  mounted() {
    this.$store.state.title = "เพิ่มสีรถ";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/vehicle/color/management";
    if (this.$route.name === "VehicleColor.Edit") {
      this.$store.state.title = "แก้ไขสีรถ";
      this.get();
    }
  },
  methods: {
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({ url: "admin/car-color/" + this.$route.params.id })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.code = resp.data.data.code;
            this.description = resp.data.data.description;
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    add() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("code", this.code);
      formData.append("description", this.description);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/car-color",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/vehicle/color/management"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    update() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("code", this.code);
      formData.append("description", this.description);
      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/car-color",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/vehicle/color/management"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
