<template>
  <div class="my-10">
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>User ID</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input--disabled"
            v-model="userId"
            rounded
            hide-details="false"
            :disabled="true"
            required
            background-color="app-disabled-theme"
            height="48"
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>รหัสพนักงาน</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="code"
            color="app-theme"
            placeholder="รหัสพนักงาน"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุรหัสพนักงาน',
              (v) =>
                /^[0-9A-Za-z]+$/.test(v.trim()) ||
                'กรุณาระบุรหัสพนักงานเป็นตัวเลขหรือตัวอักษรภาษาอังกฤษเท่านั้น',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>ชื่อ</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="firstname"
            color="app-theme"
            placeholder="ชื่อ"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุชื่อ']"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>นามสกุล</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="lastname"
            color="app-theme"
            placeholder="นามสกุล"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุนามสกุล']"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>อีเมล</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="email"
            color="app-theme"
            placeholder="อีเมล"
            outlined
            hide-details="auto"
            :rules="[(v) => /.+@.+/.test(v) || 'กรุณาระบุอีเมล']"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>เบอร์โทรศัพท์</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="tel"
            color="app-theme"
            placeholder="เบอร์โทรศัพท์"
            outlined
            hide-details="auto"
            v-mask="telMask"
            :rules="[
              (v) =>
                v.length == 12 || 'กรุณาระบุเบอร์โทรศัพท์ให้ครบทั้ง 10 ตัว',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="start">
        <v-col cols="12" sm="3" class="pl--title"> <b>ที่อยู่</b> </v-col>
        <v-col cols="10" sm="8">
          <v-row>
            <v-col>
              เลขที่
              <v-text-field
                class="mt-2 pl--input"
                v-model="addr1"
                color="app-theme"
                placeholder="เลขที่"
                outlined
                hide-details="auto"
                :rules="[
                  (v) =>
                    /^[0-9/]+$/.test(v) || 'กรุณาระบุเลขที่เป็นตัวเลขเท่านั้น',
                ]"
              ></v-text-field
            ></v-col>
            <v-col
              >หมู่<v-text-field
                class="mt-2 pl--input"
                v-model="addr2"
                color="app-theme"
                placeholder="หมู่"
                outlined
                hide-details="auto"
                type="number"
                :rules="[
                  (v) =>
                    /^[0-9/]+$/.test(v) || 'กรุณาระบุหมู่เป็นตัวเลขเท่านั้น',
                ]"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col>
              จังหวัด
              <v-select
                class="mt-2 pl--select select--icon--center"
                hide-details="auto"
                color="app-theme"
                placeholder="จังหวัด"
                v-model="addrChangwatId"
                :items="ddlProvince"
                outlined
                :rules="[(v) => !!v || 'กรุณาเลือกจังหวัด']"
                ><template v-slot:append>
                  <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col
              >เขต/อำเภอ<v-select
                class="mt-2 pl--select select--icon--center"
                hide-details="auto"
                color="app-theme"
                placeholder="เขต/อำเภอ"
                v-model="addrAmphoeId"
                :items="ddlDistrict"
                outlined
                :rules="[(v) => !!v || 'กรุณาเลือกเขต/อำเภอ']"
                ><template v-slot:append>
                  <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                </template></v-select
              ></v-col
            >
          </v-row>
          <v-row>
            <v-col>
              แขวง/ตำบล
              <v-select
                class="mt-2 pl--select select--icon--center"
                v-model="addrTambonId"
                :items="ddlSubDistrict"
                color="app-theme"
                placeholder="แขวง/ตำบล"
                outlined
                hide-details="auto"
                :rules="[(v) => !!v || 'กรุณาเลือกแขวง/ตำบล']"
                ><template v-slot:append>
                  <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                </template></v-select
              ></v-col
            >
            <v-col
              >รหัสไปรษณีย์<v-text-field
                class="mt-2 pl--input"
                v-model="addrZipCode"
                color="app-theme"
                placeholder="รหัสไปรษณีย์"
                outlined
                hide-details="auto"
                :rules="[
                  (v) =>
                    /^[0-9]+$/.test(v) ||
                    'กรุณาระบุรหัสไปรษณีย์เป็นตัวเลขเท่านั้น',
                  (v) =>
                    (v && v.length == 5) || 'รหัสไปรษณีย์ต้องมี 5 ตัวเท่านั้น',
                ]"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>ตำแหน่ง</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select select--icon--center"
            hide-details="auto"
            color="app-theme"
            placeholder="เลือกตำแหน่ง"
            v-model="staffPositionId"
            :items="ddlPosition"
            outlined
            :rules="[(v) => !!v || 'กรุณาเลือกตำแหน่ง']"
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>กลุ่มพนักงาน</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select select--icon--center"
            hide-details="auto"
            color="app-theme"
            placeholder="เลือกกลุ่มพนักงาน"
            v-model="staffGroupId"
            :items="ddlGroup"
            outlined
            :rules="[(v) => !!v || 'กรุณาเลือกกลุ่มพนักงาน']"
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row align="end">
        <v-col cols="12" sm="3" class="pl--title">
          <b>กำหนดวันที่ทำงาน</b>
        </v-col>
        <v-col cols="10" sm="1">
          <v-checkbox
            v-model="selected"
            color="app-theme"
            hide-details="auto"
            label="จันทร์"
            :value="1"
            :rules="validateWorkDate"
          ></v-checkbox>
        </v-col>
        <v-col cols="10" sm="1">
          <v-checkbox
            v-model="selected"
            color="app-theme"
            hide-details="auto"
            label="อังคาร"
            :value="2"
            :rules="validateWorkDate"
          ></v-checkbox>
        </v-col>
        <v-col cols="10" sm="1">
          <v-checkbox
            v-model="selected"
            color="app-theme"
            hide-details="auto"
            label="พุธ"
            :value="3"
            :rules="validateWorkDate"
          ></v-checkbox>
        </v-col>
        <v-col cols="10" sm="1" class="mr-4">
          <v-checkbox
            v-model="selected"
            color="app-theme"
            hide-details="auto"
            label="พฤหัสบดี"
            :value="4"
            :rules="validateWorkDate"
          ></v-checkbox>
        </v-col>
        <v-col cols="10" sm="1">
          <v-checkbox
            v-model="selected"
            color="app-theme"
            hide-details="auto"
            label="ศุกร์"
            :value="5"
            :rules="validateWorkDate"
          ></v-checkbox>
        </v-col>
        <v-col cols="10" sm="1">
          <v-checkbox
            v-model="selected"
            color="app-theme"
            hide-details="auto"
            label="เสาร์"
            :value="6"
            :rules="validateWorkDate"
          ></v-checkbox>
        </v-col>
        <v-col cols="10" sm="1">
          <v-checkbox
            v-model="selected"
            color="app-theme"
            hide-details="auto"
            label="อาทิตย์"
            :value="7"
            :rules="validateWorkDate"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>ชื่อผู้ใช้</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="username"
            color="app-theme"
            placeholder="ชื่อผู้ใช้"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุชื่อผู้ใช้']"
            autocomplete="off"
            :disabled="!isAdd"
            :background-color="!isAdd ? '#eeeeee' : ''"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" v-if="isAdd">
        <v-col cols="12" sm="3" class="pl--title"> <b>รหัสผ่าน</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="password"
            color="app-theme"
            placeholder="รหัสผ่าน"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุรหัสผ่าน',
              (v) => (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6',
              (v) =>
                /[A-Za-z]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
              (v) =>
                /[0-9]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
            ]"
            type="password"
            autocomplete="new-password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>RFID Card.No</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="rfidNo"
            color="app-theme"
            outlined
            hide-details="auto"
            autocomplete="off"
            :rules="[
              (v) =>
                /^[0-9A-Za-z]+$/.test(v) ||
                'กรุณาระบุ RFID Card.No เป็นตัวเลขหรือตัวอักษรภาษาอังกฤษเท่านั้น',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>Yard</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select select--icon--center"
            hide-details="auto"
            color="app-theme"
            v-model="yardId"
            :items="ddlYard"
            outlined
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-5 mt-10">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/labor/employee_detail/')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-5" v-if="!isAdd">
        <v-col cols="12" sm="3" class="mx-auto d-flex justify-center">
          <router-link
            :to="{
              name: 'EmployeeDetail.ChangePassword',
              params: { id: $route.params.id },
            }"
            class="text-decoration-none mb-5"
          >
            <v-btn rounded dark color="app-danger" class="px-15 py-6 mt-2"
              ><b class="px-2">เปลี่ยนรหัสผ่าน</b></v-btn
            >
          </router-link>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<style scoped></style>
<script>
export default {
  data() {
    return {
      isAdd: this.$route.name === "EmployeeDetail.Add",
      telMask: "###-###-####",
      userId: `xxxxx`,
      code: "",
      firstname: ``,
      lastname: ``,
      email: ``,
      tel: ``,

      username: ``,
      password: ``,
      staffPositionId: ``,
      staffGroupId: ``,
      rfidNo: "",
      yardId: null,
      selected: [],
      addr1: ``,
      addr2: ``,
      addrChangwatId: ``,
      addrAmphoeId: ``,
      addrTambonId: ``,
      addrZipCode: ``,

      ddlPosition: [
        { text: "Driver Leader", value: 1 },
        { text: "Labor", value: 2 },
      ],
      ddlGroup: [],
      ddlYard: [],
      ddlProvince: [],
      ddlDistrict: [],
      ddlSubDistrict: [],
      province: [],
      district: [],
      subDistrict: [],
    };
  },
  async mounted() {
    this.$store.state.title = "เพิ่มพนักงาน";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/labor/employee_detail";

    this.getGroup();
    this.getYard();
    if (this.$route.name === "EmployeeDetail.Edit") {
      await this.getProvince();
      await this.get();
      this.$store.state.title = "แก้ไขพนักงาน";
    } else {
      this.getProvince();
    }
  },
  computed: {
    validateWorkDate() {
      return [this.selected.length > 0 || "กรุณากำหนดวันที่ทำงาน"];
    },
  },
  watch: {
    addrChangwatId() {
      if (this.addrChangwatId === undefined) return;
      this.district = this.province.find((w) => w.id === this.addrChangwatId);
      if (this.district) {
        this.ddlDistrict = this.district.amphoes.map((item) => {
          return {
            text: item.nameTh,
            value: item.id,
          };
        });
      }
      this.ddlSubDistrict = [];
      this.addrZipCode = "";
    },
    addrAmphoeId() {
      if (this.addrAmphoeId === undefined) return;
      if (this.district) {
        this.subDistrict = this.district.amphoes.find(
          (w) => w.id === this.addrAmphoeId
        );
        this.ddlSubDistrict = this.subDistrict.tambons.map((item) => {
          return {
            text: item.nameTh,
            value: item.id,
          };
        });
      }
      this.addrZipCode = "";
    },
    addrTambonId() {
      const addrZipCode = this.subDistrict.tambons.find(
        (w) => w.id === this.addrTambonId
      );
      if (addrZipCode) {
        this.addrZipCode = addrZipCode.zipCode;
      }
    },
  },
  methods: {
    getYard() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlYard = resp.data.data;
          if (ddlYard) {
            this.ddlYard = ddlYard.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    async getProvince() {
      this.$store.state.loading = true;
      await this.$root
        .appApi({ url: "/data/provinces" })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            this.province = entity;
            this.ddlProvince = entity.map((item) => {
              return {
                text: item.nameTh,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getGroup() {
      this.$store.state.loading = true;
      this.$root
        .appApi({ url: "/admin/staff-group/all" })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            this.ddlGroup = entity.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    async get() {
      this.$store.state.loading = true;
      await this.$root
        .appApi({ url: `admin/staff/${this.$route.params.id}` })
        .then((resp) => {
          const entity = resp.data.data;
          this.selected = entity.workDays.map((item) => {
            return item.id;
          });
          this.yardId = entity.yardId;
          this.id = entity.id;
          this.userId = entity.id;
          this.code = entity.code;
          this.firstname = entity.firstname;
          this.lastname = entity.lastname;
          this.email = entity.email;
          this.tel = entity.tel;
          this.staffPositionId = entity.staffPosition
            ? entity.staffPosition.id
            : "";
          this.staffGroupId = entity.staffGroup ? entity.staffGroup.id : "";

          this.username = entity.username;
          this.rfidNo = entity.rfidNo;

          this.addr1 = entity.addr1;
          this.addr2 = entity.addr2;
          this.addrChangwatId = entity.addrChangwat
            ? entity.addrChangwat.id
            : "";
          this.district = this.province.find(
            (w) => w.id === entity.addrChangwat.id
          );
          this.ddlDistrict = this.district.amphoes.map((item) => {
            return {
              text: item.nameTh,
              value: item.id,
            };
          });
          this.addrAmphoeId = entity.addrAmphoe ? entity.addrAmphoe.id : "";
          this.subDistrict = this.district.amphoes.find(
            (w) => w.id === entity.addrAmphoe.id
          );
          this.ddlSubDistrict = this.subDistrict.tambons.map((item) => {
            return {
              text: item.nameTh,
              value: item.id,
            };
          });
          this.addrTambonId = entity.addrTambon ? entity.addrTambon.id : "";

          this.addrZipCode = entity.addrZipCode;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    add() {
      if (!this.$refs.form.validate()) return;
      if (!this.$refs.form.validate()) return;
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();

      formData.append("code", this.code);
      formData.append("firstname", this.firstname);
      formData.append("lastname", this.lastname);
      formData.append("email", this.email);
      formData.append("tel", this.tel);
      formData.append("staffPositionId", this.staffPositionId);
      formData.append("staffGroupId", this.staffGroupId);
      formData.append("username", this.username);
      formData.append("password", this.password);
      formData.append("rfidNo", this.rfidNo);

      formData.append("addr1", this.addr1);
      formData.append("addr2", this.addr2);
      formData.append("addrChangwatId", this.addrChangwatId);
      formData.append("addrAmphoeId", this.addrAmphoeId);
      formData.append("addrTambonId", this.addrTambonId);
      formData.append("addrZipCode", this.addrZipCode);
      formData.append("workDays[]", this.selected);
      if (this.yardId) {
        formData.append("yardId", this.yardId);
      }

      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/labor/employee_detail"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    update() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();

      formData.append("id", this.$route.params.id);
      formData.append("code", this.code);
      formData.append("firstname", this.firstname);
      formData.append("lastname", this.lastname);
      formData.append("email", this.email);
      formData.append("tel", this.tel);
      formData.append("staffPositionId", this.staffPositionId);
      formData.append("staffGroupId", this.staffGroupId);
      formData.append("username", this.username);
      formData.append("rfidNo", this.rfidNo);

      formData.append("addr1", this.addr1);
      formData.append("addr2", this.addr2);
      formData.append("addrChangwatId", this.addrChangwatId);
      formData.append("addrAmphoeId", this.addrAmphoeId);
      formData.append("addrTambonId", this.addrTambonId);
      formData.append("addrZipCode", this.addrZipCode);
      formData.append("workDays[]", this.selected);
      if (this.yardId) {
        formData.append("yardId", this.yardId);
      }

      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/staff/",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/labor/employee_detail"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.pl--select >>> div.v-select__slot {
  margin-left: 12px !important;
  color: #000000;
}
</style>
