<template>
  <v-container>
    <v-form @submit.prevent="searchDate()" ref="form">
      <v-row class="my-1" align="center">
        <v-col cols="9">
          <v-row align="center">
            <v-col cols="auto" align="center" class="text-start pl-5">
              <b>ค้นหาวันที่</b>
            </v-col>
            <v-col cols="10" sm="10">
              <v-row align="center">
                <v-col cols="12" sm="5">
                  <v-menu
                    v-model="menuStart"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateStartFormatted"
                        class="input--icon--center"
                        persistent-hint
                        append-icon="mdi-calendar-month"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details="auto"
                        :rules="[(v) => !!v || 'กรุณาระบุวันที่เริ่มต้น']"
                        ><template v-slot:append>
                          <v-img
                            class="pa-0 ma-0"
                            src="@/assets/icons/ic_calendar.svg"
                            contain
                            width="24"
                          ></v-img> </template
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateStart"
                      no-title
                      @change="menuStart = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                -
                <v-col cols="12" sm="5">
                  <v-menu
                    v-model="menuEnd"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="input--icon--center"
                        v-model="dateEndFormatted"
                        persistent-hint
                        append-icon="mdi-calendar-month"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details="auto"
                        :rules="[(v) => !!v || 'กรุณาระบุวันที่สิ้นสุด']"
                        ><template v-slot:append>
                          <v-img
                            class="pa-0 ma-0"
                            src="@/assets/icons/ic_calendar.svg"
                            contain
                            width="24"
                          ></v-img></template
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateEnd"
                      no-title
                      @change="menuEnd = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" class="text-right pr-15">
          <v-btn
            class="pa-6"
            rounded
            outlined
            color="app-theme"
            dark
            style="text-transform: none !important"
            type="submit"
          >
            <b> ค้นหา</b>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="my-1">
        <v-col cols="6">
          <v-row align="center">
            <v-col cols="2" align="center" class="text-start pl-5">
              <b>Yard</b>
            </v-col>
            <v-col cols="10" sm="10" class="pa-0">
              <v-row align="center" class="pl-2">
                <v-col cols="12" sm="6">
                  <v-select
                    class="pl--select select--icon--center"
                    hide-details="auto"
                    color="app-theme"
                    outlined
                    v-model="yardId"
                    :items="ddlYard"
                    :rules="[(v) => !!v || 'กรุณาเลือก Yard']"
                    ><template v-slot:append>
                      <v-icon large>mdi-chevron-down</v-icon>
                    </template></v-select
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row></v-col
        >
        <v-col cols="6" class="text-right">
          <v-btn
            class="py-6 mr-4"
            rounded
            color="app-export-theme"
            dark
            style="text-transform: none !important"
            @click="downloadExcel"
          >
            <b>ส่งออก Excel</b>
          </v-btn>
          <v-btn
            class="py-6"
            :to="{ name: 'RecordOfWork.Add' }"
            rounded
            color="app-theme"
            dark
            style="text-transform: none !important"
          >
            <b> นำเข้าข้อมูลการทำงาน</b>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="items"
      class="elevation-1"
      :page.sync="page"
      :items-per-page="pageSize"
      @page-count="pageCount = $event"
      :header-props="{
        sortIcon: 'mdi-menu-up',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        'items-per-page-options': rowsPerPage,
      }"
    >
      <template v-slot:item.isStatus="{ item }">
        <div style="color: green" v-if="item.isStatus === 0">พอดี</div>
        <div style="color: red" v-else-if="item.isStatus < 0">ขาด</div>
        <div style="color: blue" v-else>เกิน</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <router-link
          :to="{
            name: 'RecordOfWork.Edit',
            params: { date: item._date, yardId: item.yardId },
          }"
          class="text-decoration-none mr-1"
        >
          <v-btn dark color="app-theme"><b>ดูรายละเอียด</b></v-btn>
        </router-link>
      </template>
      <template v-slot:footer> </template>
    </v-data-table>
    <Pagination
      ref="Pagination"
      :page="page"
      :page-size="pageSize"
      @changePage="page = $event"
      @changePageSize="pageSize = $event"
    />
  </v-container>
</template>

<script>
import Pagination from "@/components/Pagination";
import helper from "@/helpers/helper";
export default {
  components: {
    Pagination,
  },

  data: () => ({
    helper: helper,
    dateStart: "",
    dateEnd: "",
    dateStartFormatted: "",
    dateEndFormatted: "",
    menuStart: false,
    menuEnd: false,
    yardId: "",
    ddlYard: [
      { text: "OTTOLand", value: 1 },
      { text: "Yard", value: 2 },
    ],
    page: 1,
    pageCount: 0,
    pageSize: 10,

    itemsPerPage: 1,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "วันที่",
        align: "center",
        value: "date",
      },
      { text: "กำลังพลที่ต้องการ", align: "center", value: "staffRequire" },
      { text: "กำลังพลตามแผน", align: "center", value: "limitCarsPerStaff" },
      { text: "กำลังพลที่มี", align: "center", value: "staffCurrent" },
      { text: "สถานะ", align: "center", value: "isStatus" },
      {
        text: "",
        sortable: false,
        align: "center",
        value: "actions",
        width: "180px",
      },
    ],
    items: [],
  }),

  watch: {
    dateStart() {
      this.dateStartFormatted = this.helper.formatDate(this.dateStart);
    },
    dateEnd() {
      this.dateEndFormatted = this.helper.formatDate(this.dateEnd);
    },
    page() {
      this.search();
    },
    pageSize() {
      this.search();
    },
  },
  mounted() {
    this.$store.state.title = "บันทึกการมาทำงานของพนักงาน";
    this.$store.state.showBackBtn = false;
    this.getYard();
  },
  methods: {
    getYard() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlYard = resp.data.data;
          if (ddlYard) {
            this.ddlYard = ddlYard.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    downloadExcel() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("startDate", this.dateStart);
      formData.append("endDate", this.dateEnd);
      formData.append("yardId", this.yardId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/staff-work/export",
          data: formData,
          responseType: "blob",
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `staff-work_${this.dateStart}_to_${this.dateEnd}`
          );
          document.body.appendChild(link);
          link.click();
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    search() {
      const formData = new FormData();
      if (!helper.isNullOrEmpty(this.dateStart)) {
        formData.append("startDate", this.dateStart || "");
      } else {
        formData.append("startDate", "0000-01-01" || "");
      }
      if (!helper.isNullOrEmpty(this.dateEnd)) {
        formData.append("endDate", this.dateEnd || "");
      } else {
        const now = helper.newDateFormatted();
        formData.append("endDate", helper.parseDateYMD(now) || "");
      }
      formData.append("yardId", this.yardId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/staff-work/search-by-group-date",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                return {
                  date: helper.formatDate(item.workDate),
                  limitCarsPerStaff: item.LimitCarsPerStaff,
                  staffRequire: item.StaffRequire,
                  staffCurrent: item.StaffCurrent,
                  isStatus: item.StaffCurrent - item.StaffRequire,
                  _date: helper.formatDate(item.workDate),
                  yardId: item.yard.id,
                };
              });
            } else {
              this.items = [];
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data ? resp.data.data.length : 0
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    searchDate() {
      if (!this.$refs.form.validate()) return;
      const formData = new FormData();
      formData.append("startDate", this.dateStart || "");
      formData.append("endDate", this.dateEnd || "");
      formData.append("yardId", this.yardId);

      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/staff-work/search-by-group-date",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                return {
                  date: helper.formatDate(item.workDate),
                  limitCarsPerStaff: item.LimitCarsPerStaff,
                  staffRequire: item.StaffRequire,
                  staffCurrent: item.StaffCurrent,
                  isStatus: item.StaffCurrent - item.StaffRequire,
                  _date: helper.formatDate(item.workDate),
                  yardId: item.yard.id,
                };
              });
            } else {
              this.items = [];
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data ? resp.data.data.length : 0
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/staff/staff-work",
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                return {
                  date: helper.formatDate(item.workDate),
                  limitCarsPerStaff: item.LimitCarsPerStaff,
                  staffRequire: item.StaffRequire,
                  staffCurrent: item.StaffCurrent,
                  isStatus: item.StaffCurrent - item.StaffRequire,
                  _date: helper.formatDate(item.workDate),
                  yardId: item.yard.id,
                };
              });
            } else {
              this.items = [];
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data ? resp.data.data.length : 0
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
