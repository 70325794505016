<template>
  <div class="my-10">
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>ชื่อพนักงาน</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select2 select--icon--center"
            hide-details="auto"
            color="app-theme"
            placeholder="ชื่อพนักงาน"
            v-model="staffId"
            :items="ddlStaff"
            outlined
            :rules="[(v) => !!v || 'กรุณาเลือกพนักงาน']"
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>ประเภทการลา</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select2 select--icon--center"
            hide-details="auto"
            color="app-theme"
            placeholder="ประเภทการลา"
            v-model="leaveTypeId"
            :items="ddlLeaveType"
            outlined
            :rules="[(v) => !!v || 'กรุณาเลือกประเภทการลา']"
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>วันที่ลา</b> </v-col>
        <v-col cols="12" sm="8">
          <v-menu
            v-model="menuStart"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="pl--input input--icon--center"
                v-model="dateStartFormatted"
                persistent-hint
                append-icon="mdi-calendar-month"
                v-bind="attrs"
                v-on="on"
                outlined
                required
                hide-details="auto"
                placeholder="วันที่ลา"
                :rules="[(v) => !!v || 'กรุณาระบุวันที่ลา']"
                ><template v-slot:append>
                  <v-icon size="30" color="app-theme"
                    >mdi-calendar-month</v-icon
                  >
                </template></v-text-field
              >
            </template>
            <v-date-picker
              v-model="dateStart"
              no-title
              @change="menuStart = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>จำนวนวันที่ลา</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select2 select--icon--center"
            hide-details="auto"
            color="app-theme"
            placeholder="จำนวนวันที่ลา"
            v-model="numberOfLeave"
            :items="ddlNumberOfLeave"
            outlined
            :rules="[(v) => !!v || 'กรุณาเลือกจำนวนวันที่ลา']"
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/labor/record_leave_of_work/')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            :color="
              staffId !== '' &&
              numberOfLeave !== '' &&
              leaveTypeId !== '' &&
              dateStartFormatted !== ''
                ? 'app-theme'
                : 'app-disabled-button-theme'
            "
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import helper from "@/helpers/helper";
export default {
  data() {
    return {
      helper: helper,
      isAdd: this.$route.name === "RecordLeaveOfWork.Add",
      dateStart: "",
      dateStartFormatted: "",
      menuStart: false,

      staffId: "",
      numberOfLeave: "",
      leaveTypeId: "",

      ddlStaff: [],
      ddlLeaveType: [],
      ddlNumberOfLeave: [
        { text: "เต็มวัน", value: "full" },
        { text: "ครึ่งวันเช้า", value: "half_morning" },
        { text: "ครึ่งวันบ่าย", value: "half_afternoon" },
      ],
    };
  },
  watch: {
    dateStart() {
      this.dateStartFormatted = this.helper.formatDate(this.dateStart);
    },
  },
  mounted() {
    this.$store.state.title = "เพิ่มบันทึกการลา";
    this.$store.state.showBackBtn = false;
    this.$store.state.isBackToName = "/labor/record_leave_of_work";
    this.getStaff();
    this.getLeaveType();
    if (this.$route.name === "RecordLeaveOfWork.Edit") {
      this.get();
      this.$store.state.title = "แก้ไขบันทึกการลา";
    }
  },
  methods: {
    getStaff() {
      this.$store.state.loading = true;
      this.$root
        .appApi({ url: "admin/staff/all", params: { page: 1, pageSize: 999 } })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlStaff = entity.map((item) => {
                return {
                  value: item.id,
                  text: `${item.firstname} ${item.lastname}`,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getLeaveType() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/leave-type/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlLeaveType = entity.map((item) => {
                return {
                  value: item.id,
                  text: item.name,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/staff/leave/" + this.$route.params.id,
        })
        .then((resp) => {
          const entity = resp.data.data;
          if (entity) {
            this.staffId = entity.staffId;
            this.numberOfLeave = entity.numberOfLeave.code;
            this.leaveTypeId = entity.leaveType.id;
            this.dateStart = entity.leaveDate;
            this.$store.state.loading = false;
          }
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    add() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("StaffID", this.staffId);
      formData.append("LeaveDate", this.dateStart);
      formData.append("NumberOfLeave", this.numberOfLeave);
      formData.append("LeaveTypeId", this.leaveTypeId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/leave",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/labor/record_leave_of_work"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    update() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("StaffID", this.staffId);
      formData.append("LeaveDate", this.dateStart);
      formData.append("NumberOfLeave", this.numberOfLeave);
      formData.append("LeaveTypeId", this.leaveTypeId);
      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/staff/leave",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/labor/record_leave_of_work"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
