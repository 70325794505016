<template>
  <div>
    <v-row class="mb-10">
      <v-col cols="12">
        <v-card class="ma-4 mb-15">
          <v-row class="ml-6 pt-10">
            <v-col cols="12" sm="12"><b class="text--color--theme">Card Detail</b></v-col>
          </v-row>
          <v-row class="ml-9 pl-7 mb-2">
            <v-col cols="12" sm="3">
              <v-row>
                <b class="text--color--theme" style="width: 190px"> Brand : </b>
                <div class="text--color--theme">
                  {{ brand }}
                </div>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" class="pl-8">
              <v-row>
                <b class="text--color--theme mr-4">Vin No. :</b>
                <div>
                  <b>{{ vinNo }}</b>
                </div>
              </v-row>
            </v-col>
            <v-col cols="12" sm="5">
              <v-row>
                <b class="text--color--theme" style="width: 150px"> RFID Tag No. : </b>
                <div class="text--color--theme">{{ rfidNo }}</div>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ml-9 pl-7 mb-2">
            <v-col cols="12" sm="3">
              <v-row>
                <b class="text--color--theme" style="width: 190px"> Model Code: </b>
                <div class="text--color--theme" style="width: 80px">
                  {{ modelCode }}
                </div>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" class="pl-8">
              <v-row>
                <b class="text--color--theme" style="width: 120px"> Color Code: </b>
                <div class="text--color--theme">
                  {{ colorCode }}
                </div>
              </v-row>
            </v-col>
            <v-col cols="12" sm="5">
              <v-row>
                <b class="text--color--theme" style="width: 150px"> Status : </b>
                <div :class="status === 'In-Stock' ? 'text-green' : status === 'Out-Stock' ? 'text-red' : ''">
                  {{ status }}
                </div>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="ml-9 pl-7 mb-2">
            <b class="text--color--theme" style="width: 190px"> MSC CODE: </b>
            <div class="text--color--theme">{{ mscCode }}</div>
          </v-row>
          <v-row class="ml-9 pl-7 mb-2">
            <b class="text--color--theme" style="width: 190px"> MSC Description: </b>
            <div class="text--color--theme">{{ mscDesc }}</div>
          </v-row>
          <v-row class="pa-0 my-2 ml-4">
            <v-col cols="12" sm="6"> <v-divider style="background-color: black"></v-divider></v-col>
          </v-row>
          <v-row class="ml-6">
            <v-col cols="12" sm="12"
              ><b class="text--color--theme">Current Location</b
              ><v-btn style="transform: translateY(-5px)" @click="showModalEditLocation()" dark color="white" min-width="20" class="ml-2 pa-0 ma-0" icon>
                <v-img src="@/assets/icons/ic_edit_location.png" contain max-height="35" max-width="35"></v-img> </v-btn
            ></v-col>
          </v-row>
          <v-row class="ml-9 pl-7 mb-2">
            <v-col md="3" sm="4">
              <v-row>
                <b class="text--color--theme" style="width: 190px; min-width: 115px"> Yard: </b>
                <div class="text--color--theme" style="width: 120px">
                  {{ yard }}
                </div>
              </v-row>
            </v-col>
            <v-col md="4" sm="4" class="pl-8">
              <v-row>
                <b class="text--color--theme" style="width: 100px"> Phase: </b>
                <div class="text--color--theme">
                  {{ phase }}
                </div>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ml-9 pl-7 mb-7">
            <v-col md="3" sm="3">
              <v-row>
                <b class="text--color--theme" style="width: 190px"> Zone: </b>
                <div class="text--color--theme">{{ zone }}</div>
              </v-row>
            </v-col>
            <v-col md="4" sm="4" class="pl-8">
              <v-row>
                <b class="text--color--theme" style="width: 100px">Row:</b>
                <div class="text--color--theme">{{ row }}</div>
              </v-row>
            </v-col>
          </v-row>
          <v-data-table
            hide-default-footer
            :headers="headers"
            :items="items"
            class="elevation-1"
            :page.sync="page"
            :items-per-page="pageSize"
            @page-count="pageCount = $event"
            :header-props="{
              sortIcon: 'mdi-menu-up',
            }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              'items-per-page-options': rowsPerPage,
            }"
          >
            <template v-slot:item.action="{ item }">
              <p v-if="item.action == 'car-in'" class="text-green">
                {{ item.action }}
              </p>
              <p v-else-if="item.action == 'car-out'" class="text-red">
                {{ item.action }}
              </p>
              <p v-else class="text-black">{{ item.action }}</p>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <Pagination ref="Pagination" :page="page" :page-size="pageSize" @changePage="page = $event" @changePageSize="pageSize = $event" />
    <v-form @submit.prevent="updateLocation()" ref="editLocationForm">
      <v-dialog v-model="dlgEditLocation" persistent max-width="700">
        <!-- <template v-slot:activator="{ on, attrs }"> </template> -->
        <v-card class="py-10">
          <v-card-text class="text-center text-body-1"> </v-card-text>
          <v-card-text class="text-center text-body-1">
            <div class="mt-1">
              <span class="font--delete">แก้ไข Location</span>
            </div></v-card-text
          >
          <v-card-text class="text-center text-body-1">
            <v-row>
              <v-col cols="3" class="text-right"><span class="text-primary-location">Vin No :</span></v-col>
              <v-col cols="8" class="text-left"
                ><span class="text-primary-location">{{ editLocationVinNo }}</span></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="3" class="text-right"><span class="text-primary-location">Yard :</span></v-col>
              <v-col cols="8" class="text-left"
                ><span class="text-primary-location">{{ editLocationYard }}</span></v-col
              >
            </v-row>
            <v-row align="center">
              <v-col cols="3" class="text-right"><span class="text-black">Phase </span></v-col>
              <v-col cols="8" class="text-left">
                <v-select
                  class="select--icon--center"
                  hide-details="auto"
                  color="app-theme"
                  placeholder=""
                  v-model="editLocationPhaseId"
                  @change="
                    editLocationZoneId = '';
                    editLocationRowId = '';
                  "
                  :items="ddlPhase"
                  outlined
                  :rules="[(v) => !!v || 'กรุณาเลือก Phase']"
                  ><template v-slot:append>
                    <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                  </template>
                </v-select></v-col
              >
            </v-row>
            <v-row align="center">
              <v-col cols="3" class="text-right"><span class="text-black">Zone </span></v-col>
              <v-col cols="8" class="text-left">
                <v-select
                  class="select--icon--center"
                  hide-details="auto"
                  color="app-theme"
                  placeholder=""
                  v-model="editLocationZoneId"
                  :items="ddlZone"
                  outlined
                  :rules="[(v) => !!v || 'กรุณาเลือก Zone']"
                  ><template v-slot:append>
                    <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                  </template>
                </v-select></v-col
              >
            </v-row>
            <v-row align="center">
              <v-col cols="3" class="text-right"><span class="text-black">Row </span></v-col>
              <v-col cols="8" class="text-left">
                <v-select
                  class="select--icon--center"
                  hide-details="auto"
                  color="app-theme"
                  placeholder=""
                  v-model="editLocationRowId"
                  :items="ddlRow"
                  outlined
                  :rules="[(v) => !!v || 'กรุณาเลือก Row']"
                  ><template v-slot:append>
                    <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                  </template>
                </v-select></v-col
              >
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <v-btn
              dark
              rounded
              color="app-disabled-button-theme"
              class="px-15 py-6 mr-4 btn--action"
              @click="
                dlgEditLocation = false;
                clearDataEditLocation();
              "
              >ยกเลิก</v-btn
            >
            <v-btn color="app-theme" dark rounded class="px-15 py-6 btn--action" @click="updateLocation()">บันทึก</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>
<style>
.header-light-green {
  background-color: #58b997 !important;
}
.header-light-purple {
  background-color: #db7bff !important;
}
.header-light-blue {
  background-color: #4e91de !important;
}
</style>
<style scoped>
.font--delete {
  font-size: 28px !important;
  font-weight: bold !important;
  color: #000000 !important;
}
.text-primary {
  color: #203486;
}
.text-primary-location {
  color: #203486;
  font-size: 28px !important;
  font-weight: bold !important;
}
.text-green {
  color: green;
}
.text-red {
  color: red;
}
.text-black {
  color: black !important;
}
.text--color--theme {
  color: #041e41;
}
.v-card >>> ::-webkit-scrollbar {
  height: 20px !important;
  border: 1px solid #aaa !important;
  border-radius: 15px !important;
}
.v-card >>> ::-webkit-scrollbar-thumb {
  background: #283593 !important;
  border-radius: 15px !important;
}
</style>
<script>
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  data: function () {
    return {
      page: 1,
      pageCount: 0,
      pageSize: 10,
      rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
      headers: [
        {
          text: "Date/Time",
          align: "center",
          value: "dateTime",
          width: "300px",
          class: "header-light-green",
        },
        {
          text: "Action",
          align: "center",
          value: "action",
          width: "160px",
          class: "header-light-green",
        },
        {
          text: "Assign type",
          align: "center",
          value: "assignType",
          width: "160px",
          class: "header-light-purple",
        },
        {
          text: "Assigner",
          align: "center",
          value: "assigner",
          width: "160px",
          class: "header-light-purple",
        },
        {
          text: "Assignee",
          align: "center",
          value: "assignee",
          width: "160px",
          class: "header-light-purple",
        },
        {
          text: "Assign Time",
          align: "center",
          value: "assignTime",
          width: "300px",
          class: "header-light-purple",
        },
        {
          text: "Driver",
          align: "center",
          value: "driver",
          width: "160px",
          class: "header-light-purple",
        },
        {
          text: "Gate",
          align: "center",
          value: "gate",
          width: "160px",
          class: "header-light-purple",
        },
        {
          text: "Gate Time",
          align: "center",
          value: "gateAt",
          width: "300px",
          class: "header-light-purple",
        },
        {
          text: "Arrival Time",
          align: "center",
          value: "arrivalAt",
          width: "300px",
          class: "header-light-purple",
        },

        {
          text: "DealerZone",
          align: "center",
          value: "dealerZone",
          width: "160px",
          class: "header-light-blue",
        },
        {
          text: "DealerCode",
          align: "center",
          value: "dealerCode",
          width: "160px",
          class: "header-light-blue",
        },
        {
          text: "DealerName",
          align: "center",
          value: "dealerName",
          width: "300px",
          class: "header-light-blue",
        },
        {
          text: "DestinationCode",
          align: "center",
          value: "destinationCode",
          width: "400px",
          class: "header-light-blue",
        },
        {
          text: "TripNo",
          align: "center",
          value: "tripNo",
          width: "160px",
          class: "header-light-blue",
        },
        {
          text: "Trailer",
          align: "center",
          value: "trailer",
          width: "160px",
          class: "header-light-blue",
        },
      ],
      items: [],

      brand: "",
      vinNo: "",
      rfidNo: "",
      modelCode: "",
      colorCode: "",
      status: "",
      mscCode: "",
      mscDesc: "",

      yard: "",
      phase: "",
      zone: "",
      row: "",

      editLocationId: "",
      ddlPhase: [],
      editLocationPhaseId: "",
      ddlZone: [],
      editLocationZoneId: "",
      ddlRow: [],
      editLocationRowId: "",
      dlgEditLocation: false,
      editLocationVinNo: "",
      editLocationYard: "",
    };
  },
  watch: {
    async editLocationZoneId() {
      if (this.editLocationZoneId) await this.getRow(this.editLocationZoneId);
    },
  },
  async mounted() {
    this.$store.state.title = "รายละเอียด";
    this.$store.state.showBackBtn = true;

    this.getPhase();
    this.getZone();
    if (this.$store.state.fromIndexStockPage) {
      this.$store.state.isBackToStock = true;
      this.$store.state.isBackToName = `/stock`;
    } else {
      if (this.$store.state.isStockPage) {
        this.$store.state.isBackToName = `/stock/detail/id=${this.$store.state.tempStockId}&yardId=${this.$store.state.tempStockYardId}&phaseId=${this.$store.state.tempStockPhaseId}`;
      } else {
        this.$store.state.isBackToName = `/stock/sub_detail/id=${this.$store.state.tempStockId}&yardId=${this.$store.state.tempStockYardId}&phaseId=${this.$store.state.tempStockPhaseId}&zoneId=${this.$store.state.tempStockZoneId}`;
      }
    }

    if (!this.$route.params.id) {
      this.$router.push("/stock");
    } else {
      await this.get();
    }
  },
  methods: {
    clearDataEditLocation() {
      this.editLocationVinNo = "";
      this.editLocationYard = "";
      this.editLocationPhaseId = "";
      this.editLocationZoneId = "";
      this.editLocationRowId = "";
    },
    getPhase() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-phase/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const entity = resp.data.data;
          if (entity) {
            this.ddlPhase = entity.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getZone() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-zone/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const entity = resp.data.data;
          if (entity) {
            this.ddlZone = entity.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    async getRow(zoneId) {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-zone-row/zone/" + zoneId,
          params: { page: this.page, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlRow = entity.map((item) => {
                return {
                  text: item.no,
                  value: item.id,
                };
              });
            }
            this.$store.state.loading = false;
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    async showModalEditLocation() {
      this.dlgEditLocation = true;

      if (this.editLocationZoneId) await this.getRow(this.editLocationZoneId);
    },
    updateLocation() {
      if (!this.$refs.editLocationForm.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("id", this.editLocationId);
      formData.append("yardPhaseId", this.editLocationPhaseId);
      formData.append("yardZoneId", this.editLocationZoneId);
      formData.append("yardZoneRowId", this.editLocationRowId);
      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/car-importing/location",
          data: formData,
        })
        .then((resp) => {
          this.dlgEditLocation = false;
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage("ไม่สามารถเปลี่ยนแปลงเป็น Location ที่ต้องการได้");
          } else {
            this.$root.getSystemMessage("บันทึกการเปลี่ยนแปลง Location สำเร็จ");
            this.get();
          }
          this.$store.state.loading = false;
        })
        .catch(() => {
          this.dlgEditLocation = false;
          this.$root.getErrorSystemMessage("ไม่สามารถเปลี่ยนแปลงเป็น Location ที่ต้องการได้");
          this.$store.state.loading = false;
        });
    },
    parseFullDateTime(value) {
      if (!value) return null;
      const [date, time] = value.split(" ");
      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year} ${time} `;
    },
    async get() {
      this.$store.state.loading = true;
      await this.$root
        .appApi({
          url: "admin/car-in/" + this.$route.params.id,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          const entity = resp.data.data;
          const carDetailImporting = entity.carDetailImporting;
          if (carDetailImporting) {
            const carModel = carDetailImporting.carModel;
            if (carModel) {
              this.brand = carModel.carBrand ? carModel.carBrand.name : "";
            }
            this.yard = carDetailImporting.YardPhase.yard ? carDetailImporting.YardPhase.yard.name : "";
            this.phase = carDetailImporting.YardPhase ? carDetailImporting.YardPhase.name : "";
            this.zone = carDetailImporting.YardZone ? carDetailImporting.YardZone.name : "";
            this.row = carDetailImporting.YardZoneRow ? carDetailImporting.YardZoneRow.no : "";

            if (carDetailImporting.status.toLowerCase() == "park" || carDetailImporting.status.toLowerCase() == "done") {
              this.status = "In-Stock";
            } else if (carDetailImporting.status.toLowerCase() == "out") {
              this.status = "Out-Stock";
            } else {
              this.status = "-";
            }
            this.mscDesc = carDetailImporting.carModel ? carDetailImporting.carModel.description : "";

            this.editLocationVinNo = entity.vinNo;
            this.editLocationYard = this.yard;

            this.editLocationId = carDetailImporting.id;
            this.editLocationPhaseId = carDetailImporting.YardPhase ? carDetailImporting.YardPhase.id : "";
            this.editLocationZoneId = carDetailImporting.YardZone ? carDetailImporting.YardZone.id : "";
            this.editLocationRowId = carDetailImporting.YardZoneRow ? carDetailImporting.YardZoneRow.id : "";
          }

          this.vinNo = entity.vinNo;

          this.rfidNo = entity.RfidNo;
          this.modelCode = entity.modelCode;
          this.colorCode = entity.colorCode;
          this.mscCode = entity.mscCode;

          this.getDetail(entity.vinNo);

          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getDetail(vinNo) {
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("vinNo", vinNo);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/car-importing/activity",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          const entity = resp.data.data;
          if (entity) {
            let items = [];
            entity.map((item) => {
              const fileCar = {
                dateTime: this.parseFullDateTime(item.FileCarIn.dateAt),
                action: item.FileCarIn.action,
                assignType: item.FileCarIn.assignType,
                assigner: item.FileCarIn.assigner,
                assignee: item.FileCarIn.assignee,
                assignTime: this.parseFullDateTime(item.FileCarIn.assignAt),
                driver: item.FileCarIn.driver,
                gate: item.FileCarIn.gate,
                gateAt: this.parseFullDateTime(item.FileCarIn.gateAt),
                arrivalAt: this.parseFullDateTime(item.FileCarIn.arrivalAt),
                dealerZone: item.FileCarIn.dealerZone,
                dealerCode: item.FileCarIn.dealerCode,
                dealerName: item.FileCarIn.dealerName,
                destinationCode: item.FileCarIn.destinationCode,
                tripNo: item.FileCarIn.tripNo,
                trailer: item.FileCarIn.trailer,
              };
              const carIn = {
                dateTime: this.parseFullDateTime(item.CarIn.dateAt),
                action: item.CarIn.action,
                assignType: item.CarIn.assignType,
                assigner: item.CarIn.assigner,
                assignee: item.CarIn.assignee,
                assignTime: this.parseFullDateTime(item.CarIn.assignAt),
                driver: item.CarIn.driver,
                gate: item.CarIn.gate,
                gateAt: this.parseFullDateTime(item.CarIn.gateAt),
                arrivalAt: this.parseFullDateTime(item.CarIn.arrivalAt),
                dealerZone: item.CarIn.dealerZone,
                dealerCode: item.CarIn.dealerCode,
                dealerName: item.CarIn.dealerName,
                destinationCode: item.CarIn.destinationCode,
                tripNo: item.CarIn.tripNo,
                trailer: item.CarIn.trailer,
              };
              const carOut = {
                dateTime: this.parseFullDateTime(item.CarOut.dateAt),
                action: item.CarOut.action,
                assignType: item.CarOut.assignType,
                assigner: item.CarOut.assigner,
                assignee: item.CarOut.assignee,
                assignTime: this.parseFullDateTime(item.CarOut.assignAt),
                driver: item.CarOut.driver,
                gate: item.CarOut.gate,
                gateAt: this.parseFullDateTime(item.CarOut.gateAt),
                arrivalAt: this.parseFullDateTime(item.CarOut.arrivalAt),
                dealerZone: item.CarOut.dealerZone,
                dealerCode: item.CarOut.dealerCode,
                dealerName: item.CarOut.dealerName,
                destinationCode: item.CarOut.destinationCode,
                tripNo: item.CarOut.tripNo,
                trailer: item.CarOut.trailer,
              };

              if (!carOut.dateTime === false) {
                items.push(carOut);
              }
              items.push(carIn);
              items.push(fileCar);
            });
            this.items = items;
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>
