<template>
  <div class="my-10">
    <v-form @submit.prevent="update()" ref="form">
      <v-row align="center" v-if="$store.getters.isAdmin">
        <v-col cols="12" sm="3" class="pl--title">
          <h3>โปรไฟล์ส่วนตัว</h3>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3 d-flex align-center" v-else>
        <v-row no-gutters class="d-flex justify-end align-center">
          <v-col cols="6" class="d-flex justify-end mr-5">
            <b>ประเภทผู้ใช้ : {{ userType }}</b>
          </v-col>
        </v-row>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" lg="3" class="pl--title">
          <b>User ID</b>
        </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input--disabled"
            v-model="id"
            rounded
            hide-details="false"
            :disabled="true"
            required
            background-color="app-disabled-theme"
            height="48"
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>ชื่อ</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="firstName"
            color="app-theme"
            placeholder="กรุณาระบุชื่อ"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุชื่อ',
              (v) =>
                /^[A-Za-zก-๏]+$/.test(v.trim()) ||
                'ใส่ได้เฉพาะอักษรภาษาไทยและภาษาอังกฤษ เท่านั้น',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>นามสกุล</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="lastName"
            color="app-theme"
            placeholder="กรุณาระบุนามสกุล"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุนามสกุล',
              (v) =>
                /^[A-Za-zก-๏]+$/.test(v.trim()) ||
                'ใส่ได้เฉพาะอักษรภาษาไทยและภาษาอังกฤษ เท่านั้น',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>อีเมล</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="email"
            color="app-theme"
            placeholder="อีเมล"
            outlined
            hide-details="auto"
            :rules="[(v) => /.+@.+/.test(v) || 'กรุณาระบุอีเมล']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>เบอร์โทรศัพท์</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="tel"
            color="app-theme"
            placeholder="เบอร์โทรศัพท์"
            outlined
            hide-details="auto"
            v-mask="telMask"
            :rules="[
              (v) =>
                v.length == 12 || 'กรุณาระบุเบอร์โทรศัพท์ให้ครบทั้ง 10 ตัว',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" v-if="$store.getters.isAdmin">
        <v-col cols="12" sm="3" class="pl--title"><b>ตำแหน่ง</b> </v-col>
        <v-col cols="10" sm="8">
          <v-select
            class="pl--select2 select--role placeholder--black"
            v-model="positionId"
            :items="ddlPostion"
            color="app-theme"
            placeholder="ตำแหน่ง"
            outlined
            hide-details="auto"
            background-color="app-disabled-theme"
            disabled
          >
            <template v-slot:append>
              <v-icon large color="red">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center" v-if="$store.getters.isAdmin">
        <v-col cols="12" sm="3" class="pl--title"><b>ชื่อผู้ใช้</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="username"
            hide-details="false"
            :disabled="true"
            required
            background-color="app-disabled-theme"
            height="48"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" v-else>
        <v-col cols="12" sm="3" class="pl--title"><b>ชื่อผู้ใช้</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input--disabled"
            v-model="username"
            rounded
            hide-details="false"
            :disabled="true"
            required
            background-color="app-disabled-theme"
            height="48"
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <router-link :to="{ name: 'Admin' }" class="text-decoration-none">
            <v-btn
              rounded
              color="app-disabled-button-theme"
              dark
              class="mr-4 my-2 px-8 py-6"
              >ยกเลิก</v-btn
            >
          </router-link>
          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<style scoped>
.select--role >>> .v-input__append-inner {
  margin-top: 10px !important;
  padding: 0px !important;
}
.placeholder--black >>> .v-select__selections > div {
  color: black;
}
</style>

<script>
export default {
  data: () => ({
    ddlPostion: [{ text: "จัดการกลุ่มข้อมูลและพนักงาน", value: "1" }],
    telMask: "###-###-####",
    userType: "Admin",

    id: `12345`,
    firstName: `Kevin`,
    lastName: `Patel`,
    email: `Emma.Reid@Mail.Com`,
    tel: `096-730-4585`,
    username: `Kevin`,
    positionId: "1",
  }),
  mounted() {
    if (this.$store.getters.isAdmin) {
      this.$store.state.title = "จัดการโปรไฟล์ส่วนตัว";
      this.$store.state.showBackBtn = false;
    } else {
      this.$store.state.title = "โปรไฟล์ส่วนตัว";
      this.$store.state.showBackBtn = true;
    }
    this.$store.state.isBackToName = "/role/management";
    this.getPostion();
    this.get();
  },
  methods: {
    getPostion() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/position/all",
        })
        .then((resp) => {
          const ddlPostion = resp.data.data;
          if (ddlPostion) {
            this.ddlPostion = ddlPostion.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/profile",
        })
        .then((resp) => {
          const entity = resp.data.data;

          this.id = entity.id.toString().padStart(5, "0");
          this.firstName = entity.firstname;
          this.lastName = entity.lastname;
          this.isActive = entity.isActive;
          this.tel = entity.tel;
          this.email = entity.email;
          this.username = entity.username;
          this.positionId = entity.adminPosition.id;

          if (entity.adminRoles[0].id === 1) {
            this.userType = "RootAdmin";
          } else {
            this.userType = "Admin";
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
        });
    },
    update() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();

      formData.append("firstName", this.firstName.trim());
      formData.append("lastName", this.lastName.trim());
      formData.append("email", this.email);
      formData.append("tel", this.tel);
      formData.append("username", this.username);
      formData.append("adminPositionId", this.positionId);

      this.$root
        .appApi({
          method: "PUT",
          url: "admin/profile/",
          data: formData,
        })
        .then((resp) => {
          localStorage.setItem(
            "fullName",
            `${resp.data.data.firstname} ${resp.data.data.lastname}`
          );
          this.$store.state.fullName = `${resp.data.data.firstname} ${resp.data.data.lastname}`;
          this.$root.getSystemMessage(resp.data.message);
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
