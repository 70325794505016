<template>
  <div class="my-10">
    <v-row align="center" v-if="!isAdd">
      <v-col cols="12" sm="12" class="pl--title mb-5">
        <div style="color: #203486">Job. {{ jobNo }}</div>
        <div class="text-red" v-if="this.status == 'request'">สถานะ : {{ jobStatus }}</div>
        <div class="text-orange" v-if="this.status == 'wait' || this.status == 'ready'">สถานะ : {{ jobStatus }}</div>
        <div class="text-primary" v-if="this.status == 'doing'">สถานะ : {{ jobStatus }}</div>
        <div class="text-green" v-if="this.status == 'done'">สถานะ : {{ jobStatus }}</div>
        <div class="text-disable" v-if="this.status == 'cancel'">สถานะ : {{ jobStatus }}</div>
      </v-col>
    </v-row>
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> บริษัทลูกค้า </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select select--icon--center"
            hide-details="auto"
            color="app-theme"
            v-model="companyId"
            :items="ddlCustomerCompany"
            outlined
            :class="!isAdd ? 'disabled' : ''"
            :rules="[(v) => !!v || 'กรุณาเลือกบริษัทลูกค้า']"
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> เลือกยี่ห้อรถ </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select select--icon--center"
            hide-details="auto"
            color="app-theme"
            v-model="brandId"
            :items="ddlBrand"
            outlined
            :class="!isAdd ? 'disabled' : ''"
            :rules="[(v) => !!v || 'กรุณาเลือกยี่ห้อรถ']"
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> Yard </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select select--icon--center"
            hide-details="auto"
            color="app-theme"
            v-model="yardId"
            :items="ddlYard"
            outlined
            :class="!isAdd ? 'disabled' : ''"
            :rules="[(v) => !!v || 'กรุณาเลือกYard']"
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> แพลนวันที่นำรถเข้า </v-col>
        <v-col cols="12" sm="8">
          <v-menu v-model="menuStart" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="pl--input input--icon--center"
                :disabled="!isAdd"
                :class="!isAdd ? 'disabled' : ''"
                v-model="dateStartFormatted"
                persistent-hint
                append-icon="mdi-calendar-month"
                v-bind="attrs"
                v-on="on"
                outlined
                required
                hide-details="auto"
                :rules="[(v) => !!v || 'ระบุแพลนวันที่นำรถเข้า']"
                ><template v-slot:append> <v-img class="mr-2" src="@/assets/icons/ic_calendar_blue.svg" contain width="22"></v-img> </template
              ></v-text-field>
            </template>
            <v-date-picker v-model="dateStart" no-title @change="menuStart = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> แพลนเวลานำรถเข้า </v-col>
        <v-col cols="12" sm="8">
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="pl--input input--icon--center"
                :disabled="!isAdd"
                :class="!isAdd ? 'disabled' : ''"
                append-icon="mdi-clock-time-four-outline"
                hide-details="auto"
                color="app-theme"
                v-model="planTime"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[(v) => !!v || 'ระบุแพลนเวลานำรถเข้า']"
                ><template v-slot:append>
                  <v-icon large color="app-theme">mdi-clock-time-four-outline</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-time-picker v-if="menu2" v-model="planTime" format="24hr" landscape @click:minute="$refs.menu.save(planTime)"></v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title">นำเข้าไฟล์ Car-In</v-col>
        <v-col cols="10" sm="6" v-if="isAdd">
          <v-file-input
            class="placeholder--black"
            ref="upload"
            name="upload"
            color="app-theme"
            hide-details="auto"
            placeholder="เลือกไฟล์"
            outlined
            persistent-hint
            prepend-icon=""
            @change="onFileChange"
            :rules="[(v) => !!v || 'กรุณาเลือกไฟล์']"
            accept=".xlx,.xlsx"
          ></v-file-input>
        </v-col>
        <v-col cols="10" sm="2" v-if="isAdd">
          <v-btn @click="$refs.upload.$refs.input.click()" class="py-6" block rounded color="#2EAB29" dark style="text-transform: none !important">
            <b> Browse</b>
          </v-btn>
        </v-col>
        <v-col cols="10" sm="5" v-if="!isAdd">
          <a @click="downloadExcel"> Download file Car-In </a>
        </v-col>
      </v-row>
      <v-row align="center" class="mb-10" v-if="isAdd">
        <v-col cols="12" sm="3" class="pl-5 ma-0 pa-0"></v-col>
        <v-col cols="10" sm="7" class="py-0 my-0">
          <div style="font-size: 16px; color: grey">ชนิดไฟล์ .xlsx</div>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-5" v-if="isAdd && isSaveFile">
        <v-col cols="12" sm="7" class="mx-auto d-flex justify-center">
          <v-btn @click="$router.push('/import_vehicle/')" rounded color="app-disabled-button-theme" dark class="mr-4 my-2 px-8 py-6">ยกเลิก</v-btn>

          <v-btn rounded color="app-theme" dark class="my-2 px-8 py-6" type="submit">บันทึก</v-btn>
        </v-col>
      </v-row>

      <v-row align="center" class="my-10" v-if="!isSaveFile">
        <v-col cols="12" sm="12" class="pl-5 ma-0 pa-0 mt-10">
          <b>รายการนำเข้าไฟล์ Car-In เรียบร้อยแล้ว</b>
        </v-col>
      </v-row>

      <v-data-table
        v-if="!isSaveFile && flagTable === 'request'"
        fixed-header
        :height="items.length >= 7 ? '500px' : ''"
        hide-default-footer
        hide-default-header
        :headers="headerWithLocation"
        :items="items"
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :header-props="{
          sortIcon: 'mdi-menu-up',
        }"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          'items-per-page-options': rowsPerPage,
        }"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th class="text-center header-details" :colspan="7">ข้อมูลรถ</th>
              <th class="text-center parking" :colspan="4">กำหนดจุดจอด</th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th class="text-center header-details">No.</th>
              <th class="text-center header-details">VIN NO.</th>
              <th class="text-center header-details">Color Code</th>
              <th class="text-center header-details">Dts On Terminal</th>
              <th class="text-center header-details">Model Code</th>
              <th class="text-center header-details">MSC Code</th>
              <th class="text-center header-details">Aging</th>
              <th class="text-center parking">Phase</th>
              <th class="text-center parking">Zone</th>
              <th class="text-center parking">Row</th>
              <th class="text-center parking">Edit Location</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item.dts="{ item }">
          <div style="width: 200px">
            {{ item.dts }}
          </div>
        </template>
        <template v-slot:item.zone="{ item }">
          <div style="width: 100px">
            {{ item.zone }}
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <div style="width: 50px, text-align: center">
            <v-btn @click="showModalEditLocation(item)" dark color="white" min-width="20" class="btn-p-10" icon>
              <v-img src="@/assets/icons/ic_edit_location.png" contain max-height="40" max-width="40"></v-img>
            </v-btn>
          </div>
        </template>
        <template v-slot:footer> </template>
      </v-data-table>

      <v-data-table
        v-if="!isSaveFile && (flagTable === 'doing' || flagTable === 'wait')"
        fixed-header
        :height="items.length >= 7 ? '500px' : ''"
        hide-default-footer
        hide-default-header
        :headers="headers2WithLocation"
        :items="items"
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :header-props="{
          sortIcon: 'mdi-menu-up',
        }"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          'items-per-page-options': rowsPerPage,
        }"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th class="text-center primary-details" :colspan="8">กำหนดจุดจอด</th>
              <th class="text-center parking" :colspan="5">ข้อมูลรถ</th>
              <th class="text-center details" :colspan="6">รายละเอียดงาน</th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th class="text-center primary-details">No.</th>
              <th class="text-center primary-details">VIN NO.</th>

              <th class="text-center primary-details">วันที่จอดรถ</th>
              <th class="text-center primary-details">Phase</th>
              <th class="text-center primary-details">Zone</th>
              <th class="text-center primary-details">Row</th>
              <th class="text-center primary-details">Edit Location</th>
              <th class="text-center primary-details">สถานะรถ</th>

              <th class="text-center parking">Color Code</th>
              <th class="text-center parking">Dts On Terminal</th>
              <th class="text-center parking">Model Code</th>
              <th class="text-center parking">MSC Code</th>
              <th class="text-center parking">Aging</th>

              <th class="text-center details">RFID Tag No.</th>
              <th class="text-center details">วิธีการมอบหมาย</th>
              <th class="text-center details">วันที่มอบหมาย</th>
              <th class="text-center details">Labor ที่มอบหมาย</th>
              <th class="text-center details">วันที่ผ่าน Gate</th>
              <th class="text-center details">Labor ที่ขับ</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item.action="{ item }">
          <div style="width: 50px, text-align: center">
            <v-btn
              v-if="item.statusPark == 'request' || item.statusPark == 'wait'"
              @click="showModalEditLocation(item)"
              dark
              color="white"
              min-width="20"
              class="btn-p-10"
              icon
            >
              <v-img src="@/assets/icons/ic_edit_location.png" contain max-height="40" max-width="40"></v-img>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.dts="{ item }">
          <div style="width: 200px">
            {{ item.dts }}
          </div>
        </template>
        <template v-slot:item.zone="{ item }">
          <div style="width: 100px">
            {{ item.zone }}
          </div>
        </template>
        <template v-slot:item.dateAssign="{ item }">
          <div style="width: 200px">
            {{ item.dateAssign }}
          </div>
        </template>
        <template v-slot:item.dateGate="{ item }">
          <div style="width: 200px">
            {{ item.dateGate }}
          </div>
        </template>
        <template v-slot:item.dateParking="{ item }">
          <div style="width: 200px">
            {{ item.dateParking }}
          </div>
        </template>
        <template v-slot:item.laborDriver="{ item }">
          <div style="width: 120px">
            {{ item.laborDriver }}
          </div>
        </template>
        <template v-slot:item.statusPark="{ item }">
          <div style="width: 140px">
            <span class="text-primary" v-if="item.statusPark == 'request'"> รอผูก RFID </span>
            <span class="text-red" v-if="item.statusPark == 'ready'"> รอดำเนินการ </span>
            <span class="text-primary" v-if="item.statusPark == 'wait'"> รอผูก RFID </span>
            <span class="text-orange" v-if="item.statusPark == 'doing'"> กำลังดำเนินการ </span>
            <span class="text-green" v-if="item.statusPark == 'done'"> จอดเรียบร้อย </span>
            <span class="text-green" v-if="item.statusPark == 'out'"> นำออกจากยาร์ดแล้ว </span>
          </div>
        </template>
        <template v-slot:footer> </template>
      </v-data-table>

      <!-- Table Default -->
      <v-data-table
        v-if="!isSaveFile && flagTable === 'cancel'"
        fixed-header
        :height="items.length >= 7 ? '500px' : ''"
        hide-default-footer
        hide-default-header
        :headers="headers"
        :items="items"
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :header-props="{
          sortIcon: 'mdi-menu-up',
        }"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          'items-per-page-options': rowsPerPage,
        }"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th class="text-center header-details" :colspan="7">ข้อมูลรถ</th>
              <th class="text-center parking" :colspan="3">กำหนดจุดจอด</th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th class="text-center header-details">No.</th>
              <th class="text-center header-details">VIN NO.</th>
              <th class="text-center header-details">Color Code</th>
              <th class="text-center header-details">Dts On Terminal</th>
              <th class="text-center header-details">Model Code</th>
              <th class="text-center header-details">MSC Code</th>
              <th class="text-center header-details">Aging</th>
              <th class="text-center parking">Phase</th>
              <th class="text-center parking">Zone</th>
              <th class="text-center parking">Row</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item.dts="{ item }">
          <div style="width: 200px">
            {{ item.dts }}
          </div>
        </template>
        <template v-slot:item.zone="{ item }">
          <div style="width: 100px">
            {{ item.zone }}
          </div>
        </template>
        <template v-slot:footer> </template>
      </v-data-table>

      <v-data-table
        v-if="!isSaveFile && flagTable === 'done'"
        fixed-header
        :height="items.length >= 7 ? '500px' : ''"
        hide-default-footer
        hide-default-header
        :headers="headers2"
        :items="items"
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :header-props="{
          sortIcon: 'mdi-menu-up',
        }"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          'items-per-page-options': rowsPerPage,
        }"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th class="text-center primary-details" :colspan="7">กำหนดจุดจอด</th>
              <th class="text-center parking" :colspan="5">ข้อมูลรถ</th>
              <th class="text-center details" :colspan="6">รายละเอียดงาน</th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th class="text-center primary-details">No.</th>
              <th class="text-center primary-details">VIN NO.</th>

              <th class="text-center primary-details">วันที่จอดรถ</th>
              <th class="text-center primary-details">Phase</th>
              <th class="text-center primary-details">Zone</th>
              <th class="text-center primary-details">Row</th>
              <th class="text-center primary-details">สถานะรถ</th>

              <th class="text-center parking">Color Code</th>
              <th class="text-center parking">Dts On Terminal</th>
              <th class="text-center parking">Model Code</th>
              <th class="text-center parking">MSC Code</th>
              <th class="text-center parking">Aging</th>

              <th class="text-center details">RFID Tag No.</th>
              <th class="text-center details">วิธีการมอบหมาย</th>
              <th class="text-center details">วันที่มอบหมาย</th>
              <th class="text-center details">Labor ที่มอบหมาย</th>
              <th class="text-center details">วันที่ผ่าน Gate</th>
              <th class="text-center details">Labor ที่ขับ</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item.dts="{ item }">
          <div style="width: 200px">
            {{ item.dts }}
          </div>
        </template>
        <template v-slot:item.zone="{ item }">
          <div style="width: 100px">
            {{ item.zone }}
          </div>
        </template>
        <template v-slot:item.dateAssign="{ item }">
          <div style="width: 200px">
            {{ item.dateAssign }}
          </div>
        </template>
        <template v-slot:item.dateGate="{ item }">
          <div style="width: 200px">
            {{ item.dateGate }}
          </div>
        </template>
        <template v-slot:item.dateParking="{ item }">
          <div style="width: 200px">
            {{ item.dateParking }}
          </div>
        </template>
        <template v-slot:item.laborDriver="{ item }">
          <div style="width: 120px">
            {{ item.laborDriver }}
          </div>
        </template>
        <template v-slot:item.statusPark="{ item }">
          <div style="width: 140px">
            <span class="text-primary" v-if="item.statusPark == 'request'"> รอผูก RFID </span>
            <span class="text-red" v-if="item.statusPark == 'ready'"> รอดำเนินการ </span>
            <span class="text-primary" v-if="item.statusPark == 'wait'"> รอผูก RFID </span>
            <span class="text-orange" v-if="item.statusPark == 'doing'"> กำลังดำเนินการ </span>
            <span class="text-green" v-if="item.statusPark == 'done'"> จอดเรียบร้อย </span>
            <span class="text-green" v-if="item.statusPark == 'out'"> นำออกจากยาร์ดแล้ว </span>
          </div>
        </template>
        <template v-slot:footer> </template>
      </v-data-table>

      <v-row align="center" class="my-10" v-if="!isSaveFile && flagAssignDate">
        <v-col cols="12" sm="12" class="pl-5 ma-0 pa-0">
          <span class="text-primary">
            <h3>วัน-เวลาที่มอบหมาย Driver Leader : {{ dateDriverLeaderAssign }}</h3>
          </span>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-5" v-if="!isAdd && btnAssign">
        <v-col cols="12" sm="7" class="mx-auto d-flex justify-center">
          <v-btn rounded color="app-theme" dark class="my-2 px-8 py-6" @click="assign"><b>มอบหมายงานให้ Driver Leader</b></v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-form @submit.prevent="updateLocation()" ref="editLocationForm">
      <v-dialog v-model="dlgEditLocation" persistent max-width="700">
        <!-- <template v-slot:activator="{ on, attrs }"> </template> -->
        <v-card class="py-10">
          <v-card-text class="text-center text-body-1"> </v-card-text>
          <v-card-text class="text-center text-body-1">
            <div class="mt-1">
              <span class="font--delete">แก้ไข Location</span>
            </div></v-card-text
          >
          <v-card-text class="text-center text-body-1">
            <v-row>
              <v-col cols="3" class="text-right"><span class="text-primary-location">Vin No :</span></v-col>
              <v-col cols="8" class="text-left"
                ><span class="text-primary-location">{{ editLocationVinNo }}</span></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="3" class="text-right"><span class="text-primary-location">Yard :</span></v-col>
              <v-col cols="8" class="text-left"
                ><span class="text-primary-location">{{ editLocationYard }}</span></v-col
              >
            </v-row>
            <v-row align="center">
              <v-col cols="3" class="text-right"><span class="text-black">Phase </span></v-col>
              <v-col cols="8" class="text-left">
                <v-select
                  class="select--icon--center"
                  hide-details="auto"
                  color="app-theme"
                  placeholder=""
                  v-model="editLocationPhaseId"
                  @change="
                    editLocationZoneId = '';
                    editLocationRowId = '';
                  "
                  :items="ddlPhase"
                  outlined
                  :rules="[(v) => !!v || 'กรุณาเลือก Phase']"
                  ><template v-slot:append>
                    <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                  </template>
                </v-select></v-col
              >
            </v-row>
            <v-row align="center">
              <v-col cols="3" class="text-right"><span class="text-black">Zone </span></v-col>
              <v-col cols="8" class="text-left">
                <v-select
                  class="select--icon--center"
                  hide-details="auto"
                  color="app-theme"
                  placeholder=""
                  v-model="editLocationZoneId"
                  :items="ddlZone"
                  outlined
                  :rules="[(v) => !!v || 'กรุณาเลือก Zone']"
                  ><template v-slot:append>
                    <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                  </template>
                </v-select></v-col
              >
            </v-row>
            <v-row align="center">
              <v-col cols="3" class="text-right"><span class="text-black">Row </span></v-col>
              <v-col cols="8" class="text-left">
                <v-select
                  class="select--icon--center"
                  hide-details="auto"
                  color="app-theme"
                  placeholder=""
                  v-model="editLocationRowId"
                  :items="ddlRow"
                  outlined
                  :rules="[(v) => !!v || 'กรุณาเลือก Row']"
                  ><template v-slot:append>
                    <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                  </template>
                </v-select></v-col
              >
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <v-btn
              dark
              rounded
              color="app-disabled-button-theme"
              class="px-15 py-6 mr-4 btn--action"
              @click="
                dlgEditLocation = false;
                clearDataEditLocation();
              "
              >ยกเลิก</v-btn
            >
            <v-btn color="app-theme" dark rounded class="px-15 py-6 btn--action" @click="updateLocation()">บันทึก</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>

<script>
import helper from "@/helpers/helper";

export default {
  components: {},
  data() {
    return {
      editLocationId: "",
      ddlPhase: [],
      editLocationPhaseId: "",
      ddlZone: [],
      editLocationZoneId: "",
      ddlRow: [],
      editLocationRowId: "",
      dlgEditLocation: false,
      editLocationVinNo: "",
      editLocationYard: "",

      showBtn: false,
      dateDriverLeaderAssign: "",
      isSaveFile: true,
      flagTable: true,
      flagAssignDate: false,
      btnAssign: false,
      jobNo: "",
      status: "",
      jobStatus: "",
      helper: helper,
      isAdd: this.$route.name === "ImportVehicle.Add",

      companyId: null,
      brandId: null,
      yardId: null,
      planTime: "",
      minutes: "",
      hours: "",
      nowDate: new Date().toISOString().slice(0, 10),
      dateStart: "",
      dateStartFormatted: "",
      menuStart: false,

      time: null,
      menu2: false,
      modal2: false,

      ddlCustomerCompany: [],
      ddlBrand: [],
      ddlYard: [],

      page: 1,
      pageCount: 0,
      itemsPerPage: -1,
      rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
      headers: [
        {
          text: "No.",
          align: "center",
          sortable: false,
          value: "no",
        },
        {
          text: "VIN NO.",
          align: "center",
          sortable: false,
          value: "vinNo",
        },
        {
          text: "Color Code",
          align: "center",
          sortable: false,
          value: "colorCode",
        },
        {
          text: "Dts On Terminal",
          align: "center",
          sortable: false,
          value: "dts",
          width: "200px",
        },
        {
          text: "Model Code",
          align: "center",
          sortable: false,
          value: "modelCode",
        },
        {
          text: "MSC Code",
          align: "center",
          sortable: false,
          value: "mscCode",
        },
        {
          text: "Aging",
          align: "center",
          sortable: false,
          value: "aging",
        },
        {
          text: "Phase",
          align: "center",
          sortable: false,
          value: "phase",
        },
        {
          text: "Zone",
          align: "center",
          sortable: false,
          value: "zone",
          width: "120px",
        },
        {
          text: "Row",
          align: "center",
          sortable: false,
          value: "row",
        },
      ],
      items: [],
      headerWithLocation: [
        {
          text: "No.",
          align: "center",
          sortable: false,
          value: "no",
        },
        {
          text: "VIN NO.",
          align: "center",
          sortable: false,
          value: "vinNo",
        },
        {
          text: "Color Code",
          align: "center",
          sortable: false,
          value: "colorCode",
        },
        {
          text: "Dts On Terminal",
          align: "center",
          sortable: false,
          value: "dts",
          width: "200px",
        },
        {
          text: "Model Code",
          align: "center",
          sortable: false,
          value: "modelCode",
        },
        {
          text: "MSC Code",
          align: "center",
          sortable: false,
          value: "mscCode",
        },
        {
          text: "Aging",
          align: "center",
          sortable: false,
          value: "aging",
        },
        {
          text: "Phase",
          align: "center",
          sortable: false,
          value: "phase",
        },
        {
          text: "Zone",
          align: "center",
          sortable: false,
          value: "zone",
          width: "120px",
        },
        {
          text: "Row",
          align: "center",
          sortable: false,
          value: "row",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headers2: [
        {
          text: "No.",
          align: "center",
          sortable: false,
          value: "no",
        },
        {
          text: "VIN NO.",
          align: "center",
          sortable: false,
          value: "vinNo",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "dateParking",
        },
        {
          text: "Phase",
          align: "center",
          sortable: false,
          value: "phase",
        },
        {
          text: "Zone",
          align: "center",
          sortable: false,
          value: "zone",
          width: "120px",
        },
        {
          text: "Row",
          align: "center",
          sortable: false,
          value: "row",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "statusPark",
        },
        {
          text: "Color Code",
          align: "center",
          sortable: false,
          value: "colorCode",
        },
        {
          text: "Dts On Terminal",
          align: "center",
          sortable: false,
          value: "dts",
          width: "200px",
        },
        {
          text: "Model Code",
          align: "center",
          sortable: false,
          value: "modelCode",
        },
        {
          text: "MSC Code",
          align: "center",
          sortable: false,
          value: "mscCode",
        },
        {
          text: "Aging",
          align: "center",
          sortable: false,
          value: "aging",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "rfid",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "typeAssign",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "dateAssign",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "laborAssign",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "dateGate",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "laborDriver",
        },
      ],
      headers2WithLocation: [
        {
          text: "No.",
          align: "center",
          sortable: false,
          value: "no",
        },
        {
          text: "VIN NO.",
          align: "center",
          sortable: false,
          value: "vinNo",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "dateParking",
        },
        {
          text: "Phase",
          align: "center",
          sortable: false,
          value: "phase",
        },
        {
          text: "Zone",
          align: "center",
          sortable: false,
          value: "zone",
          width: "120px",
        },
        {
          text: "Row",
          align: "center",
          sortable: false,
          value: "row",
        },

        {
          text: "",
          align: "center",
          sortable: false,
          value: "action",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "statusPark",
        },
        {
          text: "Color Code",
          align: "center",
          sortable: false,
          value: "colorCode",
        },
        {
          text: "Dts On Terminal",
          align: "center",
          sortable: false,
          value: "dts",
          width: "200px",
        },
        {
          text: "Model Code",
          align: "center",
          sortable: false,
          value: "modelCode",
        },
        {
          text: "MSC Code",
          align: "center",
          sortable: false,
          value: "mscCode",
        },
        {
          text: "Aging",
          align: "center",
          sortable: false,
          value: "aging",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "rfid",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "typeAssign",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "dateAssign",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "laborAssign",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "dateGate",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "laborDriver",
        },
      ],
    };
  },
  mounted() {
    this.$store.state.title = "รับรถเข้าคลังจากลูกค้า";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/import_vehicle";

    this.getYard();
    this.getCompany();
    this.getBrand();
    this.getPhase();
    this.getZone();

    const now = new Date();
    const current = now.getHours().toString().padStart(2, "0") + ":" + now.getMinutes().toString().padStart(2, "0");
    this.planTime = current;

    if (this.$route.name === "ImportVehicle.Edit") {
      this.$store.state.title = "รายละเอียด";
      this.isSaveFile = false;
      this.get();
    }
  },
  watch: {
    dateStart() {
      this.dateStartFormatted = this.helper.formatDate(this.dateStart);
    },
    async editLocationZoneId() {
      if (this.editLocationZoneId) await this.getRow(this.editLocationZoneId);
    },
  },
  methods: {
    clearDataEditLocation() {
      this.editLocationVinNo = "";
      this.editLocationYard = "";
      this.editLocationPhaseId = "";
      this.editLocationZoneId = "";
      this.editLocationRowId = "";
    },
    getPhase() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-phase/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const entity = resp.data.data;
          if (entity) {
            this.ddlPhase = entity.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getZone() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-zone/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const entity = resp.data.data;
          if (entity) {
            this.ddlZone = entity.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    async getRow(zoneId) {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-zone-row/zone/" + zoneId,
          params: { page: this.page, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlRow = entity.map((item) => {
                return {
                  text: item.no,
                  value: item.id,
                };
              });
            }
            this.$store.state.loading = false;
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    async showModalEditLocation(carDetail) {
      this.dlgEditLocation = true;
      this.clearDataEditLocation();
      const phaseId = carDetail.yardPhaseId;
      const zoneId = carDetail.yardZoneId;
      const zoneRowId = carDetail.yardZoneRowId;

      this.editLocationVinNo = carDetail.vinNo;
      this.editLocationYard = carDetail.yardName;

      this.editLocationId = carDetail.carDetailId;
      this.editLocationPhaseId = phaseId;
      this.editLocationZoneId = zoneId;

      if (this.editLocationZoneId) await this.getRow(this.editLocationZoneId);
      this.editLocationRowId = zoneRowId;
    },
    updateLocation() {
      if (!this.$refs.editLocationForm.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("id", this.editLocationId);
      formData.append("yardPhaseId", this.editLocationPhaseId);
      formData.append("yardZoneId", this.editLocationZoneId);
      formData.append("yardZoneRowId", this.editLocationRowId);
      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/car-importing/location",
          data: formData,
        })
        .then((resp) => {
          this.dlgEditLocation = false;
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage("ไม่สามารถเปลี่ยนแปลงเป็น Location ที่ต้องการได้");
          } else {
            this.$root.getSystemMessage("บันทึกการเปลี่ยนแปลง Location สำเร็จ");
            this.get();
          }
          this.$store.state.loading = false;
        })
        .catch(() => {
          this.dlgEditLocation = false;
          this.$root.getErrorSystemMessage("ไม่สามารถเปลี่ยนแปลงเป็น Location ที่ต้องการได้");
          this.$store.state.loading = false;
        });
    },
    downloadExcel() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/car-importing/download/" + this.$route.params.id,
          responseType: "blob",
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Car-In`);
          document.body.appendChild(link);
          link.click();
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getYard() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlYard = resp.data.data;
          if (ddlYard) {
            this.ddlYard = ddlYard.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getCompany() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/user-company/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlCustomerCompany = entity.map((item) => {
                return {
                  value: item.id,
                  text: item.name,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getBrand() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/car-brand/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlBrand = entity.map((item) => {
                return {
                  text: item.name,
                  value: item.id,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    parseFullDateTime(value) {
      if (!value) return null;
      const [date, time] = value.split(" ");
      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year} ${time} น.`;
    },
    get() {
      this.$root
        .appApi({
          url: "admin/car-importing/" + this.$route.params.id,
        })
        .then((resp) => {
          if (resp.data.data) {
            const entity = resp.data.data;

            if (entity.status === "request") {
              this.jobStatus = "รอมอบหมาย Driver Leader";
              this.btnAssign = true;
            } else if (entity.status === "wait" || entity.status === "ready") {
              this.jobStatus = "รอดำเนินการ";
              this.flagAssignDate = true;
            } else if (entity.status === "cancel") {
              this.jobStatus = "ยกเลิก";
            }
            this.status = entity.status;
            this.jobNo = entity.jobNo;
            this.companyId = entity.userCompany.id;
            this.brandId = entity.carBrand.id;
            this.yardId = entity.yard.id;
            this.dateStart = entity.importDate;
            this.planTime = entity.importTime + " น.";

            const assignDT = entity.assignDatetime.split(" ");
            const assignDatetime = `${helper.formatDate(assignDT[0])} ${assignDT[1]}`;

            this.dateDriverLeaderAssign = helper.isNullOrEmpty(entity.assignDatetime) ? "-" : assignDatetime;
            if (entity.status === "request" || entity.status === "wait" || entity.status === "ready" || entity.status === "cancel") {
              this.items = entity.carDetailImportings.map((item, idx) => {
                const terminalDatetime = item.terminalDatetime.split(" ");
                const dts = `${helper.formatDate(terminalDatetime[0])} ${terminalDatetime[1]}`;
                return {
                  no: idx + 1,
                  vinNo: item.vinNo,
                  colorCode: item.colorCode,
                  dts: dts,
                  modelCode: item.modelCode,
                  mscCode: item.mscCode,
                  aging: item.aging,
                  phase: item.yardPhase ? item.yardPhase.name : "",
                  zone: item.yardZone ? item.yardZone.name : "",
                  row: item.yardZoneRow ? item.yardZoneRow.no : "",

                  carDetailId: item.id,
                  yardPhaseId: item.yardPhase ? item.yardPhase.id : null,
                  yardZoneId: item.yardZone ? item.yardZone.id : null,
                  yardZoneRowId: item.yardZoneRow ? item.yardZoneRow.id : null,
                  yardName: item.yardZone ? item.yardPhase.yard.name || "" : null,
                };
              });
            } else {
              if (entity.status === "doing") {
                this.jobStatus = "กำลังดำเนินการ";
                this.flagAssignDate = true;
              } else if (entity.status === "done") {
                this.jobStatus = "ดำเนินการเสร็จสิ้น";
              }
              this.items = entity.carDetailImportings.map((item, idx) => {
                const terminalDatetime = item.terminalDatetime.split(" ");
                const dts = `${helper.formatDate(terminalDatetime[0])} ${terminalDatetime[1]}`;
                let laborName = "";
                let laborAssign = "";
                if (item.driver) {
                  laborName = `${item.driver.firstname} ${item.driver.lastname}`;
                }
                if (item.laborAssign) {
                  laborAssign = `${item.laborAssign.firstname} ${item.laborAssign.lastname}`;
                }

                return {
                  no: idx + 1,
                  vinNo: item.vinNo,
                  colorCode: item.colorCode,
                  dts: dts,
                  modelCode: item.modelCode,
                  mscCode: item.mscCode,
                  aging: item.aging,
                  phase: item.yardPhase ? item.yardPhase.name : "",
                  zone: item.yardZone ? item.yardZone.name : "",
                  row: item.yardZoneRow ? item.yardZoneRow.no : "",

                  rfid: item.rfidNo,
                  typeAssign: item.assignType,
                  dateAssign: this.parseFullDateTime(item.assignAt),
                  laborAssign: laborAssign,
                  dateGate: this.parseFullDateTime(item.carInAt),
                  dateParking: this.parseFullDateTime(item.carParkAt),
                  laborDriver: laborName,
                  statusPark: item.status,

                  carDetailId: item.id,
                  yardPhaseId: item.yardPhase ? item.yardPhase.id : null,
                  yardZoneId: item.yardZone ? item.yardZone.id : null,
                  yardZoneRowId: item.yardZoneRow ? item.yardZoneRow.id : null,
                  yardName: item.yardZone ? item.yardPhase.yard.name || "" : null,
                };
              });
            }
            switch (entity.status) {
              case "request":
                this.flagTable = "request";
                break;
              case "wait" || "ready":
                this.flagTable = "wait";
                break;
              case "doing":
                this.flagTable = "doing";
                break;
              case "done":
                this.flagTable = "done";
                break;
              case "cancel":
                this.flagTable = "cancel";
                break;
              default:
                break;
            }
          } else {
            this.items = [];
          }
          this.showBtn = false;
          this.showTable = true;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    add() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("userCompanyId", this.companyId);
      formData.append("carBrandId", this.brandId);
      formData.append("yardId", this.yardId);
      formData.append("file", this.file);

      const importDatetime = `${this.dateStart} ${this.planTime}`;
      formData.append("importDatetime", importDatetime);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/car-importing",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(resp.data.message);
          }
          if (resp.data.data) {
            this.isSaveFile = false;
            this.flagTable = true;
            const entity = resp.data.data;
            this.$root
              .appApi({
                url: "admin/car-importing/" + entity.id,
              })
              .then((resp) => {
                const errors = resp.data.errors;
                if (errors) {
                  this.$root.getErrorSystemMessage(errors);
                } else {
                  this.$root.getSystemMessage(resp.data.message);
                }
                if (resp.data.data) {
                  const entity = resp.data.data;
                  this.items = entity.carDetailImportings.map((item, idx) => {
                    return {
                      no: idx + 1,
                      vinNo: item.vinNo,
                      colorCode: item.colorCode,
                      dts: item.terminalDatetime,
                      modelCode: item.modelCode,
                      mscCode: item.mscCode,
                      aging: item.aging,
                      phase: item.yardPhase ? item.yardPhase.name : "",
                      zone: item.yardZone ? item.yardZone.name : "",
                      row: item.yardZoneRow ? item.yardZoneRow.no : "",
                    };
                  });
                } else {
                  this.items = [];
                }
                this.showBtn = false;
                this.showTable = true;
                this.$store.state.loading = false;
              })
              .catch((err) => {
                this.$root.getErrorSystemMessage(err);
                this.$store.state.loading = false;
              });
          } else {
            this.items = [];
          }
          this.showBtn = false;
          this.showTable = true;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    assign() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("carImportingId", this.$route.params.id);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/car-importing/assign",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(resp.data.message, "/import_vehicle");
          }

          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    onFileChange(file) {
      if (file) {
        if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          this.file = file;
          this.showBtn = true;
        } else {
          this.file = null;
          this.showBtn = false;
          this.$refs.upload.reset();
          this.$root.getErrorSystemMessage("กรุณาเลือกไฟล์ชนิด .xlsx เท่านั้น");
        }
      } else {
        this.file = null;
        this.showBtn = false;
      }
    },
    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ":", ...minutes, " น."] : hours;
    },
  },
};
</script>

<style scoped>
.text-black {
  color: #000000;
}
.font--delete {
  font-size: 28px !important;
  font-weight: bold !important;
  color: #000000 !important;
}
.disabled {
  background-color: #dfdfdf;
}
.v-data-table >>> ::-webkit-scrollbar {
  height: 20px !important;
  border: 1px solid #aaa !important;
  border-radius: 15px !important;
}
.v-data-table >>> ::-webkit-scrollbar-thumb {
  background: #283593 !important;
  border-radius: 15px !important;
}
.v-data-table >>> th {
  border: 1px solid #dfdfdf;
}
.v-data-table >>> td {
  border-left: 1px solid #dfdfdf;
}
.primary-details {
  color: white;
  background-color: #203486;
  border-left: 1px solid #dfdfdf !important;
  border-bottom: 1px solid #dfdfdf !important;
}
.parking {
  color: white;
  background-color: #5293fb !important;
  border-left: 1px solid #dfdfdf !important;
  border-bottom: 1px solid #dfdfdf !important;
}
.details {
  color: white;
  background-color: #58b997 !important;
  border-left: 1px solid #dfdfdf !important;
  border-bottom: 1px solid #dfdfdf !important;
}
.text-red {
  color: red;
}
.text-orange {
  color: orange;
}
.text-primary {
  color: #203486;
}
.text-primary-location {
  color: #203486;
  font-size: 28px !important;
  font-weight: bold !important;
}
.text-green {
  color: #508b21;
}
.text-disable {
  color: #ddd;
}
.placeholder--black >>> .v-file-input__text--placeholder {
  color: black;
}
</style>
