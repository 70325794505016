<template>
  <v-container>
    <v-row class="mt-1 mb-0 pb-0">
      <v-col cols="1" class="pt-5 text-center pb-0">
        <h3 style="color: #041e41">ค้นหา</h3>
      </v-col>
      <v-col cols="9" class="pb-0">
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="ชื่อ/นามสกุล"
              class="pl--input"
              hide-details="auto"
              color="app-theme"
              v-model="name"
              solo
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="userCompany"
              :items="ddlCompany"
              class="pl--select2"
              label="บริษัท"
              hide-details="auto"
              color="app-theme"
              solo
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="เบอร์โทรศัพท์"
              class="pl--input"
              hide-details="auto"
              color="app-theme"
              v-model="tel"
              solo
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="text-end pr-15 pb-0">
        <v-btn
          class="pa-6"
          outlined
          rounded
          color="app-theme"
          dark
          style="text-transform: none !important"
        >
          <b> ค้นหา</b>
        </v-btn>
      </v-col>
      <v-col cols="1" class="text-center pt-0"> </v-col>
      <v-col cols="9">
        <v-row>
          <v-col cols="4">
            <v-text-field
              class="pl--input"
              label="อีเมล"
              hide-details="auto"
              color="app-theme"
              solo
              v-model="email"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="text-end pr-15">
        <v-btn
          class="py-6"
          :to="{ name: 'Customer.Add' }"
          rounded
          color="app-theme"
          dark
          style="text-transform: none !important"
        >
          <v-icon>mdi-plus</v-icon>
          <b> เพิ่มข้อมูลลูกค้า</b>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      single-expand
      hide-default-footer
      :headers="headers"
      :items="items"
      class="elevation-1"
      :page.sync="page"
      :items-per-page="pageSize"
      @page-count="pageCount = $event"
      :header-props="{
        sortIcon: 'mdi-menu-up',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        'items-per-page-options': rowsPerPage,
      }"
    >
      <template v-slot:item.userCompany="{ item }">
        <div style="width: 100%; white-space: nowrap">
          <span>
            {{ item.userCompany }}
          </span>
        </div>
      </template>
      <template v-slot:item.email="{ item }">
        <div style="width: 100%; white-space: nowrap">
          <span>
            {{ item.email }}
          </span>
        </div>
      </template>
      <template v-slot:item.tel="{ item }">
        <div style="white-space: nowrap; width: 100px">
          <span>
            {{ item.tel }}
          </span>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <router-link
          :to="{ name: 'Customer.Edit', params: { id: item.id } }"
          class="text-decoration-none mr-3"
        >
          <v-btn dark color="app-theme"><b>แก้ไข</b></v-btn>
        </router-link>

        <v-btn
          dark
          @click="deleteDialog(item.id)"
          color="app-danger"
          min-width="20"
          class="btn-p-10"
          ><v-icon color="app-primary">mdi-trash-can</v-icon></v-btn
        >
      </template>
      <template v-slot:footer> </template>
    </v-data-table>

    <DialogDelete :dialog.sync="showDialog" :deleteId="deleteId"></DialogDelete>
    <pagination
      ref="Pagination"
      :page="page"
      :page-size="pageSize"
      @changePage="page = $event"
      @changePageSize="pageSize = $event"
    />
  </v-container>
</template>
<style scoped>
.v-data-table >>> tr > th > span {
  font-weight: 0 !important;
  font-size: 18px !important;
}
</style>
<script>
import DialogDelete from "@/components/DialogDelete";
import Pagination from "@/components/Pagination";
export default {
  components: {
    DialogDelete,
    Pagination,
  },
  data: () => ({
    name: "",
    userCompany: "",
    ddlCompany: [],
    tel: "",
    email: "",
    showDialog: false,
    deleteId: null,
    page: 1,
    pageCount: 0,
    pageSize: 10,
    itemsPerPage: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "ลำดับ",
        align: "center",
        value: "running",
      },
      {
        text: "ชื่อ",
        align: "center",
        value: "firstname",
      },
      {
        text: "นามสกุล",
        align: "center",
        value: "lastname",
      },
      {
        text: "บริษัท",
        align: "center",
        value: "userCompany",
      },
      {
        text: "อีเมล",
        align: "center",
        value: "email",

        width: "10%",
      },
      {
        text: "เบอร์โทรศัพท์",
        align: "center",
        value: "tel",
        width: "10%",
      },
      {
        text: "จัดการ",
        sortable: false,
        align: "center",
        value: "actions",
        width: "180px",
      },
    ],
    items: [],
  }),
  mounted() {
    this.$store.state.title = "จัดการข้อมูลลูกค้า";
    this.$store.state.showBackBtn = false;
    this.getCompany();
    this.search();
  },
  watch: {
    page() {
      this.search();
    },
    pageSize() {
      this.search();
    },
    name() {
      this.search();
    },
    userCompany() {
      this.search();
    },
    tel() {
      this.search();
    },
    email() {
      this.search();
    },
  },
  methods: {
    getCompany() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/user-company/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlCompany = resp.data.data;
          if (ddlCompany) {
            this.ddlCompany = [{ text: "ทั้งหมด", value: "" }];
            const company = ddlCompany.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
            this.ddlCompany = this.ddlCompany.concat(company);
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    search() {
      const formData = new FormData();
      formData.append("name", this.name.trim());
      formData.append("userCompanyId", this.userCompany);
      formData.append("tel", this.tel.trim());
      formData.append("email", this.email.trim());
      this.$root
        .appApi({
          method: "POST",
          url: "admin/user/search",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item, index) => {
                let addr = "-";
                if (item.userCompany) {
                  if (item.userCompany.id !== 0) addr = item.userCompany.name;
                }
                return {
                  id: item.id,
                  running: (this.page - 1) * this.pageSize + 1 + index,
                  firstname: item.firstname,
                  lastname: item.lastname,
                  userCompany: addr,
                  email: item.email,
                  tel: item.tel,
                };
              });
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data.length
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/user/all",
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item, index) => {
                let addr = "-";
                if (item.userCompany) {
                  if (item.userCompany.id !== 0) addr = item.userCompany.name;
                }
                return {
                  id: item.id,
                  running: (this.page - 1) * this.pageSize + 1 + index,
                  firstname: item.firstname,
                  lastname: item.lastname,
                  userCompany: addr,
                  email: item.email,
                  tel: item.tel,
                };
              });
            }

            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data.length
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    confirmDelete(id) {
      this.$store.state.loading = true;
      this.$root
        .appApi({ method: "DELETE", url: "admin/user/" + id })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.$root.getSystemMessage(resp.data.message);
          }
          this.get();
        })
        .catch((err) => {
          if (err.message) {
            this.$root.getErrorSystemMessage(err.message);
          } else {
            this.$root.getErrorSystemMessage(err);
          }
        });
    },
    deleteDialog(id) {
      this.showDialog = true;
      this.deleteId = id;
    },
  },
};
</script>

<style></style>
