<template>
  <div class="my-10">
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>ประเภทการลา</b> </v-col>
        <v-col cols="12" sm="8">
          <v-text-field
            v-model="name"
            color="app-theme"
            placeholder="ประเภทการลา"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุประเภทการลา']"
            autocomplete="off"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>ตัวย่อ</b> </v-col>
        <v-col cols="12" sm="8">
          <v-text-field
            v-model="initName"
            color="app-theme"
            placeholder="ตัวย่อ"
            outlined
            hide-details="auto"
            autocomplete="off"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/setting/leave_type/')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6 mt-15"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6 mt-15"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAdd: this.$route.name === "SettingLeaveType.Add",
      name: "",
      initName: "",
    };
  },
  mounted() {
    this.$store.state.title = "เพิ่มประเภทการลา";
    this.$store.state.showBackBtn = false;
    this.$store.state.isBackToName = "/setting/leave_type";
    if (this.$route.name === "SettingLeaveType.Edit") {
      this.get();
      this.$store.state.title = "แก้ไขประเภทการลา";
    }
  },
  methods: {
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/leave-type/" + this.$route.params.id,
        })
        .then((resp) => {
          this.name = resp.data.data.name;
          this.initName = resp.data.data.initName;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    add() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("initName", this.initName);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/leave-type",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/setting/leave_type"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    update() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("name", this.name);
      formData.append("initName", this.initName);
      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/leave-type",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/setting/leave_type"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
