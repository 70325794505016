<template>
  <v-container>
    <v-form @submit.prevent="search()" ref="form">
      <v-row class="my-1" align="center">
        <v-col cols="1" align="center" class="text-center">
          <b>ค้นหาวันที่</b>
        </v-col>
        <v-col cols="8">
          <v-row>
            <v-col cols="12" sm="12">
              <v-row align="center">
                <v-col cols="12" sm="5">
                  <v-menu
                    v-model="menuStart"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateStartFormatted"
                        class="input--icon--center"
                        persistent-hint
                        append-icon="mdi-calendar-month"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details="auto"
                        placeholder="เริ่มต้น"
                        :rules="[(v) => !!v || 'กรุณาระบุวันที่เริ่มต้น']"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateStart"
                      no-title
                      @change="menuStart = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                -
                <v-col cols="12" sm="5">
                  <v-menu
                    v-model="menuEnd"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateEndFormatted"
                        class="input--icon--center"
                        persistent-hint
                        append-icon="mdi-calendar-month"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details="auto"
                        placeholder="สิ้นสุด"
                        :rules="[(v) => !!v || 'กรุณาระบุวันที่สิ้นสุด']"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateEnd"
                      no-title
                      @change="menuEnd = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" class="text-right">
          <v-btn
            class="py-6"
            rounded
            outlined
            color="app-theme"
            dark
            style="text-transform: none !important"
            type="submit"
          >
            <b> ค้นหา</b>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="my-1">
        <v-col cols="6">
          <v-row align="center">
            <v-col cols="2" align="center" class="text-start pl-5">
              <b>Yard</b>
            </v-col>
            <v-col cols="10" sm="10" class="pa-0">
              <v-row align="center" class="pl-2">
                <v-col cols="12" sm="6">
                  <v-select
                    class="pl--select select--icon--center"
                    hide-details="auto"
                    color="app-theme"
                    outlined
                    v-model="yardId"
                    :items="ddlYard"
                    :rules="[(v) => !!v || 'กรุณาเลือก Yard']"
                    ><template v-slot:append>
                      <v-icon large>mdi-chevron-down</v-icon>
                    </template></v-select
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row></v-col
        >
        <v-col cols="6" class="text-right">
          <v-btn
            class="py-6 mr-4"
            rounded
            color="app-export-theme"
            dark
            style="text-transform: none !important"
            @click="downloadExcel"
          >
            <b>ส่งออก Excel</b>
          </v-btn>
          <v-btn
            class="py-6 mr-4"
            style="text-transform: none !important; border-radius: 10px"
            :color="listActive ? 'app-theme' : 'white'"
            @click="
              listActive = !listActive;
              calendarActive = false;
            "
            min-width="0"
            width="60px"
          >
            <b>
              <v-icon :color="listActive ? 'white' : 'app-theme'" x-large
                >mdi-format-list-bulleted</v-icon
              >
            </b>
          </v-btn>
          <v-btn
            class="py-6"
            style="text-transform: none !important; border-radius: 10px"
            :color="calendarActive ? 'app-theme' : 'white'"
            @click="btnCalendarClick"
            min-width="0"
            width="60px"
          >
            <b>
              <v-icon :color="calendarActive ? 'white' : 'app-theme'" x-large
                >mdi-calendar-month</v-icon
              ></b
            >
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      v-if="listActive"
      hide-default-footer
      :headers="headers"
      :items="items"
      class="elevation-1"
      :page.sync="page"
      :items-per-page="pageSize"
      @page-count="pageCount = $event"
      :header-props="{
        sortIcon: 'mdi-menu-up',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        'items-per-page-options': rowsPerPage,
      }"
    >
      <template v-slot:item.staffRequire="{ item }">
        <div class="item--center">
          {{ item.staffRequire }}
        </div>
      </template>
      <template v-slot:item.staffCurrent="{ item }">
        <div class="item--center">
          {{ item.staffCurrent }}
        </div>
      </template>
      <template v-slot:item.isStatus="{ item }">
        <div style="color: green" v-if="item.isStatus === 0">พอดี</div>
        <div style="color: red" v-else-if="item.isStatus < 0">ขาด</div>
        <div style="color: blue" v-else-if="item.isStatus > 0">เกิน</div>
        <div class="item--center" v-else>-</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <router-link
          :to="{
            name: 'LaborSchedule.Edit',
            params: { date: item._date, yardId: item.yardId },
          }"
          class="text-decoration-none mr-1"
        >
          <v-btn @click="setValueBack" dark color="app-theme"
            ><b>แก้ไข</b></v-btn
          >
        </router-link>
      </template>
      <template v-slot:footer> </template>
    </v-data-table>

    <div v-if="calendarActive" class="px-5">
      <v-card>
        <v-sheet tile height="54" class="d-flex">
          <v-btn icon class="ma-2" @click="getDataCalendar(-1)">
            <v-icon x-large>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <h1 style="color: #203486">
            {{ `${helper.getMonth(month)} ${this.year}` }}
          </h1>
          <v-spacer></v-spacer>
          <v-btn icon class="ma-2" @click="getDataCalendar(1)">
            <v-icon x-large>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>
        <v-sheet height="600">
          <v-simple-table class="elevation-1">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center th"><h2>Mon</h2></th>
                  <th class="text-center th"><h2>Tue</h2></th>
                  <th class="text-center th"><h2>Wed</h2></th>
                  <th class="text-center th"><h2>Thu</h2></th>
                  <th class="text-center th"><h2>Fri</h2></th>
                  <th class="text-center th"><h2>Sat</h2></th>
                  <th class="text-center th"><h2>Sun</h2></th>
                </tr>
              </thead>
              <tbody class="elevation-1">
                <tr v-for="(item, idx) in calendar" :key="idx">
                  <td
                    class="text-center"
                    :class="
                      item.day[1].dateNumber
                        ? item.day[1].laborCount === 0
                          ? 'bg-default'
                          : item.day[1].laborCount > 0
                          ? 'bg-theme text-w'
                          : item.day[1].laborCount < 0
                          ? 'bg-red text-w'
                          : 'bg-default'
                        : ''
                    "
                  >
                    <div
                      class="calendar-wh-100"
                      @click="
                        dayClick(item.day[1].dateNumber, item.day[1].event)
                      "
                    >
                      <h1 class="text-center td-center">
                        {{ item.day[1].dateNumber }}
                      </h1>
                      <h4
                        class="text-end mt-8 text-g"
                        v-if="item.day[1].laborCount === 0"
                      >
                        ● พอดี
                      </h4>
                      <h4
                        class="text-end mt-8"
                        v-if="item.day[1].laborCount < 0"
                      >
                        ● ขาด
                      </h4>
                      <h4
                        class="text-end mt-8"
                        v-if="item.day[1].laborCount > 0"
                      >
                        ● เกิน
                      </h4>
                    </div>
                  </td>
                  <td
                    class="text-center"
                    :class="
                      item.day[2].dateNumber
                        ? item.day[2].laborCount === 0
                          ? 'bg-default'
                          : item.day[2].laborCount > 0
                          ? 'bg-theme text-w'
                          : item.day[2].laborCount < 0
                          ? 'bg-red text-w'
                          : 'bg-default'
                        : ''
                    "
                  >
                    <div
                      class="calendar-wh-100"
                      @click="
                        dayClick(item.day[2].dateNumber, item.day[2].event)
                      "
                    >
                      <h1 class="text-center td-center">
                        {{ item.day[2].dateNumber }}
                      </h1>
                      <h4
                        class="text-end mt-8 text-g"
                        v-if="item.day[2].laborCount === 0"
                      >
                        ● พอดี
                      </h4>
                      <h4
                        class="text-end mt-8"
                        v-if="item.day[2].laborCount < 0"
                      >
                        ● ขาด
                      </h4>
                      <h4
                        class="text-end mt-8"
                        v-if="item.day[2].laborCount > 0"
                      >
                        ● เกิน
                      </h4>
                    </div>
                  </td>
                  <td
                    class="text-center"
                    :class="
                      item.day[3].dateNumber
                        ? item.day[3].laborCount === 0
                          ? 'bg-default'
                          : item.day[3].laborCount > 0
                          ? 'bg-theme text-w'
                          : item.day[3].laborCount < 0
                          ? 'bg-red text-w'
                          : 'bg-default'
                        : ''
                    "
                  >
                    <div
                      class="calendar-wh-100"
                      @click="
                        dayClick(item.day[3].dateNumber, item.day[3].event)
                      "
                    >
                      <h1 class="text-center td-center">
                        {{ item.day[3].dateNumber }}
                      </h1>
                      <h4
                        class="text-end mt-8 text-g"
                        v-if="item.day[3].laborCount === 0"
                      >
                        ● พอดี
                      </h4>
                      <h4
                        class="text-end mt-8"
                        v-if="item.day[3].laborCount < 0"
                      >
                        ● ขาด
                      </h4>
                      <h4
                        class="text-end mt-8"
                        v-if="item.day[3].laborCount > 0"
                      >
                        ● เกิน
                      </h4>
                    </div>
                  </td>
                  <td
                    class="text-center"
                    :class="
                      item.day[4].dateNumber
                        ? item.day[4].laborCount === 0
                          ? 'bg-default'
                          : item.day[4].laborCount > 0
                          ? 'bg-theme text-w'
                          : item.day[4].laborCount < 0
                          ? 'bg-red text-w'
                          : 'bg-default'
                        : ''
                    "
                  >
                    <div
                      class="calendar-wh-100"
                      @click="
                        dayClick(item.day[4].dateNumber, item.day[4].event)
                      "
                    >
                      <h1 class="text-center td-center">
                        {{ item.day[4].dateNumber }}
                      </h1>
                      <h4
                        class="text-end mt-8 text-g"
                        v-if="item.day[4].laborCount === 0"
                      >
                        ● พอดี
                      </h4>
                      <h4
                        class="text-end mt-8"
                        v-if="item.day[4].laborCount < 0"
                      >
                        ● ขาด
                      </h4>
                      <h4
                        class="text-end mt-8"
                        v-if="item.day[4].laborCount > 0"
                      >
                        ● เกิน
                      </h4>
                    </div>
                  </td>
                  <td
                    class="text-center"
                    :class="
                      item.day[5].dateNumber
                        ? item.day[5].laborCount === 0
                          ? 'bg-default'
                          : item.day[5].laborCount > 0
                          ? 'bg-theme text-w'
                          : item.day[5].laborCount < 0
                          ? 'bg-red text-w'
                          : 'bg-default'
                        : ''
                    "
                  >
                    <div
                      class="calendar-wh-100"
                      @click="
                        dayClick(item.day[5].dateNumber, item.day[5].event)
                      "
                    >
                      <h1 class="text-center td-center">
                        {{ item.day[5].dateNumber }}
                      </h1>
                      <h4
                        class="text-end mt-8 text-g"
                        v-if="item.day[5].laborCount === 0"
                      >
                        ● พอดี
                      </h4>
                      <h4
                        class="text-end mt-8"
                        v-if="item.day[5].laborCount < 0"
                      >
                        ● ขาด
                      </h4>
                      <h4
                        class="text-end mt-8"
                        v-if="item.day[5].laborCount > 0"
                      >
                        ● เกิน
                      </h4>
                    </div>
                  </td>
                  <td
                    class="text-center"
                    :class="
                      item.day[6].dateNumber
                        ? item.day[6].laborCount === 0
                          ? 'bg-default'
                          : item.day[6].laborCount > 0
                          ? 'bg-theme text-w'
                          : item.day[6].laborCount < 0
                          ? 'bg-red text-w'
                          : 'bg-default'
                        : ''
                    "
                  >
                    <div
                      class="calendar-wh-100"
                      @click="
                        dayClick(item.day[6].dateNumber, item.day[6].event)
                      "
                    >
                      <h1 class="text-center td-center">
                        {{ item.day[6].dateNumber }}
                      </h1>
                      <h4
                        class="text-end mt-8 text-g"
                        v-if="item.day[6].laborCount === 0"
                      >
                        ● พอดี
                      </h4>
                      <h4
                        class="text-end mt-8"
                        v-if="item.day[6].laborCount < 0"
                      >
                        ● ขาด
                      </h4>
                      <h4
                        class="text-end mt-8"
                        v-if="item.day[6].laborCount > 0"
                      >
                        ● เกิน
                      </h4>
                    </div>
                  </td>
                  <td
                    class="text-center"
                    :class="
                      item.day[0].laborCount === 0
                        ? 'bg-default'
                        : item.day[0].laborCount > 0
                        ? 'bg-theme text-w'
                        : item.day[0].laborCount < 0
                        ? 'bg-red text-w'
                        : 'bg-default'
                    "
                  >
                    <div
                      class="calendar-wh-100"
                      @click="
                        dayClick(item.day[0].dateNumber, item.day[0].event)
                      "
                    >
                      <h1 class="text-center td-center">
                        {{ item.day[0].dateNumber }}
                      </h1>
                      <h4
                        class="text-end mt-8 text-g"
                        v-if="item.day[0].laborCount === 0"
                      >
                        ● พอดี
                      </h4>
                      <h4
                        class="text-end mt-8"
                        v-if="item.day[0].laborCount < 0"
                      >
                        ● ขาด
                      </h4>
                      <h4
                        class="text-end mt-8"
                        v-if="item.day[0].laborCount > 0"
                      >
                        ● เกิน
                      </h4>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-card>
    </div>
    <Pagination
      v-show="listActive"
      ref="Pagination"
      :page="page"
      :page-size="pageSize"
      @changePage="page = $event"
      @changePageSize="pageSize = $event"
    />
  </v-container>
</template>

<style scoped>
.td-center {
  top: 50%;
  transform: translate(0, 50%);
}
div >>> .green:parent {
  background-color: red !important;
}

.th {
  /* background-color: blue; */
  color: white !important;
  border-left: 1px solid #ddd;
  height: 80px !important;
}
td {
  border-left: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
  height: 100px !important;
}
tr:hover {
  background-color: transparent !important;
}
tr >>> .bg-theme:hover {
  background-color: #515b85 !important;
}
tr >>> .bg-red:hover {
  background-color: #ff9e9e !important;
}
tr >>> .bg-disable:hover {
  background-color: red !important;
}
tr >>> .bg-default:hover {
  background-color: #eee !important;
}
.calendar-wh-100 {
  height: 100%;
  width: 100%;
}
.bg-theme {
  background-color: #203486 !important;
}
.text-g {
  color: #508b21;
}
.text-black {
  color: black;
}
.text-w {
  color: white;
}
.bg-disable {
  color: green;
}
</style>
<script>
import Pagination from "@/components/Pagination";
import helper from "@/helpers/helper";
export default {
  components: {
    Pagination,
  },

  data: () => ({
    listActive: true,
    calendarActive: false,
    helper: helper,
    dateStart: "",
    dateEnd: "",
    dateStartFormatted: "",
    dateEndFormatted: "",
    menuStart: false,
    menuEnd: false,
    yardId: "",
    ddlYard: [],
    page: 1,
    pageCount: 0,
    pageSize: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "วันที่",
        align: "center",
        value: "date",
      },
      { text: "กำลังพลที่ต้องการ", align: "center", value: "staffRequire" },
      { text: "กำลังพลที่มี", align: "center", value: "staffCurrent" },
      { text: "สถานะ", align: "center", value: "isStatus" },
      {
        text: "",
        sortable: false,
        align: "center",
        value: "actions",
        width: "180px",
      },
    ],
    items: [],
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    calendar: [],
    value: new Date(),
    event: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    eventDefault: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  }),

  watch: {
    dateStart() {
      this.dateStartFormatted = this.helper.formatDate(this.dateStart);
    },
    dateEnd() {
      this.dateEndFormatted = this.helper.formatDate(this.dateEnd);
    },
    page() {
      this.search();
    },
    pageSize() {
      this.search();
    },
    yardId() {
      this.getDataCalendar();
    },
  },
  mounted() {
    this.$store.state.title = "ตารางการทำงานของกำลังพล";
    this.$store.state.showBackBtn = false;
    if (this.$store.state.isBackSchedule == true) {
      this.dateStart = this.$store.state.laborScheduleStartDate;
      this.dateEnd = this.$store.state.laborScheduleEndDate;
      this.yardId = this.$store.state.laborScheduleYardId;
      this.page = this.$store.state.laborSchedulePage;
      this.searchHistoryBack();
      this.$store.state.laborScheduleStartDate = "";
      this.$store.state.laborScheduleEndDate = "";
      this.$store.state.laborScheduleYardId = "";
      this.$store.state.laborSchedulePage = 1;
      this.$store.state.isBackSchedule = false;
    }
    this.getYard();
  },
  methods: {
    setValueBack() {
      this.$store.state.laborScheduleStartDate = this.dateStart;
      this.$store.state.laborScheduleEndDate = this.dateEnd;
      this.$store.state.laborScheduleYardId = this.yardId;
    },
    downloadExcel() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("startDate", this.dateStart);
      formData.append("endDate", this.dateEnd);
      formData.append("yardId", this.yardId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/staff-work/export",
          data: formData,
          responseType: "blob",
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `staff-work_${this.dateStart}_to_${this.dateEnd}`
          );
          document.body.appendChild(link);
          link.click();
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    btnCalendarClick() {
      this.calendarActive = !this.calendarActive;
      this.getDataCalendar();
      this.listActive = false;
    },
    dayClick(dateNumber, event) {
      if (event && dateNumber && this.yardId) {
        this.$router.push({
          path:
            "/labor/schedule/edit/" +
            `${dateNumber}%2F${(this.month + 1)
              .toString()
              .padStart(2, "0")}%2F${this.year}&${this.yardId}`,
        });
      }
    },
    getCalendar(value) {
      if (value > 0) {
        if (this.month == 11) {
          this.year = this.year + 1;
          this.month = 0;
        } else {
          this.month = this.month + 1;
        }
      } else if (value < 0) {
        if (this.month == 0) {
          this.year = this.year - 1;
          this.month = 11;
        } else {
          this.month = this.month - 1;
        }
      }
      let calendar = [];
      const week = this.getWeeksInMonth(this.year, this.month);
      const firstDay = new Date(this.year, this.month, 1);
      const lastDay = new Date(this.year, this.month + 1, 0);
      if (firstDay.getDay() === 0) {
        let eventRunning = 0;
        for (let weekCount = 0; week.length + 1 >= weekCount + 1; weekCount++) {
          calendar[weekCount] = {
            day: [
              { dateNumber: null, event: false, laborCount: null },
              { dateNumber: null, event: false, laborCount: null },
              { dateNumber: null, event: false, laborCount: null },
              { dateNumber: null, event: false, laborCount: null },
              { dateNumber: null, event: false, laborCount: null },
              { dateNumber: null, event: false, laborCount: null },
              { dateNumber: null, event: false, laborCount: null },
            ],
          };
        }
        for (let weekCount = 0; week.length >= weekCount + 1; weekCount++) {
          for (let dayCount = 0; dayCount < 7; dayCount++) {
            if (weekCount == 0) {
              if (dayCount === 0) {
                calendar[weekCount].day[dayCount].event =
                  this.event[eventRunning] !== null ? true : false;
                calendar[weekCount].day[dayCount].dateNumber =
                  week[weekCount].start + dayCount;
                calendar[weekCount].day[dayCount].laborCount =
                  this.event[eventRunning++];
              } else {
                calendar[weekCount + 1].day[dayCount].event =
                  this.event[eventRunning] !== null ? true : false;
                calendar[weekCount + 1].day[dayCount].dateNumber =
                  week[weekCount].start + dayCount;
                calendar[weekCount + 1].day[dayCount].laborCount =
                  this.event[eventRunning++];
              }
            } else {
              if (dayCount === 0) {
                calendar[weekCount].day[dayCount].event =
                  this.event[eventRunning] !== null ? true : false;
                calendar[weekCount].day[dayCount].dateNumber =
                  week[weekCount].start + dayCount;
                calendar[weekCount].day[dayCount].laborCount =
                  this.event[eventRunning++];
              } else {
                if (week[weekCount].start + dayCount > lastDay.getDate()) {
                  calendar[weekCount + 1].day[dayCount].event = false;
                  calendar[weekCount + 1].day[dayCount].laborCount = null;
                } else {
                  calendar[weekCount + 1].day[dayCount].event =
                    this.event[eventRunning] !== null ? true : false;
                  calendar[weekCount + 1].day[dayCount].dateNumber =
                    week[weekCount].start + dayCount;
                  calendar[weekCount + 1].day[dayCount].laborCount =
                    this.event[eventRunning++];
                }
              }
            }
          }
        }
      } else {
        for (let weekCount = 0; week.length >= weekCount + 1; weekCount++) {
          calendar[weekCount] = {
            day: [
              { dateNumber: null, event: false, laborCount: null },
              { dateNumber: null, event: false, laborCount: null },
              { dateNumber: null, event: false, laborCount: null },
              { dateNumber: null, event: false, laborCount: null },
              { dateNumber: null, event: false, laborCount: null },
              { dateNumber: null, event: false, laborCount: null },
              { dateNumber: null, event: false, laborCount: null },
            ],
          };
          let start = 1;
          for (let dayCount = 0; dayCount < 7; dayCount++) {
            if (weekCount == 0) {
              if (start > 1) {
                calendar[weekCount].day[dayCount].dateNumber = start;
                calendar[weekCount].day[dayCount].event =
                  this.event[start] !== null ? true : false;
                calendar[weekCount].day[dayCount].laborCount =
                  this.event[start];
                start++;
              } else if (dayCount == firstDay.getDay()) {
                calendar[weekCount].day[dayCount].dateNumber =
                  week[weekCount].start;
                calendar[weekCount].day[dayCount].event =
                  this.event[start] !== null ? true : false;
                calendar[weekCount].day[dayCount].laborCount = this.event[0];
                start++;
              }
            } else if (week[weekCount].start + dayCount <= lastDay.getDate()) {
              if (dayCount == 0) {
                if (week.length != weekCount) {
                  calendar[weekCount - 1].day[dayCount].event =
                    this.event[week[weekCount].start + dayCount - 1] !== null
                      ? true
                      : false;
                  calendar[weekCount - 1].day[dayCount].dateNumber =
                    week[weekCount].start + dayCount;
                  calendar[weekCount - 1].day[dayCount].laborCount =
                    this.event[week[weekCount].start + dayCount - 1];
                }
              } else {
                calendar[weekCount].day[dayCount].event =
                  this.event[week[weekCount].start + dayCount - 1] !== null
                    ? true
                    : false;
                calendar[weekCount].day[dayCount].dateNumber =
                  week[weekCount].start + dayCount;
                calendar[weekCount].day[dayCount].laborCount =
                  this.event[week[weekCount].start + dayCount - 1];
                if (week[weekCount].start + dayCount <= 31) {
                  calendar[weekCount].day[dayCount].laborCount =
                    this.event[week[weekCount].start + dayCount - 1] !== null
                      ? true
                      : false;
                  calendar[weekCount].day[dayCount].laborCount =
                    this.event[week[weekCount].start + dayCount - 1];
                }
              }
            }
          }
        }
      }
      this.calendar = calendar;
      this.$forceUpdate();
    },
    getWeeksInMonth(_year, _month, returnDate = false) {
      const lastDay = new Date(_year, _month + 1, 0);
      let weeks = [];
      let _days = [];
      for (let _day = 1; _day <= lastDay.getDate(); _day++) {
        let _thisDay = new Date(_year, _month, _day);
        _days.push(returnDate ? _thisDay : _day);
        if (_thisDay.getDay() === 6) {
          weeks.push({ start: _days[0], end: _days[_days.length - 1] });
          _days = [];
        } else if (_thisDay.getDate() === lastDay.getDate()) {
          weeks.push({ start: _days[0], end: _days[_days.length - 1] });
        }
      }
      return weeks;
    },
    getYard() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlYard = resp.data.data;
          if (ddlYard) {
            this.ddlYard = ddlYard.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    search() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("startDate", this.dateStart || "");
      formData.append("endDate", this.dateEnd || "");
      formData.append("yardId", this.yardId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/staff-work/search-by-group-date",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                return {
                  date: helper.formatDate(item.workDate),
                  limitCarsPerStaff: item.LimitCarsPerStaff,
                  staffRequire: item.StaffRequire,
                  staffCurrent: item.StaffCurrent,
                  isStatus: item.StaffCurrent - item.StaffRequire,
                  _date: helper.formatDate(item.workDate),
                  yardId: item.yard.id,
                };
              });
            } else {
              this.items = [];
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data ? resp.data.data.length : 0
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    searchHistoryBack() {
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("startDate", this.dateStart || "");
      formData.append("endDate", this.dateEnd || "");
      formData.append("yardId", this.yardId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/staff-work/search-by-group-date",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                return {
                  date: helper.formatDate(item.workDate),
                  limitCarsPerStaff: item.LimitCarsPerStaff,
                  staffRequire: item.StaffRequire,
                  staffCurrent: item.StaffCurrent,
                  isStatus: item.StaffCurrent - item.StaffRequire,
                  _date: helper.formatDate(item.workDate),
                  yardId: item.yard.id,
                };
              });
            } else {
              this.items = [];
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data ? resp.data.data.length : 0
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getDataCalendar(value = 0) {
      this.$store.state.loading = true;
      const formData = new FormData();
      const lastDate = new Date(this.year, this.month + 1 + value, 0).getDate();
      const startDate = `${this.year}-${(this.month + 1 + value)
        .toString()
        .padStart(2, "0")}-01`;
      const endDate = `${this.year}-${(this.month + 1 + value)
        .toString()
        .padStart(2, "0")}-${lastDate}`;
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      formData.append("yardId", this.yardId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/staff-work/search-by-group-date",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.event = { ...this.eventDefault };
            const entity = resp.data.data;
            if (entity) {
              entity.map((item) => {
                const [year, month, day] = item.workDate.split("-");
                year;
                month;

                this.event[day - 1] = item.StaffCurrent - item.StaffRequire;
              });
              this.getCalendar(value);
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>
