<template>
  <v-container>
    <v-row class="my-1" align="center">
      <v-col
        cols="12"
        sm="auto"
        align="center"
        class="text-left"
        style="color: #203486"
      >
        <b>วันที่ {{ date }}</b>
      </v-col>
      <v-col cols="12" sm="9">
        <v-btn
          class="py-6"
          style="text-transform: none !important"
          color="app-theme"
          rounded
          dark
          @click="dialogAdd = true"
          width="200px"
        >
          <b>เพิ่มกำลังพล</b>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mb-5" align="center">
      <v-col cols="12" sm="12">
        <v-btn
          class="py-6 mr-2"
          style="text-transform: none !important"
          outlined
          disabled
        >
          <b style="color: black">กำลังพลที่ต้องการ {{ required }}</b>
        </v-btn>
        <v-btn
          class="py-6 mr-2"
          style="text-transform: none !important"
          outlined
          disabled
        >
          <b style="color: black">กำลังพลที่มี {{ available }}</b>
        </v-btn>
        <v-btn
          class="py-6 px-6"
          color="app-danger"
          style="text-transform: none !important"
          dark
        >
          <b>ขาด {{ miss }}</b>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="7">
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="items"
          class="elevation-1 border-1"
          :page.sync="page"
          :items-per-page="pageSize"
          @page-count="pageCount = $event"
          :header-props="{
            sortIcon: 'mdi-menu-up',
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            'items-per-page-options': rowsPerPage,
          }"
        >
          <template v-slot:item.fullname="{ item }">
            <div class="item--center--pl-10">
              {{ item.fullname }}
            </div>
          </template>
          <template v-slot:item.position="{ item }">
            <div class="item--center--pl-10">
              {{ item.position }}
            </div>
          </template>
          <template v-slot:item.remark="{ item }">
            <div v-if="item.remark == ''">
              {{ item.remark }}
            </div>
            <div style="color: #fe000d" v-else>{{ item.remark }}</div>
          </template>
          <template v-slot:footer> </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="7">
        <Pagination
          ref="Pagination"
          :page="page"
          :page-size="pageSize"
          @changePage="page = $event"
          @changePageSize="pageSize = $event"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="dialogAdd" persistent max-width="500">
      <v-form @submit.prevent="addItem()" ref="form">
        <v-card class="py-10">
          <v-card-text class="text-center text-body-1">
            <div class="mt-3" style="font-weight: bold; color: black">
              เพิ่มกำลังพล
            </div></v-card-text
          >
          <v-card-text class="text-center text-body-1">
            <div class="mt-3" style="font-weight: bold">
              <v-select
                class="my-2 select--icon--center"
                hide-details="auto"
                color="app-theme"
                placeholder="เลิือกตำแหน่ง"
                v-model="positionId"
                :items="ddlPosition"
                outlined
                :rules="[(v) => !!v || 'กรุณาเลิือกตำแหน่ง']"
              >
                <template v-slot:append>
                  <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                </template>
              </v-select>
              <v-select
                class="my-2 select--icon--center"
                hide-details="auto"
                color="app-theme"
                placeholder="เลือกพนักงาน"
                v-model="staffId"
                :items="ddlStaff"
                outlined
                :rules="[(v) => !!v || 'กรุณาเลือกพนักงาน']"
              >
                <template v-slot:append>
                  <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                </template>
              </v-select>
            </div></v-card-text
          >
          <v-card-actions class="d-flex justify-center">
            <v-btn
              @click="
                dialogAdd = false;
                positionId = null;
                staffId = null;
              "
              rounded
              color="app-disabled-button-theme"
              dark
              class="mr-4 my-2 px-8 py-6"
              >ยกเลิก</v-btn
            >
            <v-btn
              rounded
              color="app-theme"
              dark
              class="my-2 px-8 py-6"
              type="submit"
              >ตกลง</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-row no-gutters class="my-5">
      <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
        <v-btn
          @click="$router.push('/labor/schedule')"
          rounded
          color="app-disabled-button-theme"
          dark
          class="mr-4 my-2 px-8 py-6"
          >ยกเลิก</v-btn
        >
        <v-btn
          rounded
          color="app-theme"
          dark
          class="my-2 px-8 py-6"
          type="submit"
          >บันทึก</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.border-1 >>> td {
  border-left: 1px solid #ddd;
}
.border-1 >>> th {
  border-left: 1px solid #ddd;
}
</style>
<script>
import helper from "@/helpers/helper";
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },

  data: () => ({
    dialogAdd: false,

    helper: helper,
    date: "-",
    required: "-",
    available: "-",
    miss: "-",

    staffId: null,
    positionId: null,

    ddlPosition: [],
    ddlStaff: [],
    page: 1,
    pageCount: 0,
    pageSize: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "ลำดับ",
        align: "center",
        value: "runningNo",
      },
      {
        text: "ชื่อพนักงาน",
        align: "center",
        value: "fullname",
      },
      { text: "ตำแหน่ง", align: "center", value: "position" },
      { text: "หมายเหตุ", align: "center", value: "remark", sortable: false },
    ],
    items: [],
  }),
  watch: {
    page() {
      this.get();
    },
    pageSize() {
      this.get();
    },
    positionId() {
      const staff = this.staff.filter(
        (w) => w.staffPosition.id == this.positionId
      );
      if (staff) {
        const entity = staff.map((item) => {
          return {
            text: `${item.firstname} ${item.lastname}`,
            value: item.id,
          };
        });
        this.ddlStaff = entity;
      }
    },
  },

  mounted() {
    this.$store.state.isBackSchedule = true;
    this.$store.state.title = "แก้ไขกำลังพล";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/labor/schedule";
    if (!this.$route.params.date || !this.$route.params.yardId) {
      this.$router.push("/labor/schedule");
    } else {
      this.getRequireLabor();
      this.get();
      this.date = this.$route.params.date;
    }
    this.getPosition();
    this.getLabor();
  },
  methods: {
    getPosition() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/staff-position/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const entity = resp.data.data;
          if (entity) {
            this.ddlPosition = entity.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getLabor() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/staff/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlStaff = resp.data.data;
          this.staff = ddlStaff;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    addItem() {
      if (!this.$refs.form.validate()) return;
      const formData = new FormData();

      formData.append("date", helper.parseDateYMD(this.$route.params.date));
      formData.append("yardId", this.$route.params.yardId);
      formData.append("staffId", this.staffId);

      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/staff-work/add-more-staff",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.$root.getSystemMessage("เพิ่มกำลังพลเรียบร้อยแล้ว");

            this.get();
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });

      this.positionId = null;
      this.staffId = null;
      this.dialogAdd = false;
    },
    getRequireLabor() {
      const formData = new FormData();
      formData.append(
        "startDate",
        helper.parseDateYMD(this.$route.params.date)
      );
      formData.append("endDate", helper.parseDateYMD(this.$route.params.date));
      formData.append("yardId", this.$route.params.yardId);

      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/staff-work/search-by-group-date",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data.find(
              (w) => w.yard.id == this.$route.params.yardId
            );
            if (entity) {
              const miss = entity.StaffRequire - entity.StaffCurrent;
              this.required = entity.StaffRequire;
              this.available = entity.StaffCurrent;
              this.miss = miss > 0 ? miss : 0;
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append(
        "startDate",
        helper.parseDateYMD(this.$route.params.date) || ""
      );
      formData.append(
        "endDate",
        helper.parseDateYMD(this.$route.params.date) || ""
      );
      formData.append("yardId", this.$route.params.yardId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/staff-work/search-by-date",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            this.items = entity.map((item, idx) => {
              let runningNo = idx + 1;
              if (this.page !== 1) {
                runningNo = (this.page - 1) * this.pageSize + idx + 1;
              }
              let remark = item.remark;
              if (item.remark == "more") {
                remark = "เสริม";
              }
              return {
                id: item.id,
                runningNo: runningNo,
                fullname: `${item.firstname} ${item.lastname}`,
                position: item.staff.staffPosition
                  ? item.staff.staffPosition.name
                  : "-" || "-",
                remark: remark,
              };
            });
          }
          this.$refs.Pagination.getPagination(
            resp.data.count,
            resp.data.data ? resp.data.data.length : 0
          );
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>
