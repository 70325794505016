<template>
  <v-container>
    <v-form @submit.prevent="search()" ref="form">
      <v-row align="center">
        <v-col cols="10" class="text-left">
          <v-row class="my-1">
            <v-col cols="1" class="text-left mt-2">
              <b>ค้นหา</b>
            </v-col>
            <v-col cols="11" class="mt-3">
              <v-row align="center">
                <div class="pl-2 text-right">
                  <span>Yard<req></req></span>
                </div>
                <div style="width: 240px; padding-left: 15px">
                  <v-select label="yard" hide-details="auto" color="app-theme" solo v-model="yardId" :items="ddlYard" :rules="[(v) => !!v || 'กรุณาเลือก Yard']"
                    ><template v-slot:append>
                      <v-icon large>mdi-chevron-down</v-icon>
                    </template></v-select
                  >
                </div>

                <div class="ml-4" style="width: 120px">
                  <span>บริษัทลูกค้า</span>
                </div>
                <div style="width: 240px; padding-left: 15px">
                  <v-select label="บริษัทลูกค้า" hide-details="auto" color="app-theme" solo v-model="companyId" :items="ddlCompany"
                    ><template v-slot:append>
                      <v-icon large>mdi-chevron-down</v-icon>
                    </template></v-select
                  >
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2" class="text-right pr-15">
          <v-btn type="submit" class="py-3 mt-2" rounded outlined color="app-theme" dark style="text-transform: none !important">
            <b> ค้นหา</b>
          </v-btn>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="10" class="text-left">
          <v-row>
            <v-col cols="1" class="text-left"> </v-col>
            <v-col cols="11">
              <v-row align="center">
                <div class="text-right">
                  <span>ยี่ห้อรถ</span>
                </div>
                <div style="width: 240px; padding-left: 15px">
                  <v-select label="ยี่ห้อรถ" hide-details="auto" color="app-theme" solo v-model="brandId" :items="ddlBrand"
                    ><template v-slot:append>
                      <v-icon large>mdi-chevron-down</v-icon>
                    </template></v-select
                  >
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="10" class="text-left">
          <v-row class="my-1">
            <v-col cols="1" class="text-left2"> </v-col>
            <v-col cols="11">
              <v-row align="center">
                <div class="text-center">
                  <span>ช่วงวันที่</span>
                </div>
                <div style="width: 240px; padding-left: 5px; padding-right: 10px">
                  <v-menu v-model="menuStart" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateStartFormatted"
                        persistent-hint
                        append-icon="mdi-calendar-month"
                        v-bind="attrs"
                        v-on="on"
                        solo
                        required
                        hide-details
                        placeholder="เริ่มต้น"
                        ><template v-slot:append> <v-img class="pa-0 ma-0" src="@/assets/icons/ic_calendar.svg" contain width="26"></v-img> </template
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateStart" no-title @change="menuStart = false"></v-date-picker>
                  </v-menu>
                </div>

                <div class="mx-2"><span>-</span></div>
                <div style="width: 240px; padding-left: 15px">
                  <v-menu v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateEndFormatted"
                        persistent-hint
                        append-icon="mdi-calendar-month"
                        v-bind="attrs"
                        v-on="on"
                        solo
                        required
                        hide-details
                        placeholder="สิ้นสุด"
                        ><template v-slot:append> <v-img class="pa-0 ma-0" src="@/assets/icons/ic_calendar.svg" contain width="26"></v-img> </template
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateEnd" no-title @change="menuEnd = false"></v-date-picker>
                  </v-menu>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="my-4" align="end">
        <v-col cols="9" class="text-left"> </v-col>
        <v-col cols="3" class="text-right">
          <v-btn class="py-6" rounded color="app-export-theme" dark style="text-transform: none !important" @click="downloadExcel">
            <b> ส่งออก Excel</b>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="items"
      class="elevation-1 mt-5 custom-scroll"
      :page.sync="page"
      :items-per-page="pageSize"
      @page-count="pageCount = $event"
      :header-props="{
        sortIcon: 'mdi-menu-up',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        'items-per-page-options': rowsPerPage,
      }"
    >
      <template v-slot:item.status="{ item }">
        <span v-if="item.status == 'request'" class="text-red">รอมอบหมาย Driver Leader </span>
        <span v-else-if="item.status == 'wait'" class="text-orange">รอดำเนินการ </span>
        <span v-else-if="item.status == 'doing'" class="text-primary">กำลังดำเนินการ </span>
        <span v-else-if="item.status == 'done'" class="text-green">ดำเนินการเสร็จสิ้น </span>
        <span v-else-if="item.status == 'cancel'" class="text-disable">ยกเลิก </span>
      </template>
      <template v-slot:item.actions="{}"> </template>
      <template v-slot:footer> </template>
    </v-data-table>
    <DialogDelete :dialog.sync="showDialog" :deleteId="deleteId"></DialogDelete>
    <Pagination ref="Pagination" :page="page" :page-size="pageSize" @changePage="page = $event" @changePageSize="pageSize = $event" />
  </v-container>
</template>
<style scoped>
.text-red {
  color: red;
}
.text-orange {
  color: orange;
}
.text-primary {
  color: #203486;
}
.text-green {
  color: #508b21;
}
.text-disable {
  color: #ddd;
}
.custom-scroll >>> ::-webkit-scrollbar {
  height: 20px !important;
  border: 1px solid #aaa !important;
  border-radius: 15px !important;
}
.custom-scroll >>> ::-webkit-scrollbar-thumb {
  background: #283593 !important;
  border-radius: 15px !important;
}
</style>

<script>
import DialogDelete from "@/components/DialogDelete";
import Pagination from "@/components/Pagination";
import helper from "@/helpers/helper";
export default {
  components: {
    DialogDelete,
    Pagination,
  },

  data: () => ({
    helper: helper,

    brandId: "",
    companyId: "",
    yardId: "",

    ddlYard: [],
    ddlBrand: [],
    ddlCompany: [],
    dateStart: "",
    dateStartFormatted: "",
    menuStart: false,
    dateEnd: "",
    dateEndFormatted: "",
    menuEnd: false,

    showDialog: false,
    deleteId: null,

    page: 1,
    pageCount: 0,
    pageSize: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "Job No.",
        align: "center",
        sortable: false,
        value: "jobNo",
      },
      {
        text: "วันที่นำรถเข้า",
        align: "left",
        value: "importDate",
      },
      {
        text: "บริษัทลูกค้า",
        align: "left",
        value: "customerCompany",
      },
      { text: "ยี่ห้อรถ", align: "center", value: "brand" },
      { text: "Model Code", align: "center", value: "modelCode" },
      { text: "MSC Code", align: "center", value: "mscCode" },
      { text: "VINNO", align: "center", value: "vinNo", width: "100px" },
      {
        text: "Driver Leader ที่มอบหมาย",
        align: "center",
        value: "driverLeader",
      },
      {
        text: "Labor ที่ขับ",
        align: "center",
        value: "labor",
      },
      {
        text: "จุดจอด Phase",
        align: "center",
        value: "phase",
      },
      {
        text: "Zone",
        align: "center",
        value: "zone",
      },
      {
        text: "Row",
        align: "center",
        value: "row",
      },
      {
        text: "สถานะ Job",
        align: "center",
        value: "status",
        width: "280px",
      },
    ],
    items: [],
  }),
  mounted() {
    this.$store.state.title = "รายงานการนำรถเข้าคลัง";
    this.$store.state.showBackBtn = false;
    this.getYard();
    this.getBrand();
    this.getCompany();
  },
  watch: {
    dateStart() {
      this.dateStartFormatted = this.helper.formatDate(this.dateStart);
    },
    dateEnd() {
      this.dateEndFormatted = this.helper.formatDate(this.dateEnd);
    },
    page() {
      this.search();
    },
    pageSize() {
      this.search();
    },
  },
  methods: {
    getYard() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlYard = resp.data.data;
          if (ddlYard) {
            this.ddlYard = ddlYard.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getCompany() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/user-company/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlCompany = resp.data.data;
          if (ddlCompany) {
            this.ddlCompany = ddlCompany.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getBrand() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/car-brand/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlBrand = entity.map((item) => {
                return {
                  text: item.name,
                  value: item.id,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    downloadExcel() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("yardId", this.yardId);
      if (!helper.isNullOrEmpty(this.companyId)) formData.append("userCompanyId", this.companyId);

      if (!helper.isNullOrEmpty(this.brandId)) formData.append("carBrandId", this.brandId);

      if (!helper.isNullOrEmpty(this.dateStart)) formData.append("startDate", this.dateStart);
      if (!helper.isNullOrEmpty(this.dateEnd)) formData.append("endDate", this.dateEnd);

      formData.append("download", true);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/report/car-importing/search",
          data: formData,
          responseType: "blob",
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `report_import_car_${this.dateStart}_to_${this.dateEnd}`);
          document.body.appendChild(link);
          link.click();
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    search() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("yardId", this.yardId);
      if (!helper.isNullOrEmpty(this.companyId)) formData.append("userCompanyId", this.companyId);

      if (!helper.isNullOrEmpty(this.brandId)) formData.append("carBrandId", this.brandId);

      if (!helper.isNullOrEmpty(this.dateStart)) formData.append("startDate", this.dateStart);
      if (!helper.isNullOrEmpty(this.dateEnd)) formData.append("endDate", this.dateEnd);

      formData.append("download", false);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/report/car-importing/search",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                let driverLeader = "";
                let labor = "";
                if (item.driver) {
                  driverLeader = `${item.driver.firstname} ${item.driver.lastname}`;
                }
                if (item.staffAssigner) {
                  labor = `${item.staffAssigner.firstname} ${item.staffAssigner.lastname}`;
                }
                let importDate = "";
                if (item.carInAt) {
                  const [date, time] = item.carInAt.split(" ");
                  time;
                  importDate = helper.formatDate(date);
                }
                let jobNo = "";
                let customerCompany = "";
                let brand = "";
                let jobStatus = "";
                if (item.carImporting) {
                  jobNo = item.carImporting.jobNo;
                  jobStatus = item.carImporting.status;
                  if (item.carImporting.userCompany) {
                    customerCompany = item.carImporting.userCompany.name;
                  }
                  if (item.carImporting.carBrand) {
                    brand = item.carImporting.carBrand.name;
                  }
                }
                return {
                  id: item.id,
                  jobNo: jobNo,
                  importDate: importDate,
                  customerCompany: customerCompany,
                  brand: brand,
                  modelCode: item.modelCode,
                  mscCode: item.mscCode,
                  vinNo: item.vinNo,
                  driverLeader: labor,
                  labor: driverLeader,
                  phase: item.yardPhase ? item.yardPhase.name : "",
                  zone: item.yardZone ? item.yardZone.name : "",
                  row: item.yardZoneRow ? item.yardZoneRow.no : "",
                  status: jobStatus,
                };
              });
            } else {
              this.items = [];
            }
            this.$refs.Pagination.getPagination(resp.data.count, resp.data.data ? resp.data.data.length : 0);
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
