<template>
  <v-app class="section-container">
    <v-row>
      <v-col cols="4" class="left">
        <span class="white--text login-text"> เข้าสู่ระบบ </span>
      </v-col>
      <v-col cols="8" class="right">
        <v-form @submit.prevent="submit" ref="form">
          <v-row justify="center">
            <v-col cols="12" align="center" class="mb-5">
              <v-img
                min-width="100"
                max-width="200"
                src="@/assets/ic_logo_login.svg"
              />
            </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                class="pl--input"
                color="app-theme"
                height="48"
                prepend-inner-icon="mdi-account"
                v-model="email"
                label="ชื่อผู้ใช้"
                required
                outlined
                dense
                :rules="userNameRulues"
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="10" class="mb-0 pb-0">
              <v-text-field
                class="pl--input"
                color="app-theme"
                height="48"
                prepend-inner-icon="mdi-lock"
                v-model="password"
                label="รหัสผ่าน"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                :type="showPass ? 'text' : 'password'"
                :rules="passwordRules"
              >
                <template v-slot:append>
                  <v-img
                    v-if="showPass"
                    @click="showPass = false"
                    class="pt-2"
                    src="@/assets/icons/ic_eye_on.svg"
                    contain
                    width="26"
                  ></v-img>
                  <v-img
                    v-else
                    @click="showPass = true"
                    class="pt-2"
                    src="@/assets/icons/ic_eye_off.svg"
                    contain
                    width="26"
                  ></v-img>
                </template> </v-text-field
            ></v-col>
            <v-col cols="12" sm="10" class="text-end mt-0 pt-0 mb-5">
              <router-link
                to="/forgot_password"
                class="grey--text font-weight-bold"
                >ลืมรหัสผ่าน ?</router-link
              >
            </v-col>
          </v-row>

          <div class="text-center">
            <v-btn
              class="px-15 py-6"
              type="submit"
              rounded
              color="app-theme"
              dark
            >
              เข้าสู่ระบบ
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    email: "",
    password: "",
    showPass: false,
    userNameRulues: [(v) => !!v || "กรุณาระบุชื่อผู้ใช้"],
    passwordRules: [
      (v) => !!v || "กรุณาระบุรหัสผ่าน",
      (v) => (v && v.length >= 6) || "รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6",
    ],
  }),
  mounted() {
    if (localStorage.getItem("token")) this.$router.push("/about");
  },
  methods: {
    submit() {
      this.email = this.email.trim();
      if (!this.$refs.form.validate()) return;

      this.$store.state.loading = true;
      this.$store
        .dispatch("loginRootAdmin", {
          username: this.email,
          password: this.password,
        })
        .then(() => {
          this.getProfile();
        })
        .catch((err) => {
          this.$store.state.loading = false;
          if (err === "record not found") {
            err = "ไม่พบผู้ใช้งานนี้ในระบบ";
          }
          this.$root.getErrorSystemMessage(err);
        });
    },

    getProfile() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/profile",
        })
        .then((resp) => {
          const entity = resp.data.data;
          if (entity) {
            if (entity.adminRoles[0].id === 1) {
              this.$store.state.isRoot = true;
              this.$store.state.isAdmin = false;
              this.$store.state.isRootAdminMenu = true;
              localStorage.setItem("root", true);
              localStorage.setItem("admin", false);
            } else {
              this.$store.state.isRoot = false;
              this.$store.state.isAdmin = true;
              this.$store.state.isRootAdminMenu = false;
              localStorage.setItem("root", false);
              localStorage.setItem("admin", true);
            }
            localStorage.setItem(
              "fullName",
              `${entity.firstname} ${entity.lastname}`
            );
            this.$store.state.loading = false;
            this.$router.push("/about");
            this.$store.state.title = "";
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
        });
    },
  },
};
</script>

<style scoped>
.section-container {
  padding: 0;
  margin: 0;
  background: #fff;
  width: 100%;
}
.left {
  padding: 20px;
  padding-bottom: 7%;
  flex: start;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  background: var(--v-app-theme-base);
}
.right {
  padding-bottom: 10%;
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(--v-app-theme-base);
}
.login-text {
  margin: 70px;
  border-bottom: 1px solid #000;
  width: 80%;
  font-size: 48px;
  font-weight: bold;
  border-color: white;
}
</style>
