<template>
  <div class="my-10">
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>ระบุ MSC Code</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="mscCode"
            color="app-theme"
            placeholder="MSC Code"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุ MSC Code']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title">
          <b>ระบุ Model Description</b>
        </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="modelDescription"
            color="app-theme"
            placeholder="Model Description"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุ Model Description']"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>ระบุ Brand</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="select--icon--center"
            hide-details="auto"
            color="app-theme"
            placeholder="กรุณาเลือก Brand"
            v-model="modelBrandId"
            :items="ddlBrand"
            outlined
            rounded
            :rules="[(v) => v !== null || 'กรุณาเลือก Brand']"
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title">
          <b>ระบุ Model Code</b>
        </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="select--icon--center"
            hide-details="auto"
            color="app-theme"
            placeholder="กรุณาเลือก Model Code"
            v-model="modelCodeId"
            :items="ddlModelCode"
            outlined
            rounded
            :rules="[(v) => v !== null || 'กรุณาเลือก Model Code']"
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/vehicle/msc_mode/management/')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAdd: this.$route.name === "VehicleMscMode.Add",
      mscCode: "",
      modelDescription: "",
      modelBrandId: null,
      modelCodeId: null,
      ddlBrand: [
        { text: "Brand", value: 1 },
        { text: "MAZDA", value: 2 },
        { text: "TOYOTA", value: 3 },
      ],
      ddlModelCode: [
        { text: "Model Code", value: 1 },
        { text: "CX 3", value: 2 },
        { text: "M2:4SD", value: 3 },
      ],
    };
  },
  mounted() {
    this.$store.state.title = "เพิ่ม MSC code (model serial code)";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/vehicle/msc_mode/management";
    this.getBrand();
    this.getModel();
    if (this.$route.name === "VehicleMscMode.Edit") {
      this.$store.state.title = "แก้ไข MSC code (model serial code)";
      this.get();
    }
  },
  methods: {
    getBrand() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/car-brand/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlBrand = entity.map((item) => {
                return {
                  text: item.name,
                  value: item.id,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getModel() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/car-model/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlModelCode = entity.map((item) => {
                return {
                  text: item.code,
                  value: item.id,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({ url: "admin/car-model-msc/" + this.$route.params.id })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            this.mscCode = entity.code;
            this.modelDescription = entity.description;

            this.modelBrandId = entity.carBrand ? entity.carBrand.id : null;
            this.modelCodeId = entity.carModel ? entity.carModel.id : null;
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    add() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("code", this.mscCode);
      formData.append("description", this.modelDescription);
      formData.append("carBrandId", this.modelBrandId);
      formData.append("carModelId", this.modelCodeId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/car-model-msc",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/vehicle/msc_mode/management"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    update() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("code", this.mscCode);
      formData.append("description", this.modelDescription);
      formData.append("carBrandId", this.modelBrandId);
      formData.append("carModelId", this.modelCodeId);
      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/car-model-msc",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/vehicle/msc_mode/management"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
