<template>
  <v-container>
    <v-form @submit.prevent="search()" ref="form">
      <v-row class="my-1" align="center">
        <v-col cols="12" sm="1" class="text-center">
          <b class="font--title" style="color: #041e41">เงื่อนไข</b>
        </v-col>
        <v-col cols="12" sm="11" class="pr-15">
          <v-row>
            <v-col cols="12" sm="4" xl="4">
              <v-row align="center">
                <v-col cols="12" sm="4" xl="4"><b>Yard</b></v-col>
                <v-col cols="12" sm="8" xl="8">
                  <v-select
                    class="select--icon--center"
                    hide-details="auto"
                    color="app-theme"
                    placeholder="Yard"
                    v-model="yardId"
                    :items="ddlYard"
                    outlined
                    rounded
                    :rules="[(v) => !!v || 'กรุณาเลือก Yard']"
                    ><template v-slot:append>
                      <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" xl="4">
              <v-row align="center">
                <v-col cols="12" sm="4" xl="3"><b>Phase</b></v-col>
                <v-col cols="12" sm="8" xl="8">
                  <v-select
                    class="select--icon--center"
                    hide-details="auto"
                    color="app-theme"
                    placeholder="Phase"
                    v-model="yardPhaseId"
                    :items="ddlPhase"
                    outlined
                    rounded
                    ><template v-slot:append>
                      <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                    </template>
                  </v-select></v-col
                >
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" xl="4">
              <v-row align="center">
                <v-col cols="12" sm="4" xl="2"> <b>Zone</b></v-col>
                <v-col cols="12" sm="8" xl="8">
                  <v-select
                    class="select--icon--center"
                    hide-details="auto"
                    color="app-theme"
                    placeholder="Zone"
                    v-model="yardZoneId"
                    :items="ddlZone"
                    outlined
                    rounded
                    ><template v-slot:append>
                      <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="my-1">
        <v-col cols="12" sm="1" class="text-center"> </v-col>
        <v-col cols="12" sm="11" class="pr-15">
          <v-row>
            <v-col cols="12" sm="4" xl="4">
              <v-row align="center">
                <v-col cols="12" sm="4" class="ma-0 pa-0"
                  ><b>บริษัทลูกค้า</b></v-col
                >
                <v-col cols="12" sm="8" xl="8" justify="start"
                  ><v-select
                    class="select--icon--center"
                    hide-details="auto"
                    color="app-theme"
                    placeholder="บริษัทลูกค้า"
                    v-model="userCompanyId"
                    :items="ddlCompany"
                    outlined
                    rounded
                    ><template v-slot:append>
                      <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="8">
              <v-row>
                <v-col cols="12" sm="4" xl="3"> </v-col>
                <v-col cols="12" sm="8" xl="8" class="text-right">
                  <v-btn
                    class="pa-6"
                    outlined
                    rounded
                    color="app-theme"
                    dark
                    type="submit"
                    style="text-transform: none !important"
                  >
                    <b> ค้นหา</b>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="my-1">
      <v-col cols="12" sm="12" class="pt-5 text-left">
        <b>ผลการค้นหาจาก Yard</b>
      </v-col>
    </v-row>
    <v-row v-if="items.length > 0">
      <v-col cols="12"> </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="pageSize"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      class="elevation-1"
      hide-default-footer
      expand-icon="mdi-chevron-right"
    >
      <template v-slot:item.actions="{ item }">
        <div width="100px">
          <router-link
            :to="{
              name: 'Yard.Detail',
              params: {
                id: item.id,
                yardId: item.yardId,
                phaseId: item.phaseId,
              },
            }"
            class="text-decoration-none mx-1"
          >
            <v-btn min-width="20" class="btn-p-10" dark color="app-pink-theme"
              ><b><v-icon color="">mdi-magnify</v-icon></b></v-btn
            >
          </router-link>
        </div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td
          v-if="item.yardZones.length > 0"
          :colspan="headers.length"
          class="pa-0 ma-0"
        >
          <div>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr v-for="subItem in item.yardZones" :key="subItem.id">
                    <td class="text-center pa-0" width="34%"></td>
                    <td class="text-center pa-0" width="10%">
                      {{ subItem.zone }}
                    </td>
                    <td class="text-center px-0" width="10%">
                      {{ subItem.company }}
                    </td>
                    <td class="text-center px-0" width="10%">
                      {{ subItem.row }}
                    </td>
                    <td class="text-center px-0" width="13%">
                      {{ subItem.slot }}
                    </td>
                    <td class="text-center px-0" width="13%">
                      {{ subItem.unavailable }}
                    </td>
                    <td class="text-center" width="10%">
                      <router-link
                        :to="{
                          name: 'Yard.SubDetail',
                          params: {
                            id: subItem.id,
                            yardId: item.yardId,
                            phaseId: item.phaseId,
                            zoneId: subItem.id,
                          },
                        }"
                        class="text-decoration-none mx-1"
                      >
                        <v-btn
                          min-width="20"
                          class="btn-p-10"
                          dark
                          color="app-pink-theme"
                          ><b><v-icon color="">mdi-magnify</v-icon></b></v-btn
                        >
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </td>

        <div v-else></div>
      </template>
    </v-data-table>

    <DialogDelete :dialog.sync="showDialog" :deleteId="deleteId"></DialogDelete>
    <Pagination
      ref="Pagination"
      :page="page"
      :page-size="pageSize"
      @changePage="page = $event"
      @changePageSize="pageSize = $event"
    />
  </v-container>
</template>
<style scoped>
.v-data-table >>> tr > td > button {
  color: #08318a;
  justify-content: right;
  align-content: right;
}
.v-data-table >>> tr:nth-child(n) > td.text-start:nth-child(1) {
  padding: 0 !important;
  text-align: right !important;
}
</style>
<script>
import DialogDelete from "@/components/DialogDelete";
import Pagination from "@/components/Pagination";
export default {
  components: {
    DialogDelete,
    Pagination,
  },

  data: () => ({
    expanded: [],
    singleExpand: false,

    yardId: "",
    yardPhaseId: "",
    yardZoneId: "",
    userCompanyId: "",

    ddlYard: [],
    ddlPhase: [],
    ddlZone: [],
    ddlCompany: [],

    phase: [],
    zone: [],
    company: [],

    showDialog: false,
    deleteId: null,
    page: 1,
    pageCount: 0,
    pageSize: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "Yard",
        sortable: false,
        align: "center",
        value: "yard",
        width: "15%",
      },
      {
        text: "Phase",
        sortable: false,
        align: "center",
        value: "phase",
        width: "15%",
      },
      {
        text: "Zone",
        sortable: false,
        align: "center",
        value: "zone",
        width: "10%",
      },
      {
        text: "ลูกค้า",
        sortable: false,
        align: "center",
        value: "company",
        width: "10%",
      },
      {
        text: "Row",
        sortable: false,
        align: "center",
        value: "row",
        width: "10%",
      },
      {
        text: "ช่องจอด",
        sortable: false,
        align: "center",
        value: "slot",
        width: "13%",
      },
      {
        text: "จอดแล้ว",
        sortable: false,
        align: "center",
        value: "unavailable",
        width: "13%",
      },
      {
        text: "",
        sortable: false,
        align: "center",
        value: "actions",
        width: "10%",
      },
    ],

    items: [],
  }),
  mounted() {
    this.$store.state.title = "ดูข้อมูล Yard";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/yard/management";
    this.getYard();
    this.getPhase();
    this.getZone();
    this.getCompany();
  },
  watch: {
    yardId() {
      const phase = this.phase.filter((w) => w.yard.id == this.yardId);
      if (phase) {
        this.ddlPhase = [{ text: "ทั้งหมด", value: "" }];
        const entity = phase.map((item) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
        this.ddlPhase = this.ddlPhase.concat(entity);
      }
      this.ddlZone = [{ text: "ทั้งหมด", value: "" }];
    },
    yardPhaseId() {
      const zone = this.zone.filter((w) => w.yardPhase.id == this.yardPhaseId);
      if (zone) {
        this.ddlZone = [{ text: "ทั้งหมด", value: "" }];
        const entity = zone.map((item) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
        this.ddlZone = this.ddlZone.concat(entity);
      }
    },
    page() {
      this.search();
    },
    pageSize() {
      this.search();
    },
  },
  methods: {
    getYard() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const entity = resp.data.data;
          if (entity) {
            this.ddlYard = entity.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getPhase() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-phase/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlPhase = resp.data.data;
          this.phase = ddlPhase;
          if (ddlPhase) {
            this.ddlPhase = [{ text: "ทั้งหมด", value: "" }];
            const entity = ddlPhase.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
            this.ddlPhase = this.ddlPhase.concat(entity);
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getZone() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-zone/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlZone = resp.data.data;
          this.zone = ddlZone;
          if (ddlZone) {
            this.ddlZone = [{ text: "ทั้งหมด", value: "" }];
            const entity = ddlZone.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
            this.ddlZone = this.ddlZone.concat(entity);
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getCompany() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/user-company/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlCompany = resp.data.data;
          if (ddlCompany) {
            this.ddlCompany = [{ text: "ทั้งหมด", value: "" }];
            const entity = ddlCompany.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
            this.ddlCompany = this.ddlCompany.concat(entity);
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    search() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("yardId", this.yardId);
      formData.append("yardPhaseId", this.yardPhaseId);
      formData.append("yardZoneId", this.yardZoneId);
      formData.append("userCompanyId", this.userCompanyId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/yard/search",
          data: formData,
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                let zone = "-";
                let company = "-";
                let row = 0;
                let slot = 0;
                let unavailable = 0;
                let yardZones = [];
                if (item.yardZones) {
                  zone = item.yardZones.length;
                  company = item.yardZones[0].userCompany.name;
                  item.yardZones.map((yz) => {
                    row = row + yz.rowNumbers;
                    if (yz.yardZoneRows) {
                      yz.yardZoneRows.map((item) => {
                        slot = slot + item.qty;
                        unavailable = unavailable + item.qtyIn;
                      });
                    }
                  });
                  yardZones = item.yardZones.map((item) => {
                    let qty = 0;
                    let qtyIn = 0;
                    if (item.yardZoneRows) {
                      item.yardZoneRows.map((yzr) => {
                        qty = qty + yzr.qty;
                        qtyIn = qtyIn + yzr.qtyIn;
                      });
                    }
                    return {
                      id: item.id,
                      zone: item.name,
                      company: item.userCompany.name,
                      row: item.rowNumbers,
                      slot: qty,
                      unavailable: qtyIn,
                    };
                  });
                }
                return {
                  id: item.id,
                  yard: item.yard.name,
                  phase: item.name,
                  zone: zone,
                  company: company,
                  row: row,
                  slot: slot,
                  unavailable: unavailable,
                  yardZones: yardZones,
                  yardId: item.yard.id,
                  phaseId: item.id,
                };
              });
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data.length
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>
