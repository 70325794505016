<template>
  <div class="my-10">
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl-10"> บริษัทลูกค้า </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select2 select--icon--center"
            hide-details="auto"
            color="app-theme"
            v-model="companyId"
            :items="ddlCompany"
            outlined
            :rules="[(v) => !!v || 'กรุณาเลือกบริษัทลูกค้า']"
            ><template v-slot:append>
              <v-icon color="app-theme" large>mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl-10"> เลือกยี่ห้อรถ </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select2 select--icon--center"
            hide-details="auto"
            color="app-theme"
            v-model="brandId"
            :items="ddlBrand"
            outlined
            :rules="[(v) => !!v || 'กรุณาเลือกยี่ห้อรถ']"
            ><template v-slot:append>
              <v-icon color="app-theme" large>mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl-10"> Yard </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select2 select--icon--center"
            hide-details="auto"
            color="app-theme"
            v-model="yardId"
            :items="ddlYard"
            outlined
            :rules="[(v) => !!v || 'กรุณาเลือกYard']"
            ><template v-slot:append>
              <v-icon color="app-theme" large>mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl-10"> วันที่นำรถออก </v-col>
        <v-col cols="12" sm="8">
          <v-menu
            v-model="menuStart"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="pl--input input--icon--center"
                :disabled="!isAdd"
                v-model="dateStartFormatted"
                persistent-hint
                append-icon="mdi-calendar-month"
                v-bind="attrs"
                v-on="on"
                outlined
                required
                hide-details="auto"
                :rules="[(v) => !!v || 'ระบุแพลนวันที่นำรถออก']"
                ><template v-slot:append>
                  <v-img
                    class="mr-2"
                    src="@/assets/icons/ic_calendar_blue.svg"
                    contain
                    width="22"
                  ></v-img> </template
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateStart"
              no-title
              @change="menuStart = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl-10"> เวลาที่นำรถออก </v-col>
        <v-col cols="12" sm="8">
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="!isAdd"
                v-model="planTime"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                class="pl--input input--icon--center"
                append-icon="mdi-clock-time-four-outline"
                hide-details="auto"
                color="app-theme"
                :rules="[(v) => !!v || 'ระบุแพลนเวลานำรถออก']"
                ><template v-slot:append>
                  <v-icon large color="app-theme"
                    >mdi-clock-time-four-outline</v-icon
                  >
                </template>
              </v-text-field>
            </template>
            <v-time-picker
              v-if="menu2"
              v-model="planTime"
              format="24hr"
              landscape
              @click:minute="$refs.menu.save(planTime)"
            ></v-time-picker>
          </v-menu>

          <!-- <v-text-field
            v-if="$route.name === 'ExportVehicle.Edit'"
            class="pl--input input--icon--center"
            :disabled="!isAdd"
            append-icon="mdi-clock-time-four-outline"
            hide-details="auto"
            color="app-theme"
            v-model="planTime"
            outlined
            ><template v-slot:append>
              <v-icon large color="app-theme"
                >mdi-clock-time-four-outline</v-icon
              >
            </template>
          </v-text-field>
          <v-text-field
            v-else
            class="pl--input input--icon--center"
            append-icon="mdi-clock-time-four-outline"
            hide-details="auto"
            color="app-theme"
            v-model="planTime"
            outlined
            v-mask="timeMask"
            :rules="[(v) => !!v || 'ระบุแพลนเวลานำรถออก']"
            ><template v-slot:append>
              <v-icon large color="app-theme"
                >mdi-clock-time-four-outline</v-icon
              >
            </template>
          </v-text-field> -->
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl-10">นำเข้าไฟล์ SI</v-col>
        <v-col cols="10" sm="6" v-if="isAdd">
          <v-file-input
            class="placeholder--black"
            ref="upload"
            name="upload"
            color="app-theme"
            hide-details="auto"
            outlined
            :placeholder="isAdd ? 'เลือกไฟล์' : ''"
            persistent-hint
            prepend-icon=""
            @change="onFileChange"
            :rules="[(v) => !!v || 'กรุณานำเข้าไฟล์ชนิด xlxs']"
          ></v-file-input>
        </v-col>
        <v-col cols="10" sm="6" v-if="!isAdd">
          <div
            class="v-input pl--input v-input--hide-details v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder"
          >
            <div class="v-input__control">
              <div class="v-input__slot">
                <fieldset aria-hidden="true">
                  <legend style="width: 0px">
                    <span class="notranslate"></span>
                  </legend>
                </fieldset>
                <div class="v-text-field__slot">
                  <a style="text-decoration: underline" @click="downloadExcel">
                    Download file SI
                  </a>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="10" sm="2" v-if="isAdd">
          <v-btn
            @click="$refs.upload.$refs.input.click()"
            class="py-6"
            block
            rounded
            color="green"
            dark
            style="text-transform: none !important"
          >
            <b> Browse</b>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" class="mb-10">
        <v-col cols="12" sm="3" class="pl-5 ma-0 pa-0"></v-col>
        <v-col cols="10" sm="6" class="py-0 my-0">
          <div style="font-size: 16px; color: grey">ชนิดไฟล์ .xlsx</div>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-5" v-if="isAdd">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/export_vehicle/')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>

      <v-row align="center" class="my-10" v-if="!isSaveFile">
        <v-col cols="12" sm="12" class="pl-5 ma-0 pa-0 mt-10">
          <b>รายการนำเข้าไฟล์ SI เรียบร้อยแล้ว</b>
        </v-col>
      </v-row>

      <v-data-table
        v-if="!isSaveFile"
        hide-default-footer
        hide-default-header
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        class="elevation-1"
        :header-props="{
          sortIcon: 'mdi-menu-up',
        }"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          'items-per-page-options': rowsPerPage,
        }"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th class="text-center header-theme" :colspan="9">
                ข้อมูล SR /SI / VIN
              </th>
              <th class="text-center remark-header-theme">รับข้อมูล</th>
              <th class="text-center addr-header-theme" :colspan="12">
                ข้อมูลการจัดส่ง
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th class="text-center header-theme"></th>
              <th class="text-center header-theme">SRDATE</th>
              <th class="text-center header-theme">SIDATE</th>
              <th class="text-center header-theme">Sino</th>
              <th class="text-center header-theme">VINNO</th>
              <th class="text-center header-theme">BodyType</th>
              <th class="text-center header-theme">Trailer</th>
              <th class="text-center header-theme">ColorCode</th>
              <th class="text-center header-theme">MSC</th>

              <th class="text-center remark-header-theme">Remark</th>

              <th class="text-center addr-header-theme">Zone</th>
              <th class="text-center addr-header-theme">DealerCode</th>
              <th class="text-center addr-header-theme">DealerName</th>
              <th class="text-center addr-header-theme">Province</th>
              <th class="text-center addr-header-theme">TripNo</th>
              <th class="text-center addr-header-theme">Status</th>
              <th class="text-center addr-header-theme">Trailer</th>
              <th class="text-center addr-header-theme">Yard</th>
              <th class="text-center addr-header-theme">Phase</th>
              <th class="text-center addr-header-theme">Zone</th>
              <th class="text-center addr-header-theme">RowNo</th>
              <!-- <th class="text-center addr-header-theme">สถานะการขาย</th> -->
            </tr>
          </thead>
        </template>
        <template v-slot:item.remark="{ item }">
          <div style="width: 200px">
            {{ item.remark }}
          </div>
        </template>
        <template v-slot:item.dealerName="{ item }">
          <div style="width: 250px">
            {{ item.dealerName }}
          </div>
        </template>
        <template v-slot:footer> </template>
      </v-data-table>
    </v-form>
    <v-dialog v-model="dialogDate" persistent max-width="500">
      <v-card class="py-10">
        <v-card-text class="text-center text-body-1">
          <div class="mt-3">
            <v-icon size="100" color="app-disabled-button-theme"
              >mdi-alert-circle</v-icon
            >
          </div></v-card-text
        >
        <v-card-text class="text-center text-body-1">
          <div class="mt-1">
            <span style="color: #000">ไม่สามารถเลือกวันที่ล่วงหน้าได้</span>
          </div></v-card-text
        >

        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="app-theme"
            dark
            rounded
            class="px-10 py-6 btn--action"
            @click="dialogDate = false"
            >ตกลง</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.disabled {
  background-color: #dfdfdf;
}
.v-data-table >>> ::-webkit-scrollbar {
  height: 20px !important;
  border: 1px solid #aaa !important;
  border-radius: 15px !important;
}
.v-data-table >>> ::-webkit-scrollbar-thumb {
  background: #283593 !important;
  border-radius: 15px !important;
}
.v-data-table >>> th {
  border-left: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
}
.v-data-table >>> td {
  border-left: 1px solid #dfdfdf;
}
.header-theme {
  color: white;
  background-color: #203486;
  border-left: 1px solid #dfdfdf !important;
  border-bottom: 1px solid #dfdfdf !important;
}
.remark-header-theme {
  color: white;
  background-color: #4b28fb !important;
  border-left: 1px solid #dfdfdf !important;
  border-bottom: 1px solid #dfdfdf !important;
}
.addr-header-theme {
  color: white;
  background-color: #ff6c00 !important;
  border-left: 1px solid #dfdfdf !important;
  border-bottom: 1px solid #dfdfdf !important;
}

.placeholder--black >>> .v-file-input__text--placeholder {
  color: black;
}
</style>
<script>
import helper from "@/helpers/helper";
import axios from "axios";
export default {
  data() {
    return {
      time: null,
      menu2: false,
      modal2: false,

      fileName: "",
      dialogDate: false,
      isSaveFile: true,
      helper: helper,
      isAdd: this.$route.name === "ExportVehicle.Add",

      companyId: null,
      brandId: null,
      yardId: null,
      planTime: "",

      dateStart: "",
      dateStartFormatted: "",
      menuStart: false,

      ddlCompany: [],
      ddlBrand: [],
      ddlYard: [],

      page: 1,
      pageCount: 0,
      pageSize: 8,
      rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
      headers: [
        {
          text: "",
          align: "center",
          sortable: false,
          value: "no",
        },
        {
          text: "SRDATE",
          align: "center",
          sortable: false,
          value: "srDate",
        },
        {
          text: "SIDATE",
          align: "center",
          sortable: false,
          value: "siDate",
        },
        {
          text: "Sino",
          align: "center",
          sortable: false,
          value: "sino",
        },
        {
          text: "VINNO",
          align: "center",
          sortable: false,
          value: "vinNo",
        },
        {
          text: "BodyType",
          align: "center",
          sortable: false,
          value: "bodyType",
        },
        {
          text: "Trailer",
          align: "center",
          sortable: false,
          value: "trailerDetail",
        },
        {
          text: "ColorCode",
          align: "center",
          sortable: false,
          value: "colorCode",
        },
        {
          text: "MSC",
          align: "center",
          sortable: false,
          value: "msc",
          width: "120px",
        },
        {
          text: "Remark",
          align: "center",
          sortable: false,
          value: "remark",
        },
        {
          text: "Zone",
          align: "center",
          sortable: false,
          value: "zone",
        },
        {
          text: "DealerCode",
          align: "center",
          sortable: false,
          value: "dealerCode",
        },
        {
          text: "DealerName",
          align: "center",
          sortable: false,
          value: "dealerName",
        },
        {
          text: "Province",
          align: "center",
          sortable: false,
          value: "province",
        },
        {
          text: "TripNo",
          align: "center",
          sortable: false,
          value: "tripNo",
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Trailer",
          align: "center",
          sortable: false,
          value: "trailerSale",
        },
        {
          text: "Yard",
          align: "center",
          sortable: false,
          value: "parkYardName",
        },
        {
          text: "Phase",
          align: "center",
          sortable: false,
          value: "parkYardPhaseName",
        },
        {
          text: "Zone",
          align: "center",
          sortable: false,
          value: "parkYardZoneName",
        },
        {
          text: "RowNo",
          align: "center",
          sortable: false,
          value: "parkYardZoneRowNo",
        },
        // {
        //   text: "สถานะการขาย",
        //   align: "center",
        //   sortable: false,
        //   value: "statusSale",
        // },
      ],
      items: [],
    };
  },
  mounted() {
    const now = new Date();
    const current =
      now.getHours().toString().padStart(2, "0") +
      ":" +
      now.getMinutes().toString().padStart(2, "0");
    this.planTime = current;

    this.$store.state.title = "เพิ่มการส่งออกรถ (ขาย)";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/export_vehicle";
    this.getYard();
    this.getCompany();
    this.getBrand();

    if (this.$route.name === "ExportVehicle.Edit") {
      this.$store.state.title = "รายละเอียด";
      this.isSaveFile = false;
      this.get(this.$route.params.id);
    }
  },
  watch: {
    dateStart() {
      const y = new Date().getFullYear();
      const m = new Date().getMonth() + 1;
      const d = new Date().getDate();

      const [year, month, day] = this.dateStart.split("-");
      let flag = false;
      if (Number(year) > y) {
        flag = true;
      } else if (Number(year) === y && Number(month) > m) {
        flag = true;
      } else if (Number(year) === y && Number(month) === m && Number(day) > d) {
        flag = true;
      }
      if (flag) {
        this.dialogDate = true;
        this.dateStartFormatted = "";
        this.dateStart = "";
      } else {
        this.dateStartFormatted = this.helper.formatDate(this.dateStart);
      }
    },
  },
  methods: {
    downloadExcel() {
      this.$store.state.loading = true;
      axios.defaults.headers.common = {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}` || "",
      };
      // this.$root
      //   .appApi({
      //     url: "admin/car-importing/download/" + this.$route.params.id,
      //     responseType: "blob",
      //   })
      //   .then((response) => {
      //     const url = URL.createObjectURL(
      //       new Blob([response.data], {
      //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //       })
      //     );
      //     const link = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", `Car-In`);
      //     document.body.appendChild(link);
      //     link.click();
      //     this.$store.state.loading = false;
      //   })
      //   .catch((err) => {
      //     this.$root.getErrorSystemMessage(err);
      //     this.$store.state.loading = false;
      //   });
      axios({
        url:
          process.env.VUE_APP_API_URL_CAR_EXPORT_DOWNLOAD +
          this.$route.params.id,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `SI`);
          document.body.appendChild(link);
          link.click();
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getYard() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlYard = resp.data.data;
          if (ddlYard) {
            this.ddlYard = ddlYard.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getCompany() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/user-company/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlCompany = entity.map((item) => {
                return {
                  value: item.id,
                  text: item.name,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getBrand() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/car-brand/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlBrand = entity.map((item) => {
                return {
                  text: item.name,
                  value: item.id,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    parseFullDateTime(value) {
      if (!value) return null;
      const [date, time] = value.split(" ");
      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year} ${time} น.`;
    },
    get(id) {
      this.$root
        .appApi({
          url: "admin/car-exporting/" + id,
        })
        .then((resp) => {
          if (resp.data.data) {
            const entity = resp.data.data;
            this.fileName = entity.fileName;
            this.companyId = entity.userCompany.id;
            this.brandId = entity.carBrand.id;
            this.yardId = entity.yard.id;
            const [exDate, exTime] = entity.exportAt.split(" ");
            this.dateStart = exDate;
            this.planTime = exTime + " น.";
            if (entity.carDetailExportings) {
              this.items = entity.carDetailExportings.map((item, idx) => {
                const srDate = item.srDate.split(" ");
                const siDate = item.siDate.split(" ");
                return {
                  no: idx + 1,
                  srDate: srDate[0],
                  siDate: siDate[0],
                  sino: item.siNo,
                  vinNo: item.vinNo,
                  bodyType: item.bodyType,
                  trailerDetail: item.bodyDetail,
                  colorCode: item.colorCode,
                  msc: item.mscCode,
                  remark: item.remark,
                  zone: item.zone,
                  dealerCode: item.dealerCode,
                  dealerName: item.dealerName,
                  province: item.province,
                  tripNo: item.tripNo,
                  status: item.exportStatus,
                  trailerSale: item.trailer,
                  statusSale: item.engine,

                  parkYardName: item.parkYardName,
                  parkYardPhaseName: item.parkYardPhaseName,
                  parkYardZoneName: item.parkYardZoneName,
                  parkYardZoneRowNo: item.parkYardZoneRowNo,
                };
              });
            }
          } else {
            this.items = [];
          }
          this.showBtn = false;
          this.showTable = true;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    add() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("userCompanyId", this.companyId);
      formData.append("carBrandId", this.brandId);
      formData.append("yardId", this.yardId);
      formData.append("file", this.file);

      const importDatetime = `${this.dateStart} ${this.planTime}`;
      formData.append("exportAt", importDatetime);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/car-exporting",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(resp.data.message);
          }
          if (resp.data.data) {
            this.isSaveFile = false;
            const entity = resp.data.data;
            if (entity) this.get(entity.id);
          } else {
            this.items = [];
          }
          this.showBtn = false;
          this.showTable = true;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    onFileChange(file) {
      if (file) {
        if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          this.file = file;
          this.showBtn = true;
        } else {
          this.file = null;
          this.showBtn = false;
          this.$refs.upload.reset();
          this.$root.getErrorSystemMessage("กรุณาเลือกไฟล์ชนิด .xlsx เท่านั้น");
        }
      } else {
        this.file = null;
        this.showBtn = false;
      }
    },
    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ":", ...minutes, " น."] : hours;
    },
  },
};
</script>

<style></style>
