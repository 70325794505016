var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"my-1"},[_c('v-col',{staticClass:"pt-5 text-center",attrs:{"cols":"12","sm":"2"}},[_c('b',[_vm._v(_vm._s(_vm.yard))])]),_c('v-col',{staticClass:"pt-5 text-center",attrs:{"cols":"12","sm":"2"}},[_c('b',[_vm._v(_vm._s(_vm.phase))])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pageSize,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id","show-expand":"","hide-default-footer":"","expand-icon":"mdi-chevron-right","fixed-header":"","height":"600px"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){return undefined}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0 ma-0",attrs:{"colspan":headers.length}},[_c('div',[_c('v-data-table',{staticClass:"elevation-2 subtable",attrs:{"hide-default-header":"","headers":_vm.subHeaders,"items":item.yardZones,"single-expand":_vm.subSingleExpand,"expanded":_vm.subExpanded,"item-key":"id","show-expand":"","hide-default-footer":"","expand-icon":"mdi-chevron-right","items-per-page":9999},on:{"update:expanded":function($event){_vm.subExpanded=$event}},scopedSlots:_vm._u([{key:"item.vinNo",fn:function(ref){return undefined}},{key:"item.actions",fn:function(ref){return [_c('div',{staticStyle:{"width":"5vw"}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.vinNo.length > 0)?_c('td',{staticClass:"pa-0 ma-0",attrs:{"colspan":headers.length}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',{staticClass:"pa-0 ma-0"},_vm._l((item.vinNo),function(subItem,idx){return _c('tr',{key:idx},[_c('td',{staticClass:"text-right",attrs:{"colspan":headers.length,"width":"100%"}},[_vm._v(" "+_vm._s(subItem.vinNo)+" "),_c('router-link',{staticClass:"text-decoration-none mx-1 ml-5",attrs:{"to":{
                              name: 'Stock.VinNoDetail',
                              params: {
                                id: subItem.id,
                                vinNo: subItem.vinNo,
                              },
                            }}},[_c('v-btn',{staticClass:"btn-p-10",attrs:{"min-width":"20","dark":"","color":"app-pink-theme"}},[_c('b',[_c('v-icon',{attrs:{"color":""}},[_vm._v("mdi-magnify")])],1)])],1)],1)])}),0)]},proxy:true}],null,true)})],1):_c('td',{staticClass:"text-center no--data--text",attrs:{"colspan":headers.length}},[_vm._v(" No data available ")])]}}],null,true)})],1)])]}}])}),_c('Pagination',{ref:"Pagination",attrs:{"page":_vm.$store.state.tempStockPage,"page-size":_vm.pageSize},on:{"changePage":function($event){_vm.page = $event},"changePageSize":function($event){_vm.pageSize = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }