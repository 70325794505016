<template>
  <v-app class="section-container">
    <v-row>
      <v-col cols="4" class="left">
        <span class="white--text login-text"> เปลี่ยนรหัสผ่าน </span>
      </v-col>
      <v-col cols="8" class="right">
        <v-form @submit.prevent="submit" ref="form">
          <v-row>
            <v-col cols="12" align="center">
              <v-img
                min-width="100"
                max-width="200"
                src="@/assets/ic_logo_login.svg"
              />
            </v-col>
            <v-col cols="12" class="mb-0 pb-0 mt-5 pt-10">
              <v-row class="justify-center">
                <v-col
                  >ระบุรหัสผ่านใหม่
                  <v-text-field
                    v-model="newPassword"
                    color="app-theme"
                    placeholder="ระบุรหัสผ่านใหม่"
                    outlined
                    hide-details="auto"
                    :rules="[
                      (v) => !!v || 'กรุณาระบุรหัสผ่าน',
                      (v) =>
                        (v && v.length >= 6) ||
                        'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6',
                      ,
                      (v) =>
                        v === (confirmNewPassword || '') ||
                        'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน',
                    ]"
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mb-0 pb-0 mt-5 pt-10">
              <v-row class="justify-center">
                <v-col cols="ค"
                  >ยืนยันระบุรหัสผ่านใหม่
                  <v-text-field
                    v-model="confirmNewPassword"
                    color="app-theme"
                    placeholder="ระบุยืนยันรหัสผ่านใหม่"
                    outlined
                    hide-details="auto"
                    :rules="[
                      (v) => !!v || 'กรุณาระบุยืนยันรหัสผ่าน',
                      (v) =>
                        (v && v.length >= 6) ||
                        'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6',
                      ,
                      (v) =>
                        v === (newPassword || '') ||
                        'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน',
                    ]"
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="my-5">
              <v-row class="justify-center">
                <v-btn
                  class="px-15 py-6 mt-10"
                  type="submit"
                  rounded
                  color="app-theme"
                  dark
                >
                  ตกลง
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    newPassword: "",
    confirmNewPassword: "",
    token: "",
  }),
  watch: {
    newPassword() {
      if (!this.$refs.form.validate()) return;
    },
    confirmNewPassword() {
      if (!this.$refs.form.validate()) return;
    },
  },
  mounted() {
    this.token = this.$route.query.token;
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) return;

      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("password", this.newPassword);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/forgot-password/token",
          data: formData,
        })
        .then(() => {
          this.$root.getSystemMessage("ทำการเปลี่ยนรหัสผ่านใหม่เรียบร้อยแล้ว");
          this.$router.push("/login");
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.section-container {
  padding: 0;
  margin: 0;
  background: #fff;
  width: 100%;
}
.left {
  padding: 20px;
  padding-bottom: 7%;
  flex: start;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  background: var(--v-app-theme-base);
}
.right {
  padding-bottom: 10%;
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(--v-app-theme-base);
}
.login-text {
  margin: 70px;
  border-bottom: 1px solid #000;
  width: 80%;
  font-size: 48px;
  font-weight: bold;
  border-color: white;
}
.text-black {
  color: black;
}
</style>
