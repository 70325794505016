<template>
  <div class="my-10">
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>User ID</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            v-model="userId"
            class="pl--input--disabled"
            rounded
            hide-details="false"
            :disabled="true"
            required
            background-color="app-disabled-theme"
            height="48"
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>ชื่อ</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="firstname"
            color="app-theme"
            placeholder="ชื่อ"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุชื่อ',
              (v) =>
                /^[A-Za-zก-๏]+$/.test(v.trim()) ||
                'ใส่ได้เฉพาะอักษรภาษาไทยและภาษาอังกฤษ เท่านั้น',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>นามสกุล</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="lastname"
            color="app-theme"
            placeholder="นามสกุล"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุนามสกุล',
              (v) =>
                /^[A-Za-zก-๏]+$/.test(v.trim()) ||
                'ใส่ได้เฉพาะอักษรภาษาไทยและภาษาอังกฤษ เท่านั้น',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>อีเมล</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="email"
            color="app-theme"
            placeholder="อีเมล"
            outlined
            hide-details="auto"
            :rules="[(v) => /.+@.+/.test(v) || 'กรุณาระบุอีเมล']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>เบอร์โทรศัพท์</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="tel"
            color="app-theme"
            placeholder="เบอร์โทรศัพท์"
            outlined
            hide-details="auto"
            v-mask="telMask"
            :rules="[
              (v) =>
                v.length == 12 || 'กรุณาระบุเบอร์โทรศัพท์ให้ครบทั้ง 10 ตัว',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>ตำแหน่ง</b> </v-col>
        <v-col cols="12" sm="8">
          <v-text-field
            v-model="role"
            class="pl--input--disabled"
            rounded
            hide-details="false"
            :disabled="true"
            required
            background-color="app-disabled-theme"
            height="48"
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center" v-if="isAdd">
        <v-col cols="12" sm="3" class="pl--title"><b>ชื่อผู้ใช้</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="username"
            color="app-theme"
            placeholder="ชื่อผู้ใช้"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุชื่อผู้ใช้']"
            autocomplete="off"
            :disabled="!isAdd"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" v-if="!isAdd">
        <v-col cols="12" sm="3" class="pl--title"><b>ชื่อผู้ใช้</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input--disabled"
            v-model="username"
            rounded
            hide-details="false"
            :disabled="true"
            required
            background-color="app-disabled-theme"
            height="48"
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" v-if="isAdd">
        <v-col cols="12" sm="3" class="pl--title"><b>รหัสผ่าน</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="password"
            color="app-theme"
            placeholder="รหัสผ่าน"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุรหัสผ่าน',
              (v) => (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6',
              (v) =>
                /[A-Za-z]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
              (v) =>
                /[0-9]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
            ]"
            type="password"
            autocomplete="new-password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-5 mt-10">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/executive/management/')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!isAdd">
        <v-col cols="12" sm="3" class="mx-auto d-flex justify-center">
          <router-link
            :to="{ name: 'Executive.ChangePassword', params: { id: 1 } }"
            class="text-decoration-none mx-4"
          >
            <v-btn rounded dark color="app-danger" class="px-title py-6"
              ><b>เปลี่ยนรหัสผ่าน</b></v-btn
            >
          </router-link>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import helper from "@/helpers/helper";
export default {
  data() {
    return {
      isAdd: this.$route.name === "Executive.Add",
      telMask: "###-###-####",

      userId: `xxxxx`,
      firstname: ``,
      lastname: ``,
      email: ``,
      tel: ``,
      username: ``,
      password: ``,
      role: `Executive`,
      yardId: ``,
    };
  },
  mounted() {
    this.$store.state.title = "เพิ่ม Executive";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/executive/management";
    if (this.$route.name === "Executive.Edit") {
      this.$store.state.title = "แก้ไข Executive";
      this.get();
    }
  },
  methods: {
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/user-ex/" + this.$route.params.id,
        })
        .then((resp) => {
          const entity = resp.data.data;
          let tel = entity.tel;
          if (tel.length == 10) {
            tel = helper.insertString(entity.tel.toString(), 3, "-");
            tel = helper.insertString(tel.toString(), 7, "-");
          }
          this.userId = entity.id.toString().padStart(5, "0");
          this.firstname = entity.firstname;
          this.lastname = entity.lastname;
          this.tel = tel;
          this.email = entity.email;
          this.username = entity.username;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    add() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("firstname", this.firstname.trim());
      formData.append("lastname", this.lastname.trim());
      formData.append("email", this.email);
      formData.append("username", this.username);
      formData.append("password", this.password);
      formData.append("tel", this.tel);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/user-ex",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/executive/management"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          if (err.message == "error data duplicate") {
            this.$root.getErrorSystemMessage("User นี้มีผู้ใช้งานแล้ว");
          } else {
            this.$root.getErrorSystemMessage(err);
          }
          this.$store.state.loading = false;
        });
    },
    update() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("firstname", this.firstname.trim());
      formData.append("lastname", this.lastname.trim());
      formData.append("email", this.email);
      formData.append("username", this.username);
      formData.append("password", this.password);
      formData.append("tel", this.tel);
      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/user-ex/",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/executive/management"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          if (err.message == "error data duplicate") {
            this.$root.getErrorSystemMessage("User นี้มีผู้ใช้งานแล้ว");
          } else {
            this.$root.getErrorSystemMessage(err);
          }
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
