<template>
  <v-container>
    <v-form @submit.prevent="search()" ref="form">
      <v-row class="mt-1" align="center">
        <v-col cols="1" class="text-center">
          <b style="color: #041e41">ค้นหา</b>
        </v-col>
        <v-col cols="8">
          <v-row>
            <v-col cols="12" sm="4">
              <v-select
                class="select--icon--center placeholder--black"
                hide-details="auto"
                color="app-theme"
                placeholder="ลูกค้า"
                v-model="companyId"
                :items="ddlCustomerCompany"
                solo
                ><template v-slot:append>
                  <v-icon large>mdi-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                class="select--icon--center placeholder--black"
                hide-details="auto"
                color="app-theme"
                placeholder="Yard"
                v-model="yardId"
                :items="ddlYard"
                solo
                ><template v-slot:append>
                  <v-icon large>mdi-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                class="select--icon--center"
                hide-details="auto"
                color="app-theme"
                placeholder="ยี่ห้อรถ"
                v-model="brandId"
                :items="ddlBrand"
                solo
                ><template v-slot:append>
                  <v-icon large>mdi-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" class="text-right pr-10">
          <v-btn
            class="py-6"
            rounded
            outlined
            color="app-theme"
            dark
            style="text-transform: none !important"
            type="submit"
          >
            <b> ค้นหา</b>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-5">
        <v-col cols="1" class="text-center"> </v-col>
        <v-col cols="8" class="">
          <v-row>
            <v-col cols="12" sm="4">
              <v-menu
                v-model="menuStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="input--icon--center"
                    v-model="dateStartFormatted"
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                    solo
                    required
                    hide-details
                    placeholder="วันที่"
                  >
                    <template v-slot:append>
                      <v-img
                        class="pa-0 ma-0"
                        src="@/assets/icons/ic_calendar_blue.svg"
                        contain
                        width="26"
                      ></v-img>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dateStart"
                  no-title
                  @change="menuStart = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" class="text-right">
          <v-btn
            class="py-6"
            :to="{ name: 'ExportVehicle.Add' }"
            rounded
            color="app-theme"
            dark
            style="text-transform: none !important"
          >
            <v-icon>mdi-plus</v-icon>
            <b> เพิ่มการส่งออกรถ (ขาย)</b>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="items"
      class="elevation-1"
      :page.sync="page"
      :items-per-page="pageSize"
      @page-count="pageCount = $event"
      :header-props="{
        sortIcon: 'mdi-menu-up',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        'items-per-page-options': rowsPerPage,
      }"
    >
      <template v-slot:item.actions="{ item }">
        <router-link
          :to="{ name: 'ExportVehicle.Edit', params: { id: item.id } }"
          class="text-decoration-none mr-1"
        >
          <v-btn dark color="app-light-theme"><b>ดูรายละเอียด</b></v-btn>
        </router-link>
      </template>
      <template v-slot:footer> </template>
    </v-data-table>
    <DialogDelete :dialog.sync="showDialog" :deleteId="deleteId"></DialogDelete>
    <pagination
      ref="Pagination"
      :page="page"
      :page-size="pageSize"
      @changePage="page = $event"
      @changePageSize="pageSize = $event"
    />
  </v-container>
</template>
<script>
import DialogDelete from "@/components/DialogDelete";
import Pagination from "@/components/Pagination";
import helper from "@/helpers/helper";
export default {
  components: {
    DialogDelete,
    Pagination,
  },
  data: () => ({
    helper: helper,
    yardId: null,
    companyId: null,
    brandId: null,
    ddlYard: [],
    ddlStatus: [
      { text: "รอมอบหมาย Driver Leader", value: 1 },
      { text: "รอดำเนินการ", value: 2 },
      { text: "กำลังดำเนินการ", value: 3 },
      { text: "ดำเนินการเสร็จสิ้น", value: 4 },
      { text: "ยกเลิก", value: 5 },
    ],
    ddlCustomerCompany: [{ text: "MST", value: 1 }],
    ddlBrand: [
      { text: "MASDA", value: 1 },
      { text: "HONDA", value: 2 },
    ],
    dateStart: "",
    dateStartFormatted: "",
    menuStart: false,

    showDialog: false,
    deleteId: null,

    page: 1,
    pageCount: 0,
    pageSize: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "Job No.",
        align: "center",
        sortable: false,
        value: "jobNo",
      },
      {
        text: "บริษัทลูกค้า",
        align: "center",
        value: "company",
      },
      { text: "ยี่ห้อรถ", align: "center", value: "brand" },
      { text: "YARD", align: "center", value: "yard" },
      { text: "วันที่นำรถออก", align: "center", value: "date" },
      {
        text: "เวลาที่นำรถออก",
        align: "center",
        value: "time",
        width: "100px",
      },
      {
        text: "",
        sortable: false,
        align: "center",
        value: "actions",
        width: "240px",
      },
    ],
    items: [],
  }),
  mounted() {
    this.$store.state.title = "ส่งออกรถ (ขาย)";
    this.$store.state.showBackBtn = false;
    this.getYard();
    this.getBrand();
    this.getCompany();
    this.search();
  },
  watch: {
    dateStart() {
      this.dateStartFormatted = this.helper.formatDate(this.dateStart);
    },
    page() {
      this.search();
    },
    pageSize() {
      this.search();
    },
  },
  methods: {
    getYard() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlYard = resp.data.data;
          if (ddlYard) {
            this.ddlYard = ddlYard.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getBrand() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/car-brand/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlBrand = entity.map((item) => {
                return {
                  text: item.name,
                  value: item.id,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getCompany() {
      this.$store.state.loading = true;
      this.$root
        .appApi({ url: "admin/user-company/all" })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlCustomerCompany = entity.map((item) => {
                return {
                  value: item.id,
                  text: item.name,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    search() {
      const formData = new FormData();
      if (!helper.isNullOrEmpty(this.companyId)) {
        formData.append("userCompanyId", this.companyId);
      }
      if (!helper.isNullOrEmpty(this.yardId)) {
        formData.append("yardId", this.yardId);
      }
      if (!helper.isNullOrEmpty(this.brandId)) {
        formData.append("carBrandId", this.brandId);
      }
      if (!helper.isNullOrEmpty(this.dateStartFormatted)) {
        formData.append("importDate", this.dateStart);
      } else {
        formData.append("importDate", "0001-01-01");
      }
      this.$root
        .appApi({
          method: "POST",
          url: "admin/car-exporting/search",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                let company = "-";
                let brand = "-";
                if (item.userCompany) company = item.userCompany.name;
                if (item.carBrand) brand = item.carBrand.name;
                let exportAt = "";
                let exportTimeAt = "";
                if (item.exportAt) {
                  const [date, time] = item.exportAt.split(" ");
                  exportAt = helper.formatDate(date);
                  exportTimeAt = time;
                }

                return {
                  id: item.id,
                  jobNo: item.jobNo,
                  company: company,
                  brand: brand,
                  yard: item.yard.name ?? "-",
                  date: exportAt,
                  time: `${exportTimeAt} น.`,
                };
              });
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data.length
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
