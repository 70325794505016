<template>
  <div class="my-10">
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>ระบุประเภทรถ</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="typeName"
            color="app-theme"
            placeholder="ประเภทรถ"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุประเภทรถ',
              (v) => !isNullOrWhitespace(v) || 'กรุณาระบุประเภทรถ',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/vehicle/type/management/')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAdd: this.$route.name === "VehicleType.Add",
      typeName: "",
    };
  },
  mounted() {
    this.$store.state.title = "เพิ่มประเภทรถ";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/vehicle/type/management";
    if (this.$route.name === "VehicleType.Edit") {
      this.$store.state.title = "แก้ไขประเภทรถ";
      this.get();
    }
  },
  computed: {
    imgUrl() {
      return this.brandImgUrl
        ? this.brandImgUrl
        : this.brandImg
        ? this.brandImg
        : this.defaultImage;
    },
  },
  methods: {
    isNullOrWhitespace(input) {
      if (typeof input === "undefined" || input == null) return true;

      return input.replace(/\s/g, "").length < 1;
    },
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({ url: "admin/car-type/" + this.$route.params.id })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.typeName = resp.data.data.name;
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    add() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("name", this.typeName);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/car-type",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/vehicle/type/management"
            );
          }

          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    update() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("name", this.typeName);
      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/car-type",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/vehicle/type/management"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
