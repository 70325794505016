<template>
  <div class="my-10">
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="start">
        <v-col cols="12" sm="3" class="pl--title">
          <b>อัพโหลดรูปภาพ Brand</b>
        </v-col>
        <v-col cols="10" sm="6" align="center">
          <div style="width: 200px; position: relative">
            <v-img
              min-height="150"
              lazy-src="@/assets/default_img.svg"
              :src="imgUrl"
            ></v-img>
            <v-file-input
              style="display: none"
              ref="img"
              name="brandImg"
              hide-details="auto"
              :hidden="true"
              @change="onImageChange"
              accept="image/*"
            ></v-file-input>

            <v-btn
              absolute
              class="mx-2"
              fab
              dark
              small
              color="app-theme"
              style="bottom: -15px; right: -20px"
              @click="$refs.img.$refs.input.click()"
            >
              <v-icon dark> mdi-camera </v-icon>
            </v-btn>
            <div class="v-text-field__details mt-2" v-if="validImageUrl">
              <div class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    กรุณาเลือกไฟล์ JPG,PNG ขนาด 100*100
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>ระบุยี่ห้อรถ</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="brandName"
            color="app-theme"
            placeholder="ยี่ห้อรถ"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุยี่ห้อรถ']"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/vehicle/brand/management/')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAdd: this.$route.name === "VehicleBrand.Add",
      brandName: "",
      brandImg: "",
      defaultImg: "@/assets/default_img.svg",
      brandImgUrl: "@/assets/default_img.svg",
      validImageUrl: false,
      file: null,
    };
  },
  mounted() {
    this.$store.state.title = "เพิ่มยี่ห้อรถ (Brand)";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/vehicle/brand/management";
    if (this.$route.name === "VehicleBrand.Edit") {
      this.$store.state.title = "แก้ไขยี่ห้อรถ (Brand)";
      this.get();
    }
  },
  computed: {
    imgUrl() {
      return this.brandImgUrl
        ? this.brandImgUrl
        : this.brandImg
        ? this.brandImg
        : this.defaultImage;
    },
  },
  methods: {
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({ url: "admin/car-brand/" + this.$route.params.id })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.brandName = resp.data.data.name;
            this.brandImg = resp.data.data.logoImg;
            this.brandImgUrl = resp.data.data.logoImg;
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    add() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("name", this.brandName);
      formData.append("logoImg", this.brandImg);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/car-brand",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/vehicle/brand/management"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    update() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("name", this.brandName);
      if (this.file) formData.append("logoImg", this.brandImg);
      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/car-brand",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/vehicle/brand/management"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    onImageChange(file) {
      this.brandImgUrl = URL.createObjectURL(file);
      this.brandImg = file;
      this.file = file;

      if (this.imgUrl === this.defaultImage) {
        this.validImageUrl = true;
        return;
      } else {
        this.validImageUrl = false;
      }
    },
  },
};
</script>

<style></style>
