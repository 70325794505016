import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import LoginAdmin from "../views/auth/LoginAdmin.vue";
import ChangePassword from "../views/auth/ChangePassword.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import Profile from "../views/auth/Profile.vue";

import Admin from "../views/admin_management/admins/Index.vue";
import AdminForm from "../views/admin_management/admins/Form.vue";
import AdminChangePassword from "../views/admin_management/admins/ChangePassword.vue";
import Position from "../views/admin_management/position/Index.vue";
import PositionForm from "../views/admin_management/position/Form.vue";

import Executive from "../views/executive_management/Index.vue";
import ExecutiveForm from "../views/executive_management/Form.vue";
import ExecutiveChangePassword from "../views/executive_management/ChangePassword.vue";

import Customer from "../views/customer_management/customers/Index.vue";
import CustomerForm from "../views/customer_management/customers/Form.vue";
import CustomerChangePassword from "../views/customer_management/customers/ChangePassword.vue";
import CustomerCompany from "../views/customer_management/customer_companies/Index.vue";
import CustomerCompanyForm from "../views/customer_management/customer_companies/Form.vue";

import VehicleBrand from "../views/vehicle_management/vehicle_brands/Index.vue";
import VehicleBrandForm from "../views/vehicle_management/vehicle_brands/Form.vue";
import VehicleType from "../views/vehicle_management/vehicle_types/Index.vue";
import VehicleTypeForm from "../views/vehicle_management/vehicle_types/Form.vue";
import VehicleColor from "../views/vehicle_management/vehicle_colors/Index.vue";
import VehicleColorForm from "../views/vehicle_management/vehicle_colors/Form.vue";
import VehicleModel from "../views/vehicle_management/vehicle_models/Index.vue";
import VehicleModelForm from "../views/vehicle_management/vehicle_models/Form.vue";
import VehicleMscMode from "../views/vehicle_management/vehicle_msc_mode/Index.vue";
import VehicleMscModeForm from "../views/vehicle_management/vehicle_msc_mode/Form.vue";

import Yard from "../views/yard_management/yard/Index.vue";
import YardForm from "../views/yard_management/yard/Form.vue";
import YardInfo from "../views/yard_management/yard/YardInformation.vue";
import YardDetail from "../views/yard_management/yard/YardDetail.vue";
import YardSubDetail from "../views/yard_management/yard/YardSubDetail.vue";

import Phase from "../views/yard_management/phase/Index.vue";
import PhaseForm from "../views/yard_management/phase/Form.vue";

import Zone from "../views/yard_management/zone/Index.vue";
import ZoneForm from "../views/yard_management/zone/Form.vue";

import EmployeeGroup from "../views/labor/employee_group/Index.vue";
import EmployeeGroupForm from "../views/labor/employee_group/Form.vue";

import EmployeeDetail from "../views/labor/employee_detail/Index.vue";
import EmployeeDetailForm from "../views/labor/employee_detail/Form.vue";
import EmployeeDetailChangePassword from "../views/labor/employee_detail/ChangePassword.vue";

import RecordOfWork from "../views/labor/record_of_work/Index.vue";
import RecordOfWorkDetail from "../views/labor/record_of_work/Detail.vue";
import RecordOfWorkImport from "../views/labor/record_of_work/Import.vue";

import RecordLeaveOfWork from "../views/labor/record_leave_of_work/Index.vue";
import RecordLeaveOfWorkForm from "../views/labor/record_leave_of_work/Form.vue";

import LaborSchedule from "../views/labor/schedule/Index.vue";
import LaborScheduleForm from "../views/labor/schedule/Form.vue";

import ExportVehicle from "../views/export_vehicle/Index.vue";
import ExportVehicleForm from "../views/export_vehicle/Form.vue";

import ImportVehicle from "../views/import_vehicle/Index.vue";
import ImportVehicleForm from "../views/import_vehicle/Form.vue";

import Stock from "../views/stock/Index.vue";
import StockVinNoDetail from "../views/stock/StockVinNoDetail.vue";
import StockDetail from "../views/stock/StockDetail.vue";
import StockSubDetail from "../views/stock/StockSubDetail.vue";

import ReportExportVehicle from "../views/report/export_vehicle/Index.vue";

import ReportImportVehicle from "../views/report/import_vehicle/Index.vue";

import SettingLeaveType from "../views/setting/leave_type/Index.vue";
import SettingLeaveTypeForm from "../views/setting/leave_type/Form.vue";

import SettingNotification from "../views/setting/Notification.vue";
import SettingPerformance from "../views/setting/Performance.vue";
import SettingCloseJob from "../views/setting/CloseJob.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "LoginAdmin",
    component: LoginAdmin,
  },
  {
    path: "/forgot_password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset_password/*",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/change_password",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/admin/management",
    name: "Admin",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "Admin.Index",
        component: Admin,
      },
      {
        path: "add",
        name: "Admin.Add",
        component: AdminForm,
      },
      {
        path: "edit/:id",
        name: "Admin.Edit",
        component: AdminForm,
      },
      {
        path: "change_password/:id",
        name: "Admin.ChangePassword",
        component: AdminChangePassword,
      },
    ],
  },
  {
    path: "/position/management",
    name: "Position",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "Position.Index",
        component: Position,
      },
      {
        path: "add",
        name: "Position.Add",
        component: PositionForm,
      },
      {
        path: "edit/:id",
        name: "Position.Edit",
        component: PositionForm,
      },
    ],
  },
  {
    path: "/executive/management",
    name: "Executive",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "Executive.Index",
        component: Executive,
      },
      {
        path: "add",
        name: "Executive.Add",
        component: ExecutiveForm,
      },
      {
        path: "edit/:id",
        name: "Executive.Edit",
        component: ExecutiveForm,
      },
      {
        path: "change_password/:id",
        name: "Executive.ChangePassword",
        component: ExecutiveChangePassword,
      },
    ],
  },
  {
    path: "/customer/management",
    name: "Customer",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "Customer.Index",
        component: Customer,
      },
      {
        path: "add",
        name: "Customer.Add",
        component: CustomerForm,
      },
      {
        path: "edit/:id",
        name: "Customer.Edit",
        component: CustomerForm,
      },
      {
        path: "change_password/:id",
        name: "Customer.ChangePassword",
        component: CustomerChangePassword,
      },
    ],
  },
  {
    path: "/customer/company/management",
    name: "CustomerCompany",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "CustomerCompany.Index",
        component: CustomerCompany,
      },
      {
        path: "add",
        name: "CustomerCompany.Add",
        component: CustomerCompanyForm,
      },
      {
        path: "edit/:id",
        name: "CustomerCompany.Edit",
        component: CustomerCompanyForm,
      },
    ],
  },
  {
    path: "/vehicle/brand/management",
    name: "VehicleBrand",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "VehicleBrand.Index",
        component: VehicleBrand,
      },
      {
        path: "add",
        name: "VehicleBrand.Add",
        component: VehicleBrandForm,
      },
      {
        path: "edit/:id",
        name: "VehicleBrand.Edit",
        component: VehicleBrandForm,
      },
    ],
  },
  {
    path: "/vehicle/type/management",
    name: "VehicleType",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "VehicleType.Index",
        component: VehicleType,
      },
      {
        path: "add",
        name: "VehicleType.Add",
        component: VehicleTypeForm,
      },
      {
        path: "edit/:id",
        name: "VehicleType.Edit",
        component: VehicleTypeForm,
      },
    ],
  },
  {
    path: "/vehicle/model/management",
    name: "VehicleModel",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "VehicleModel.Index",
        component: VehicleModel,
      },
      {
        path: "add",
        name: "VehicleModel.Add",
        component: VehicleModelForm,
      },
      {
        path: "edit/:id",
        name: "VehicleModel.Edit",
        component: VehicleModelForm,
      },
    ],
  },
  {
    path: "/vehicle/msc_mode/management",
    name: "VehicleMscMode",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "VehicleMscMode.Index",
        component: VehicleMscMode,
      },
      {
        path: "add",
        name: "VehicleMscMode.Add",
        component: VehicleMscModeForm,
      },
      {
        path: "edit/:id",
        name: "VehicleMscMode.Edit",
        component: VehicleMscModeForm,
      },
    ],
  },
  {
    path: "/vehicle/color/management",
    name: "VehicleColor",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "VehicleColor.Index",
        component: VehicleColor,
      },
      {
        path: "add",
        name: "VehicleColor.Add",
        component: VehicleColorForm,
      },
      {
        path: "edit/:id",
        name: "VehicleColor.Edit",
        component: VehicleColorForm,
      },
    ],
  },
  {
    path: "/yard/management",
    name: "Yard",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "Yard.Index",
        component: Yard,
      },
      {
        path: "add",
        name: "Yard.Add",
        component: YardForm,
      },
      {
        path: "edit/:id",
        name: "Yard.Edit",
        component: YardForm,
      },
      {
        path: "info",
        name: "Yard.Information",
        component: YardInfo,
      },
      {
        path: "info/detail/:id",
        name: "Yard.Detail",
        component: YardDetail,
      },
      {
        path: "info/sub_detail/:id",
        name: "Yard.SubDetail",
        component: YardSubDetail,
      },
    ],
  },
  {
    path: "/phase/management",
    name: "Phase",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "Phase.Index",
        component: Phase,
      },
      {
        path: "add",
        name: "Phase.Add",
        component: PhaseForm,
      },
      {
        path: "edit/:id",
        name: "Phase.Edit",
        component: PhaseForm,
      },
    ],
  },
  {
    path: "/zone/management",
    name: "Zone",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "Zone.Index",
        component: Zone,
      },
      {
        path: "add",
        name: "Zone.Add",
        component: ZoneForm,
      },
      {
        path: "edit/:id",
        name: "Zone.Edit",
        component: ZoneForm,
      },
    ],
  },

  {
    path: "/labor/employee_group",
    name: "EmployeeGroup",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "EmployeeGroup.Index",
        component: EmployeeGroup,
      },
      {
        path: "add",
        name: "EmployeeGroup.Add",
        component: EmployeeGroupForm,
      },
      {
        path: "edit/:id",
        name: "EmployeeGroup.Edit",
        component: EmployeeGroupForm,
      },
    ],
  },
  {
    path: "/labor/employee_detail",
    name: "EmployeeDetail",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "EmployeeDetail.Index",
        component: EmployeeDetail,
      },
      {
        path: "add",
        name: "EmployeeDetail.Add",
        component: EmployeeDetailForm,
      },
      {
        path: "edit/:id",
        name: "EmployeeDetail.Edit",
        component: EmployeeDetailForm,
      },
      {
        path: "change_password/:id",
        name: "EmployeeDetail.ChangePassword",
        component: EmployeeDetailChangePassword,
      },
    ],
  },
  {
    path: "/labor/record_of_work",
    name: "RecordOfWork",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "RecordOfWork.Index",
        component: RecordOfWork,
      },
      {
        path: "import",
        name: "RecordOfWork.Add",
        component: RecordOfWorkImport,
      },
      {
        path: "detail/:date&:yardId",
        name: "RecordOfWork.Edit",
        component: RecordOfWorkDetail,
      },
    ],
  },
  {
    path: "/labor/record_leave_of_work",
    name: "RecordLeaveOfWork",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "RecordLeaveOfWork.Index",
        component: RecordLeaveOfWork,
      },
      {
        path: "add",
        name: "RecordLeaveOfWork.Add",
        component: RecordLeaveOfWorkForm,
      },
      {
        path: "edit/:id",
        name: "RecordLeaveOfWork.Edit",
        component: RecordLeaveOfWorkForm,
      },
    ],
  },
  {
    path: "/labor/schedule",
    name: "LaborSchedule",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "LaborSchedule.Index",
        component: LaborSchedule,
      },
      {
        path: "edit/:date&:yardId",
        name: "LaborSchedule.Edit",
        component: LaborScheduleForm,
      },
    ],
  },
  {
    path: "/export_vehicle",
    name: "ExportVehicle",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "ExportVehicle.Index",
        component: ExportVehicle,
      },
      {
        path: "add",
        name: "ExportVehicle.Add",
        component: ExportVehicleForm,
      },
      {
        path: "edit/:id",
        name: "ExportVehicle.Edit",
        component: ExportVehicleForm,
      },
    ],
  },
  {
    path: "/import_vehicle",
    name: "ImportVehicle",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "ImportVehicle.Index",
        component: ImportVehicle,
      },
      {
        path: "add",
        name: "ImportVehicle.Add",
        component: ImportVehicleForm,
      },
      {
        path: "edit/:id",
        name: "ImportVehicle.Edit",
        component: ImportVehicleForm,
      },
    ],
  },
  {
    path: "/stock",
    name: "Stock",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "Stock.Index",
        component: Stock,
      },
      {
        path: "detail/id=:id&yardId=:yardId&phaseId=:phaseId",
        name: "Stock.Detail",
        component: StockDetail,
      },
      {
        path: "sub_detail/id=:id&yardId=:yardId&phaseId=:phaseId&zoneId=:zoneId",
        name: "Stock.SubDetail",
        component: StockSubDetail,
      },
      {
        path: "vin_no_detail/id=:id&vinNo=:vinNo",
        name: "Stock.VinNoDetail",
        component: StockVinNoDetail,
      },
    ],
  },
  {
    path: "/report/export_vehicle",
    name: "ReportExportVehicle",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "ReportExportVehicle.Index",
        component: ReportExportVehicle,
      },
    ],
  },
  {
    path: "/report/import_vehicle",
    name: "ReportImportVehicle",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "ReportImportVehicle.Index",
        component: ReportImportVehicle,
      },
    ],
  },
  {
    path: "/setting/notification",
    name: "SettingNotification",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "SettingNotification.Index",
        component: SettingNotification,
      },
    ],
  },
  {
    path: "/setting/performance",
    name: "SettingPerformance",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "SettingPerformance.Index",
        component: SettingPerformance,
      },
    ],
  },
  {
    path: "/setting/close_job",
    name: "SettingCloseJob",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "SettingCloseJob.Index",
        component: SettingCloseJob,
      },
    ],
  },
  {
    path: "/setting/leave_type",
    name: "SettingLeaveType",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "SettingLeaveType.Index",
        component: SettingLeaveType,
      },
      {
        path: "add",
        name: "SettingLeaveType.Add",
        component: SettingLeaveTypeForm,
      },
      {
        path: "edit/:id",
        name: "SettingLeaveType.Edit",
        component: SettingLeaveTypeForm,
      },
    ],
  },

  {
    path: "/abort",
    name: "Abort",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Abort.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (
    store.getters.isLoggedIn &&
    ["Login", "ForgotPassword", "ResetPassword"].includes(to.name)
  ) {
    next(false);
    return;
  } else {
    next();
  }
});

export default router;
