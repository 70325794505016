<template>
  <v-container>
    <v-row class="my-1 mb-10" align="center">
      <v-col cols="1" align="center" class="text-center">
        <b>วันที่</b>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="12" sm="12">
            <v-row align="center">
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menuStart"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="input--icon--center"
                      v-model="dateStartFormatted"
                      persistent-hint
                      append-icon="mdi-calendar-month"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      required
                      hide-details
                    >
                      <template v-slot:append>
                        <v-img
                          class="pa-0 ma-0"
                          src="@/assets/icons/ic_calendar_blue.svg"
                          contain
                          width="24"
                        ></v-img>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateStart"
                    no-title
                    @change="menuStart = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="items"
      class="elevation-1 border-1"
      :page.sync="page"
      :items-per-page="pageSize"
      @page-count="pageCount = $event"
      :header-props="{
        sortIcon: 'mdi-menu-up',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        'items-per-page-options': rowsPerPage,
      }"
    >
      <template v-slot:item.employeeName="{ item }">
        <div class="text-left">
          {{ item.employeeName }}
        </div>
      </template>
      <template v-slot:item.role="{ item }">
        <div class="text-left">
          {{ item.role }}
        </div>
      </template>
      <template v-slot:item.startTime="{ item }">
        <div style="color: #203486">{{ item.startTime }}</div>
      </template>
      <template v-slot:item.endTime="{ item }">
        <div style="color: #203486">{{ item.endTime }}</div>
      </template>
      <template v-slot:item.leave="{ item }">
        <div style="color: #203486">{{ item.leave }}</div>
      </template>
      <template v-slot:item.absent="{ item }">
        <div style="color: #203486">{{ item.absent }}</div>
      </template>
      <template v-slot:footer> </template>
    </v-data-table>
    <Pagination
      ref="Pagination"
      :page="page"
      :page-size="pageSize"
      @changePage="page = $event"
      @changePageSize="pageSize = $event"
    />
  </v-container>
</template>
<style scoped>
.border-1 >>> td {
  border-left: 1px solid #ddd;
}
.border-1 >>> th {
  border-left: 1px solid #ddd;
}
</style>
<script>
import helper from "@/helpers/helper";
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },

  data: () => ({
    helper: helper,
    dateStart: "",
    dateStartFormatted: "",
    menuStart: false,

    page: 1,
    pageCount: 0,
    pageSize: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "ลำดับ",
        align: "center",
        value: "runningNo",
      },
      {
        text: "ชื่อพนักงาน",
        align: "center",
        value: "fullname",
        width: "180px",
      },
      { text: "ตำแหน่ง", align: "center", value: "position", width: "150px" },
      {
        text: "เวลาเข้างาน",
        align: "center",
        value: "inDatetime",
        sortable: false,
      },
      {
        text: "เวลาออกงาน",
        align: "center",
        value: "outDatetime",
        sortable: false,
      },
      { text: "ลางาน", align: "center", value: "leave", sortable: false },
      { text: "ขาดงาน", align: "center", value: "absent", sortable: false },
      {
        text: "",
        align: "center",
        value: "actions",
        width: "180px",
        sortable: false,
      },
    ],
    items: [],
  }),

  watch: {
    dateStart() {
      this.dateStartFormatted = this.helper.formatDate(this.dateStart);
    },
    page() {
      this.get();
    },
    pageSize() {
      this.get();
    },
  },
  mounted() {
    if (!this.$route.params.date || !this.$route.params.yardId) {
      this.$router.push("/labor/record_of_work");
    } else {
      this.get();
    }
    this.$store.state.title = "ดูรายละเอียดบันทึกการมาทำงาน";
    this.$store.state.showBackBtn = false;
    this.dateStartFormatted = this.$route.params.date;
  },
  methods: {
    convertDateToHHMM(date) {
      if (!date) return date;
      const time = new Date(date);
      return (
        time.getHours().toString().padStart(2, "0") +
        ":" +
        time.getMinutes().toString().padStart(2, "0")
      );
    },
    get() {
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append(
        "startDate",
        helper.parseDateYMD(this.$route.params.date) || ""
      );
      formData.append(
        "endDate",
        helper.parseDateYMD(this.$route.params.date) || ""
      );
      formData.append("yardId", this.$route.params.yardId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/staff-work/search-by-date",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            this.items = entity.map((item, idx) => {
              let runningNo = idx + 1;
              if (this.page !== 1) {
                runningNo = (this.page - 1) * this.pageSize + idx + 1;
              }
              return {
                id: item.id,
                runningNo: runningNo,
                fullname: `${item.firstname} ${item.lastname}`,
                position: item.staff.staffPosition
                  ? item.staff.staffPosition.name
                  : "-" || "-",
                inDatetime: this.convertDateToHHMM(item.inDatetime) || "-",
                outDatetime: this.convertDateToHHMM(item.outDatetime) || "-",
                leave: "-",
                absent: "-",
              };
            });
          }
          this.$refs.Pagination.getPagination(
            resp.data.count,
            resp.data.data ? resp.data.data.length : 0
          );
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>
