<template>
  <v-container>
    <v-form @submit.prevent="search()" ref="form">
      <v-row class="my-1" align="center">
        <v-col cols="12" sm="1">
          <div style="width: 80px"><b>เงื่อนไข</b></div>
        </v-col>
        <v-col cols="12" sm="11">
          <v-row align="center">
            <v-col cols="12" sm="4" xl="4">
              <v-row align="center">
                <v-col cols="auto"
                  ><div style="max-width: 80px"><b>Yard</b></div></v-col
                >
                <v-col cols="12" sm="9" xl="9">
                  <v-select
                    class="select--icon--center"
                    hide-details="auto"
                    color="app-theme"
                    placeholder="Yard"
                    v-model="yardId"
                    :items="ddlYard"
                    outlined
                    rounded
                    :rules="[(v) => !!v || 'กรุณาเลือก Yard']"
                  >
                    <template v-slot:append>
                      <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" xl="4">
              <v-row align="center">
                <v-col cols="auto" class="pa-0"
                  ><div><b>Phase</b></div></v-col
                >
                <v-col cols="12" sm="9" xl="9">
                  <v-select
                    class="select--icon--center"
                    hide-details="auto"
                    color="app-theme"
                    placeholder="Phase"
                    v-model="yardPhaseId"
                    :items="ddlPhase"
                    outlined
                    rounded
                    ><template v-slot:append>
                      <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                    </template>
                  </v-select></v-col
                >
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" xl="4">
              <v-row align="center">
                <v-col cols="auto"
                  ><div style="max-width: 60px"><b>Zone</b></div></v-col
                >
                <v-col cols="12" sm="9" xl="9">
                  <v-select
                    class="select--icon--center"
                    hide-details="auto"
                    color="app-theme"
                    placeholder="Zone"
                    v-model="yardZoneId"
                    :items="ddlZone"
                    outlined
                    rounded
                    ><template v-slot:append>
                      <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="my-1">
        <v-col cols="12" sm="1">
          <div style="max-width: 80px"><b></b></div>
        </v-col>
        <v-col cols="12" sm="11">
          <v-row align="center">
            <v-col cols="12" sm="7" md="7" lg="5" xl="4">
              <v-row align="center">
                <v-col cols="auto" class="pr-0 mr-0"
                  ><div style="max-width: 80px"><b>VIN No.</b></div></v-col
                >
                <v-col cols="12" sm="7" xl="8" class="pa-0"
                  ><v-text-field hide-details="auto" color="app-theme" v-model="vinNo" outlined rounded> </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="5" md="5" lg="7" xl="8" class="text-right pr-8">
              <v-btn class="pa-6" outlined rounded color="app-theme" dark style="text-transform: none !important" type="submit">
                <b> ค้นหา</b>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="my-1">
      <v-col cols="12" sm="12" class="pt-5 text-left">
        <b>ผลการค้นหาจาก Yard</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"> </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="pageSize"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      class="elevation-1"
      hide-default-footer
      expand-icon="mdi-chevron-right"
    >
      <template v-slot:item.actions="{ item }">
        <div width="10%">
          <router-link
            :to="{
              name: 'Stock.Detail',
              params: {
                id: item.id,
                yardId: item.yardId,
                phaseId: item.phaseId,
              },
            }"
            class="text-decoration-none mx-1"
          >
            <v-btn @click="setValueBack" min-width="20" width="40" dark color="app-pink-theme"
              ><b><v-icon color="">mdi-magnify</v-icon></b></v-btn
            >
          </router-link>
        </div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td v-if="item.yardZones.length > 0" :colspan="headers.length" class="pa-0 ma-0">
          <div>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr v-for="subItem in item.yardZones" :key="subItem.id">
                    <td class="text-center" width="34%"></td>
                    <td class="text-center" width="10%">
                      {{ subItem.zone }}
                    </td>
                    <td class="text-center" width="10%">
                      {{ subItem.company }}
                    </td>
                    <td class="text-center" width="10%">
                      {{ subItem.row }}
                    </td>
                    <td class="text-center" width="13%">
                      {{ subItem.qty }}
                    </td>
                    <td class="text-center" width="13%">
                      {{ subItem.qtyInStock }}
                    </td>
                    <td class="text-center" width="10%">
                      <router-link
                        :to="{
                          name: 'Stock.SubDetail',
                          params: {
                            id: subItem.id,
                            yardId: item.yardId,
                            phaseId: item.phaseId,
                            zoneId: subItem.id,
                          },
                        }"
                        class="text-decoration-none"
                      >
                        <v-btn min-width="20" width="40" dark color="app-pink-theme" @click="setValueBack"
                          ><b><v-icon color="">mdi-magnify</v-icon></b></v-btn
                        >
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div v-if="searchByVinNo">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr v-for="vinNo in item.vinNo" :key="vinNo.id">
                    <td class="text-right" width="90%">{{ vinNo.vinNo }}</td>
                    <td class="text-center" width="10%">
                      <router-link
                        :to="{
                          name: 'Stock.VinNoDetail',
                          params: { id: vinNo.id },
                        }"
                        class="text-decoration-none"
                      >
                        <v-btn min-width="20" width="40" dark color="app-pink-theme"
                          ><b><v-icon color="">mdi-magnify</v-icon></b></v-btn
                        >
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </td>

        <div v-else></div>
      </template>
    </v-data-table>

    <DialogDelete :dialog.sync="showDialog" :deleteId="deleteId"></DialogDelete>
    <Pagination ref="Pagination" :page="page" :page-size="pageSize" @changePage="page = $event" @changePageSize="pageSize = $event" />
  </v-container>
</template>
<style scoped>
.input--icon--center >>> .v-input__append-inner {
  margin: 0 !important;
}
.v-data-table >>> tr > td > button {
  color: #08318a;
  justify-content: right;
  align-content: right;
}
.v-data-table >>> tr:nth-child(n) > td.text-start:nth-child(1) {
  padding: 0 !important;
  text-align: right !important;
}
</style>
<script>
import DialogDelete from "@/components/DialogDelete";
import Pagination from "@/components/Pagination";
import helper from "@/helpers/helper";
export default {
  components: {
    DialogDelete,
    Pagination,
  },

  data: () => ({
    searchByVinNo: true,
    expanded: [],
    singleExpand: false,

    yardId: null,
    yardPhaseId: "",
    yardZoneId: "",
    vinNo: "",

    ddlYard: [{ text: "ทั้งหมด", value: "All" }],
    ddlPhase: [{ text: "ทั้งหมด", value: "All" }],
    ddlZone: [{ text: "ทั้งหมด", value: "All" }],
    ddlVinNo: [{ text: "ทั้งหมด", value: "All" }],

    showDialog: false,
    deleteId: null,
    page: 1,
    pageCount: 0,
    pageSize: 10,
    itemsPerPage: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "Yard",
        sortable: false,
        align: "center",
        value: "yard",
        width: "15%",
      },
      {
        text: "Phase",
        sortable: false,
        align: "center",
        value: "phase",
        width: "15%",
      },
      {
        text: "Zone",
        sortable: false,
        align: "center",
        value: "zone",
        width: "10%",
      },
      {
        text: "ลูกค้า",
        sortable: false,
        align: "center",
        value: "company",
        width: "10%",
      },
      {
        text: "Row",
        sortable: false,
        align: "center",
        value: "row",
        width: "10%",
      },
      {
        text: "ช่องจอด",
        sortable: false,
        align: "center",
        value: "qty",
        width: "13%",
      },
      {
        text: "จอดแล้ว",
        sortable: false,
        align: "center",
        value: "qtyInStock",
        width: "13%",
      },
      {
        text: "",
        sortable: false,
        align: "center",
        value: "actions",
        width: "10%",
      },
    ],

    items: [],
    prevRoute: null,
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  async mounted() {
    this.$store.state.title = "ดูสต๊อกและสถานะรถในคลัง";
    this.$store.state.showBackBtn = false;
    this.$store.state.isBackToName = "/yard/management";
    this.getYard();
    this.getPhase();
    this.getZone();

    if (this.$store.state.isBackToStock == true && this.prevRoute.path.split("/")[1] == "stock") {
      this.yardId = this.$store.state.stockYardId;
      this.yardPhaseId = this.$store.state.stockPhaseId;
      this.yardZoneId = this.$store.state.stockZoneId;
      this.vinNo = this.$store.state.stockVinNo;
      this.page = this.$store.state.stockPage;
      await this.searchHistoryBack();
      this.$store.state.loading = false;
      this.$store.commit("clearStock");
      this.$store.commit("clearTempStock");
      this.$store.state.isBackToStock = false;
    } else {
      this.$store.state.isBackToStock = false;
    }
  },
  watch: {
    yardId() {
      const phase = this.phase.filter((w) => w.yard.id == this.yardId);
      if (phase) {
        this.ddlPhase = [{ text: "ทั้งหมด", value: "" }];
        const entity = phase.map((item) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
        this.ddlPhase = this.ddlPhase.concat(entity);
      }
      this.ddlZone = [{ text: "ทั้งหมด", value: "" }];
    },
    yardPhaseId() {
      const zone = this.zone.filter((w) => w.yardPhase.id == this.yardPhaseId);
      if (zone) {
        this.ddlZone = [{ text: "ทั้งหมด", value: "" }];
        const entity = zone.map((item) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
        this.ddlZone = this.ddlZone.concat(entity);
      }
    },
    page() {
      this.search();
    },
    pageSize() {
      this.search();
    },
  },
  methods: {
    setValueBack() {
      this.$store.state.stockYardId = this.yardId;
      this.$store.state.stockPhaseId = this.yardPhaseId;
      this.$store.state.stockZoneId = this.yardZoneId;
      this.$store.state.stockVinNo = this.vinNo;
    },
    getYard() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const entity = resp.data.data;
          if (entity) {
            this.ddlYard = entity.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getPhase() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-phase/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlPhase = resp.data.data;
          this.phase = ddlPhase;
          if (ddlPhase) {
            this.ddlPhase = [{ text: "ทั้งหมด", value: "" }];
            const entity = ddlPhase.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
            this.ddlPhase = this.ddlPhase.concat(entity);
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getZone() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-zone/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlZone = resp.data.data;
          this.zone = ddlZone;
          if (ddlZone) {
            this.ddlZone = [{ text: "ทั้งหมด", value: "" }];
            const entity = ddlZone.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
            this.ddlZone = this.ddlZone.concat(entity);
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    search() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("yardId", this.yardId);
      if (this.yardPhaseId) formData.append("yardPhaseId", this.yardPhaseId);
      if (this.yardZoneId) formData.append("yardZoneId", this.yardZoneId);
      formData.append("vinNo", this.vinNo);

      this.searchByVinNo = !helper.isNullOrEmpty(this.vinNo) && this.yardZoneId;

      this.$root
        .appApi({
          method: "POST",
          url: "admin/car-in/search",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            let vinNoId = null;
            // let _yardId = null;
            // let _phaseId = null;
            // let _zoneId = null;
            let flagRouteToVinNo = false;
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                // if (!this.vinNo === false) {
                //   vinNoId = item.id;
                //   // _yardId = item.yard.id;
                //   // _phaseId = item.id;
                //   // _zoneId = item.zone.id;
                // }
                let zone = "-";
                let company = "-";
                let row = 0;
                let qty = 0;
                let qtyInStock = 0;
                let yardZones = [];
                let vinNo = [];
                if (item.yardZones) {
                  zone = item.yardZones.length;
                  company = item.yardZones[0].userCompany.name;
                  item.yardZones.map((yz) => {
                    // if (!this.vinNo === false) {
                    //   vinNoId = yz.id;
                    //   // _zoneId = yz.id;
                    // }
                    row = row + yz.rowNumbers;
                    if (yz.yardZoneRows) {
                      yz.yardZoneRows.map((item) => {
                        qty = qty + item.qty;
                        qtyInStock = qtyInStock + item.qtyInStock;
                      });
                    }
                  });
                  yardZones = item.yardZones.map((item) => {
                    let qty = 0;
                    let qtyInStock = 0;
                    if (item.yardZoneRows) {
                      item.yardZoneRows.map((yzr) => {
                        qty = qty + yzr.qty;
                        qtyInStock = qtyInStock + yzr.qtyInStock;
                        vinNo = yzr.carIns;

                        if (vinNo) {
                          vinNo.map((vn) => {
                            if (vn.vinNo == this.vinNo) {
                              flagRouteToVinNo = true;
                              vinNoId = vn.id;
                            }
                          });
                        }
                      });
                    }
                    return {
                      id: item.id,
                      zone: item.name,
                      company: item.userCompany.name,
                      row: item.rowNumbers,
                      qty: qty,
                      qtyInStock: qtyInStock,
                    };
                  });
                }
                return {
                  id: item.id,
                  yard: item.yard.name,
                  phase: item.name,
                  zone: zone,
                  company: company,
                  row: row,
                  qty: qty,
                  qtyInStock: qtyInStock,
                  yardZones: yardZones,
                  vinNo: vinNo,
                  yardId: item.yard.id,
                  phaseId: item.id,
                };
              });
            }
            if (!this.vinNo === false && entity.length === 1) {
              this.setValueBack();
              this.$store.commit("fromIndexStockToStockVinNoPage");
              // this.$router.push({
              //   path: `/stock/sub_detail/id=${_id}&yardId=${_yardId}&phaseId=${_phaseId}&zoneId=${_zoneId}`,
              // });
              if (flagRouteToVinNo) {
                this.$router.push({
                  path: `/stock/vin_no_detail/id=${vinNoId}&vinNo=${this.vinNo}`,
                });
              }
            }
            this.$refs.Pagination.getPagination(resp.data.count, resp.data.data.length);
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    async searchHistoryBack() {
      this.$store.state.loading = true;
      const formData = new FormData();
      if (this.yardId) formData.append("yardId", this.yardId);
      if (this.yardPhaseId) formData.append("yardPhaseId", this.yardPhaseId);
      if (this.yardZoneId) formData.append("yardZoneId", this.yardZoneId);
      if (this.vinNo) formData.append("vinNo", this.vinNo);

      this.searchByVinNo = !helper.isNullOrEmpty(this.vinNo) && this.yardZoneId;

      await this.$root
        .appApi({
          method: "POST",
          url: "admin/car-in/search",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            // let _id = null;
            // let _yardId = null;
            // let _phaseId = null;
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                // if (!this.vinNo === false) {
                //   _id = item.id;
                //   _yardId = item.yard.id;
                //   _phaseId = item.id;
                // }
                let zone = "-";
                let company = "-";
                let row = 0;
                let qty = 0;
                let qtyInStock = 0;
                let yardZones = [];
                let vinNo = [];
                if (item.yardZones) {
                  zone = item.yardZones.length;
                  company = item.yardZones[0].userCompany.name;
                  item.yardZones.map((yz) => {
                    row = row + yz.rowNumbers;
                    if (yz.yardZoneRows) {
                      yz.yardZoneRows.map((item) => {
                        qty = qty + item.qty;
                        qtyInStock = qtyInStock + item.qtyInStock;
                      });
                    }
                  });
                  yardZones = item.yardZones.map((item) => {
                    let qty = 0;
                    let qtyInStock = 0;
                    if (item.yardZoneRows) {
                      item.yardZoneRows.map((yzr) => {
                        qty = qty + yzr.qty;
                        qtyInStock = qtyInStock + yzr.qtyInStock;
                        vinNo = yzr.carIns;
                      });
                    }
                    return {
                      id: item.id,
                      zone: item.name,
                      company: item.userCompany.name,
                      row: item.rowNumbers,
                      qty: qty,
                      qtyInStock: qtyInStock,
                    };
                  });
                }
                return {
                  id: item.id,
                  yard: item.yard.name,
                  phase: item.name,
                  zone: zone,
                  company: company,
                  row: row,
                  qty: qty,
                  qtyInStock: qtyInStock,
                  yardZones: yardZones,
                  vinNo: vinNo,
                  yardId: item.yard.id,
                  phaseId: item.id,
                };
              });
            }
            // if (!this.vinNo === false && entity.length === 1) {
            //   this.$router.push({
            //     path: `/stock/detail/id=${_id}&yardId=${_yardId}&phaseId=${_phaseId}`,
            //   });
            // }
            this.$refs.Pagination.getPagination(resp.data.count, resp.data.data.length);
          }
          // this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>
