<template>
  <div class="my-10">
    <v-form @submit.prevent="update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="12" class="pl-10">
          <h3>กำหนดเวลาแจ้งเตือน Labor</h3>
        </v-col>
      </v-row>
      <v-row
        align="center"
        v-for="(item, index) in notification"
        :key="item.id"
      >
        <v-col cols="12" sm="8" class="pl-10">
          {{ `${index + 1}.${item.label}` }}
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="item.value"
            color="app-theme"
            placeholder=""
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุเวลา']"
            autocomplete="off"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" class="pl-10"> {{ item.labelExt }} </v-col>
      </v-row>
      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="6" class="mx-auto d-flex justify-center pr-16">
          <v-btn
            @click="count = 0"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6 mr-5"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notification: [],
    };
  },
  mounted() {
    this.$store.state.title = "ตั้งค่าการแจ้งเตือน";
    this.$store.state.showBackBtn = false;
    this.get();
  },
  methods: {
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/settings/noti",
        })
        .then((resp) => {
          this.notification = resp.data.data;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    update() {
      if (!this.$refs.form.validate()) return;
      let code = "";
      let value = "";
      this.notification.forEach((item) => {
        if (code == "") {
          code = item.code;
        } else {
          code = code + "," + item.code;
        }
        if (value == "") {
          value = item.value;
        } else {
          value = value + "," + item.value;
        }
      });
      this.$store.state.loading = true;
      let formData = new FormData();
      formData.append("code", code);
      formData.append("value", value);
      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/settings",
          data: formData,
        })
        .then((resp) => {
          this.$root.getSystemMessage(resp.data.message);
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
      this.$root.getSystemMessage("แก้ไขข้อมูลสำเร็จ");
    },
  },
};
</script>

<style></style>
