<template>
  <div class="my-10">
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>ชื่อ Zone</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="name"
            color="app-theme"
            placeholder="กรุณาระบุชื่อ"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุชื่อ']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>Yard</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="select--icon--center placeholder--black"
            hide-details="auto"
            color="app-theme"
            placeholder="Yard"
            v-model="yardId"
            :items="ddlYard"
            outlined
            rounded
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>Phase</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="select--icon--center placeholder--black"
            hide-details="auto"
            color="app-theme"
            placeholder="Phase"
            v-model="yardPhaseId"
            :items="ddlPhase"
            outlined
            rounded
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>ประเภทของ Zone</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="select--icon--center placeholder--black"
            hide-details="auto"
            color="app-theme"
            placeholder="ประเภทของ Zone"
            v-model="yardZoneTypeId"
            :items="ddlZoneType"
            outlined
            rounded
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>บริษัทลูกค้า</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="select--icon--center placeholder--black"
            hide-details="auto"
            color="app-theme"
            placeholder="บริษัทลูกค้า"
            v-model="userCompanyId"
            :items="ddlCustomerCompany"
            outlined
            rounded
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>ยี่ห้อรถ</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="select--icon--center placeholder--black"
            hide-details="auto"
            color="app-theme"
            placeholder="ยี่ห้อรถ"
            v-model="carBrandId"
            :items="ddlBrand"
            outlined
            rounded
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>สถานะการใช้งาน</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="select--icon--center placeholder--black"
            hide-details="auto"
            color="app-theme"
            placeholder="สถานะการใช้งาน"
            v-model="status"
            :items="ddlStatus"
            outlined
            rounded
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b> จำนวน Row</b> </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            class="pl--input"
            v-model="rowNumbers"
            color="app-theme"
            placeholder="จำนวน Row"
            outlined
            hide-details="auto"
            :min="1"
            @keypress="isNumberDec($event)"
            @input="checkDec(rowNumbers)"
            type="number"
            :rules="[
              (v) => !!v || 'กรุณาระบุจำนวน Row',
              (v) => v > 0 || 'จำนวน Row ต้องมากกว่า 0',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" justify="end">
          <v-btn
            @click="rowList.length > 0 ? (dlgAddRow = true) : addRowList()"
            block
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6 none-tf"
            >สร้าง Row</v-btn
          ></v-col
        >
      </v-row>
      <v-row align="center" v-if="rowList.length > 0">
        <v-col cols="12" sm="12" class="pl--title"> ลิสต์ของ Row </v-col>
      </v-row>
      <v-row align="center" v-if="rowList.length > 0" justify="center">
        <v-col cols="11" class="px-10">
          <v-simple-table class="elevation-1">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center" width="20%">Row No.</th>
                  <th class="text-center">ประเภทรถ</th>
                  <th class="text-center" width="20%">QTY/Row</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in rowList" :key="item.no">
                  <td class="text-center">{{ item.no }}</td>
                  <td class="text-left">
                    {{ typeName(item.carTypes) }}
                    <v-btn
                      @click="showDialogType(item)"
                      icon
                      min-width="20"
                      class="btn-p-10"
                      ><v-icon color="app-theme">mdi-plus-circle</v-icon></v-btn
                    >
                  </td>
                  <td class="text-center">
                    <v-btn
                      @click="showDialogQty(item)"
                      icon
                      min-width="20"
                      class="btn-p-10"
                      v-if="parseInt(item.qty) == 0"
                      ><v-icon color="app-theme">mdi-plus-circle</v-icon></v-btn
                    >
                    <div v-else>
                      <div>
                        {{ item.qty
                        }}<v-btn
                          @click="showDialogQty(item)"
                          icon
                          min-width="20"
                          class="btn-p-10"
                          ><v-icon color="app-theme"
                            >mdi-plus-circle</v-icon
                          ></v-btn
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/zone/management/')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            v-if="isAdd"
            rounded
            :color="!isAddRow ? 'app-disabled-button-theme' : 'app-theme'"
            dark
            class="my-2 px-8 py-6"
            :type="isAddRow ? 'submit' : ''"
            >บันทึก</v-btn
          >
          <v-btn
            v-else
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="dialogQTY" persistent max-width="400">
      <v-card class="py-10">
        <v-card-text class="text-center text-body-1">
          <div class="mt-3" style="font-weight: bold; color: black">
            ระบุจำนวนคันต่อ Row
          </div></v-card-text
        >
        <v-card-text class="text-center text-body-1">
          <div class="mt-3" style="font-weight: bold; color: black">
            <v-text-field
              dense
              min-height
              height="0"
              v-model="editQty"
              color="app-theme"
              placeholder="ระบุจำนวนคันต่อ Row"
              outlined
              hide-details="auto"
              :min="0"
              type="number"
              :rules="[
                (v) => !!v || 'ระบุจำนวนคันต่อ Row',
                (v) => v > 0 || 'จำนวนคันต่อ Row ต้องมากกว่า 0',
              ]"
            ></v-text-field></div
        ></v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            @click="dialogQTY = false"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >
          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            @click="addQTY()"
            >ตกลง</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogType" persistent max-width="500">
      <v-card class="py-10">
        <v-card-text class="text-center text-body-1">
          <div class="mt-3" style="font-weight: bold; color: black">
            เพิ่มประเภทรถ
          </div></v-card-text
        >
        <v-card-text class="pa-0 ma-0">
          <v-divider></v-divider>
          <div v-for="item in carTypeList" :key="item.id">
            <v-row align="center" class="py-4">
              <v-col cols="2"></v-col>
              <v-col cols="7" class="mt-0" style="color: black">{{
                item.name
              }}</v-col>
              <v-col cols="3">
                <v-checkbox
                  class="mt-0"
                  v-model="selectCarTypes"
                  color="app-theme"
                  hide-details="auto"
                  :value="item.id"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            @click="dialogType = false"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6 mt-10"
            >ยกเลิก</v-btn
          >
          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6 mt-10"
            @click="addType()"
            >ตกลง</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgAddRow" persistent max-width="500">
      <v-card class="py-10">
        <v-card-text class="text-center text-body-1"> </v-card-text>
        <v-card-text class="text-center text-body-1">
          <div class="mt-1">
            <b style="color: black"
              >คุณต้องการเปลี่ยนแปลง<br />จำนวนRowหรือไม่</b
            >
          </div></v-card-text
        >

        <v-card-actions class="d-flex justify-center">
          <v-btn
            style="box-shadow: 1px 10px 20px 13px rgba(0, 0, 0, 0.02)"
            dark
            rounded
            class="px-15 py-6 app-disabled-button-theme mr-4"
            @click="dlgAddRow = false"
            >ยกเลิก</v-btn
          >
          <v-btn
            color="app-theme"
            style="box-shadow: 1px 10px 20px 13px rgba(0, 0, 0, 0.02)"
            dark
            rounded
            class="px-15 py-6"
            @click="addRowList()"
            >ตกลง</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.placeholder--black >>> input::placeholder {
  color: black;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  background: red;
}
</style>
<script>
export default {
  data() {
    return {
      isAddRow: false,
      dlgAddRow: false,
      editQty: 0,
      editItemQty: {},
      dialogQTY: false,

      selectCarTypes: [],
      editItemType: {},
      dialogType: false,
      defaultRowList: [],
      isAdd: this.$route.name === "Zone.Add",

      name: "",
      yardId: "",
      yardPhaseId: "",
      yardZoneTypeId: "",
      userCompanyId: "",
      carBrandId: "",
      rowNumbers: "",
      status: "",

      rowList: [],
      ddlYard: [
        { text: "Yard", value: 1 },
        { text: "OTTOLand", value: 2 },
      ],
      phase: [],
      ddlPhase: [
        { text: "Phase 1", value: 1 },
        { text: "Phase 2", value: 2 },
      ],
      ddlZoneType: [
        { text: "Zone A", value: 1 },
        { text: "Zone G", value: 2 },
      ],
      ddlCustomerCompany: [
        { text: "บริษัทลูกค้า A", value: 1 },
        { text: "บริษัทลูกค้า B", value: 2 },
      ],
      ddlBrand: [
        { text: "MAZDA", value: 1 },
        { text: "TOYOTA", value: 2 },
      ],
      ddlStatus: [
        { text: "เปิดใช้งาน", value: "active" },
        { text: "ปิดใช้งานชั่วคราว", value: "inactive" },
        { text: "ปิดใช้งาน", value: "close" },
      ],
      carTypeList: [],
      defaultRowNumbers: null,
    };
  },
  watch: {
    yardId() {
      const phase = this.phase.filter((w) => w.yard.id == this.yardId);
      if (phase) {
        const entity = phase.map((item) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
        this.ddlPhase = entity;
      } else {
        this.ddlPhase = [];
      }
    },
  },
  mounted() {
    this.$store.state.title = "เพิ่ม Zone";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/zone/management";
    this.getYard();
    this.getPhase();
    this.getZoneType();
    this.getCompany();
    this.getBrand();
    this.getCarType();
    if (this.$route.name === "Zone.Edit") {
      this.$store.state.title = "แก้ไข Zone";
      this.get();
      this.getRow();
    }
  },
  methods: {
    getCarType() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/car-type/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const carTypeList = resp.data.data;
          if (carTypeList) {
            this.carTypeList = carTypeList.map((item) => {
              return {
                name: item.name,
                id: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getYard() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlYard = resp.data.data;
          if (ddlYard) {
            this.ddlYard = ddlYard.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getPhase() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-phase/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            this.phase = entity;
            if (entity) {
              this.ddlPhase = entity.map((item) => {
                return {
                  value: item.id,
                  text: item.name,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getZoneType() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-zone-type/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlZoneType = entity.map((item) => {
                return {
                  value: item.id,
                  text: item.name,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getCompany() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/user-company/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlCustomerCompany = entity.map((item) => {
                return {
                  value: item.id,
                  text: item.name,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getBrand() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/car-brand/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlBrand = entity.map((item) => {
                return {
                  text: item.name,
                  value: item.id,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    checkDec(value) {
      this.rowNumbers = value.replace(/^0*(.+)/, "$1");
    },
    isNumberDec(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addRowList() {
      this.dlgAddRow = false;
      if (this.rowNumbers) {
        let list = [];
        for (let index = 0; index < parseInt(this.rowNumbers); index++) {
          list.push({
            no: index + 1,
            qty: 0,
            carTypes: [],
          });
        }
        this.rowList.forEach((element, index) => {
          if (index < list.length) {
            list[index] = element;
          }
        });
        this.rowList = list;
        this.isAddRow = true;
      }
    },
    showDialogQty(item) {
      this.editQty = item.qty;
      this.editItemQty = null;
      this.editItemQty = item;
      this.dialogQTY = true;
    },
    addQTY() {
      if (this.editQty <= 0) return;
      const index = this.rowList.findIndex((w) => w === this.editItemQty);
      this.rowList[index].qty = this.editQty;
      this.editQty = "";
      this.editItemQty = null;
      this.dialogQTY = false;
    },
    showDialogType(item) {
      this.selectCarTypes = [];
      if (item.carTypes) {
        this.selectCarTypes = item.carTypes.map((item) => {
          return item.id;
        });
      }
      this.editItemType = null;
      this.editItemType = item;
      this.dialogType = true;
    },
    typeName(carTypes) {
      let type = "";
      if (carTypes) {
        carTypes.map((item) => {
          if (type == "") {
            type = item.name;
          } else {
            type = `${type}, ${item.name}`;
          }
        });
      }
      return type;
    },
    addType() {
      const index = this.rowList.findIndex((w) => w === this.editItemType);
      this.rowList[index].carTypes = this.selectCarTypes.map((item) => {
        return this.carTypeList.find((w) => w.id == item);
      });
      this.editItemType = null;
      this.dialogType = false;
    },
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({ url: "admin/yard-zone/" + this.$route.params.id })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.name = resp.data.data.name;
            this.rowNumbers = resp.data.data.rowNumbers;
            this.yardId = resp.data.data.yard.id;
            this.yardPhaseId = resp.data.data.yardPhase.id;
            this.userCompanyId = resp.data.data.userCompany.id;
            this.carBrandId = resp.data.data.carBrand.id;
            this.status = resp.data.data.status;
            this.yardZoneTypeId = resp.data.data.yardZoneType.id;

            this.defaultRowNumbers = resp.data.data.rowNumbers;
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getRow() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-zone-row/zone/" + this.$route.params.id,
          params: { page: this.page, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.rowList = resp.data.data;
            this.defaultRowList = resp.data.data;
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    add() {
      if (!this.$refs.form.validate() || !this.isAddRow) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("rowNumbers", this.rowNumbers);
      formData.append("yardId", this.yardId);
      formData.append("yardPhaseId", this.yardPhaseId);
      formData.append("userCompanyId", this.userCompanyId);
      formData.append("carBrandId", this.carBrandId);
      formData.append("status", this.status);
      formData.append("yardZoneTypeId", this.yardZoneTypeId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/yard-zone",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(resp.data.message, "/zone/management");
          }
          const entity = resp.data.data;
          this.createZoneRow(entity.id, entity.rowNumbers);
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    update() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("name", this.name);
      formData.append("rowNumbers", this.rowNumbers);
      formData.append("yardId", this.yardId);
      formData.append("yardPhaseId", this.yardPhaseId);
      formData.append("userCompanyId", this.userCompanyId);
      formData.append("carBrandId", this.carBrandId);

      formData.append("status", this.status);
      formData.append("yardZoneTypeId", this.yardZoneTypeId);

      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/yard-zone",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(resp.data.message, "/zone/management");
          }
          if (
            this.defaultRowNumbers != this.rowNumbers ||
            this.defaultRowList.length == 0
          ) {
            const entity = resp.data.data;
            this.createZoneRow(entity.id, entity.rowNumbers);
          } else {
            if (this.rowNumbers !== this.defaultRowList.length) {
              this.createZoneRow(this.$route.params.id, this.rowNumbers);
            } else {
              this.rowList.forEach((element) => {
                const qty = element.qty;
                const carTypes = element.carTypes.map((item) => {
                  return item.id;
                });
                this.updateZoneRow(element.id, qty, carTypes);
              });
            }

            this.$store.state.loading = false;
          }
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    createZoneRow(zoneId, rowNumbers) {
      const formDataROw = new FormData();
      formDataROw.append("yardZoneId", zoneId);
      formDataROw.append("rowNumbers", rowNumbers);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/yard-zone-row",
          data: formDataROw,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            // this.$root.getSystemMessage(resp.data.message);
            // this.messageStatus = "บันทึกข้อมูลเรียบร้อยแล้ว";
          }
          let zoneRow = resp.data.data;
          zoneRow.forEach((element, index) => {
            const qty = this.rowList[index].qty;
            const carTypes = this.rowList[index].carTypes.map((item) => {
              return item.id;
            });
            this.updateZoneRow(element.id, qty, carTypes);
          });
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    updateZoneRow(id, qty, carTypes) {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("id", id);
      formData.append("qty", qty);
      formData.append("carTypes[]", carTypes);
      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/yard-zone-row",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            // this.$root.getSystemMessage(resp.data.message);
            // this.messageStatus = "แก้ไขข้อมูลเรียบร้อยแล้ว";
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style scoped>
#app
  > div
  > main
  > div
  > div
  > div
  > form
  > div:nth-child(10)
  > div
  > div
  > div
  > table
  > thead
  > tr
  > th:nth-child(n) {
  background-color: #4786d8 !important;
}

table th + th {
  border-left: 1px solid #b6b5b5;
}
table td + td {
  border-left: 1px solid #b6b5b5;
}
/* #app > div > main > div > div > div > form > div:nth-child(10) > div > div > div > table > thead > tr */
</style>
