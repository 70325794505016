<template>
  <v-container>
    <v-row class="my-1" align="center">
      <v-col cols="1" class="text-center">
        <h3>ค้นหา</h3>
      </v-col>
      <v-col cols="8" class="">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              label="ชื่อ/นามสกุล"
              hide-details="auto"
              color="app-theme"
              v-model="name"
              solo
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              label="เบอร์โทรศัพท์"
              hide-details="auto"
              color="app-theme"
              solo
              v-model="tel"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              hide-details="auto"
              color="app-theme"
              placeholder="ตำแหน่ง"
              v-model="positionId"
              :items="ddlPostion"
              solo
            >
              <template v-slot:append>
                <v-icon large>mdi-chevron-down</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3" class="text-right pr-15">
        <v-btn
          class="pa-6"
          rounded
          outlined
          color="app-theme"
          dark
          style="text-transform: none !important"
        >
          <h3>ค้นหา</h3>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="my-1 mb-5">
      <v-col cols="12" class="text-right">
        <v-btn
          class="py-6 mr-5"
          :to="{ name: 'EmployeeDetail.Add' }"
          rounded
          color="app-theme"
          dark
          style="text-transform: none !important"
          width="180px"
        >
          <v-icon class="px-1">mdi-plus</v-icon>
          <b class="pr-2"> เพิ่มพนักงาน</b>
        </v-btn>
        <v-btn
          class="py-6"
          rounded
          color="app-export-theme"
          dark
          style="text-transform: none !important"
          width="180px"
          @click="downloadExcel"
        >
          <b>ส่งออก Excel</b>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="items"
      class="elevation-1"
      :page.sync="page"
      :items-per-page="pageSize"
      @page-count="pageCount = $event"
      :header-props="{
        sortIcon: 'mdi-menu-up',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        'items-per-page-options': rowsPerPage,
      }"
    >
      <template v-slot:item.actions="{ item }">
        <router-link
          :to="{ name: 'EmployeeDetail.Edit', params: { id: item.id } }"
          class="text-decoration-none mr-1"
        >
          <v-btn dark color="app-theme"><b>แก้ไข</b></v-btn>
        </router-link>

        <v-btn
          dark
          @click="deleteDialog(item.id)"
          color="app-danger"
          min-width="20"
          class="btn-p-10"
          ><v-icon color="app-primary">mdi-trash-can</v-icon></v-btn
        >
      </template>
      <template v-slot:footer> </template>
    </v-data-table>
    <DialogDelete :dialog.sync="showDialog" :deleteId="deleteId"></DialogDelete>
    <Pagination
      ref="Pagination"
      :page="page"
      :page-size="pageSize"
      @changePage="page = $event"
      @changePageSize="pageSize = $event"
    />
  </v-container>
</template>

<script>
import DialogDelete from "@/components/DialogDelete";
import Pagination from "@/components/Pagination";
export default {
  components: {
    DialogDelete,
    Pagination,
  },
  data: () => ({
    name: "",
    tel: "",
    positionId: null,
    ddlPostion: [],

    showDialog: false,
    deleteId: null,

    page: 1,
    pageCount: 0,
    pageSize: 10,
    itemsPerPage: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "User ID",
        align: "center",
        sortable: false,
        value: "userId",
      },
      { text: "ชื่อพนักงาน", align: "center", value: "fullName", width: "20%" },
      { text: "อีเมล", align: "center", value: "email" },
      { text: "เบอร์โทรศัพท์", align: "center", value: "tel" },
      {
        text: "ตำแหน่ง",
        align: "center",
        value: "staffPosition.name",
        width: "140px",
      },
      {
        text: "กลุ่มพนักงาน",
        align: "center",
        value: "groupName.name",
      },
      {
        text: "จัดการ",
        sortable: false,
        align: "center",
        value: "actions",
        width: "180px",
      },
    ],
    items: [],
  }),
  mounted() {
    this.$store.state.title = "จัดการข้อมูลพนักงาน";
    this.$store.state.showBackBtn = false;
    this.getPosition();
    this.get();
  },

  watch: {
    page() {
      this.search();
    },
    pageSize() {
      this.search();
    },
    name() {
      this.search();
    },
    tel() {
      this.search();
    },
    positionId() {
      this.search();
    },
  },
  methods: {
    downloadExcel() {
      this.$store.state.loading = true;
      const formData = new FormData();
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/export",
          data: formData,
          responseType: "blob",
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `staff`);
          document.body.appendChild(link);
          link.click();
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    search() {
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("tel", this.tel);
      formData.append("staffPositionId", this.positionId ?? "");
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/search",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                return {
                  id: item.id,
                  userId: item.id,
                  fullName: `${item.firstname} ${item.lastname}`,
                  email: item.email,
                  tel: item.tel,
                  staffPosition: item.staffPosition,
                  groupName: item.staffGroup,
                };
              });
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data.length
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getPosition() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/staff-position/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlPostion = resp.data.data;
          if (ddlPostion) {
            this.ddlPostion = ddlPostion.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/staff/all",
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                return {
                  id: item.id,
                  userId: item.id,
                  fullName: `${item.firstname} ${item.lastname}`,
                  email: item.email,
                  tel: item.tel,
                  staffPosition: item.staffPosition,
                  groupName: item.staffGroup,
                };
              });
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data.length
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    confirmDelete(id) {
      this.$store.state.loading = true;
      this.$root
        .appApi({ method: "DELETE", url: "admin/staff/" + id })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.$root.getSystemMessage(resp.data.message);
          }
          this.get();
        })
        .catch((err) => {
          if (err.message) {
            this.$root.getErrorSystemMessage(err.message);
          } else {
            this.$root.getErrorSystemMessage(err);
          }
        });
    },
    deleteDialog(id) {
      this.showDialog = true;
      this.deleteId = id;
    },
  },
};
</script>

<style></style>
