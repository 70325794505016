<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="py-10">
        <v-card-text class="text-center text-body-1">
          <div class="mt-3">
            <v-icon size="100" color="app-disabled-button-theme"
              >mdi-alert-circle</v-icon
            >
          </div></v-card-text
        >
        <v-card-text class="text-center text-body-1">
          <div class="mt-1">
            <span class="font--delete">ยืนยันการลบข้อมูล</span>
          </div></v-card-text
        >

        <v-card-actions class="d-flex justify-center">
          <v-btn
            dark
            rounded
            color="app-disabled-button-theme"
            class="px-15 py-6 mr-4 btn--action"
            @click="$emit('update:dialog', false)"
            >ยกเลิก</v-btn
          >
          <v-btn
            color="app-theme"
            dark
            rounded
            class="px-15 py-6 btn--action"
            @click="deleteEntity()"
            >ตกลง</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.btn--action {
  box-shadow: 1px 10px 20px 13px rgba(0, 0, 0, 0.02);
}
.font--delete {
  font-size: 30px !important;
  font-weight: bold !important;
  color: #000000 !important;
}
</style>
<script>
export default {
  name: "DialogDelete",
  props: ["dialog", "deleteId"],
  methods: {
    deleteEntity() {
      this.$emit("update:dialog", false);
      this.$parent.confirmDelete(this.deleteId);
    },
  },
};
</script>

<style></style>
