var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"my-1 mb-5"},[_c('v-col',{staticClass:"px-15",attrs:{"cols":"9"}}),_c('v-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"py-6",staticStyle:{"text-transform":"none !important"},attrs:{"to":{ name: 'Zone.Add' },"rounded":"","color":"app-theme","dark":"","width":"200px"}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('b',[_vm._v(" เพิ่ม Zone")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"page":_vm.page,"items-per-page":_vm.pageSize,"header-props":{
      sortIcon: 'mdi-menu-up',
    },"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      'items-per-page-options': _vm.rowsPerPage,
    }},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none mr-3",attrs:{"to":{ name: 'Zone.Edit', params: { id: item.id } }}},[_c('v-btn',{attrs:{"dark":"","color":"app-theme"}},[_c('b',[_vm._v("แก้ไข")])])],1),_c('v-btn',{staticClass:"btn-p-10",attrs:{"dark":"","color":"app-danger","min-width":"20"},on:{"click":function($event){return _vm.deleteDialog(item.id)}}},[_c('v-icon',{attrs:{"color":"app-primary"}},[_vm._v("mdi-trash-can")])],1)]}},{key:"footer",fn:function(){return undefined},proxy:true}])}),_c('DialogDelete',{attrs:{"dialog":_vm.showDialog,"deleteId":_vm.deleteId},on:{"update:dialog":function($event){_vm.showDialog=$event}}}),_c('pagination',{ref:"Pagination",attrs:{"page":_vm.page,"page-size":_vm.pageSize},on:{"changePage":function($event){_vm.page = $event},"changePageSize":function($event){_vm.pageSize = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }