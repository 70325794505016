<template>
  <v-app id="app">
    <v-app-bar
      prominent
      app
      clipped-left
      justify="center"
      v-if="$store.getters.isLoggedIn"
      color="white"
    >
      <v-container fluid>
        <v-row align="center">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.xsOnly"
            @click.stop="drawer = !drawer"
          />
          <v-img
            src="@/assets/ic_logo_menu.svg"
            contain
            max-height="120"
            max-width="20%"
          ></v-img>

          <div class="ml-2" v-if="$store.getters.showBackBtn">
            <v-toolbar-title>
              <v-btn
                @click="backTo($store.getters.isBackToName)"
                class="pa-0 ma-0"
                style="min-width: 32px; height: 28px"
                color="white"
              >
                <v-icon color="red">mdi-arrow-left</v-icon>
              </v-btn>
              <span justify="start" class="pl-5">
                <b>{{ $store.getters.title }}</b>
              </span></v-toolbar-title
            >
          </div>
          <div v-else>
            <v-toolbar-title>
              <span justify="start" class="pl-5" style="font-size: 40px">
                {{ $store.getters.title }}
              </span></v-toolbar-title
            >
          </div>
          <v-spacer></v-spacer>
          <div class="pa-2">
            <v-avatar size="64">
              <img src="@/assets/ic_logo_menu.svg" />
            </v-avatar>
          </div>
          <v-menu bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="btn-without-outline"
                color="white"
                v-bind="attrs"
                v-on="on"
              >
                <span
                  class="subtitle-1 mx-4"
                  style="font-size: 32px; color: #041e41; font-weight: bold"
                >
                  {{ $store.state.fullName }}
                </span>
                <v-icon large color="app-theme">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="profile()">
                <v-list-item-title color="primary"
                  ><b>โปรไฟล์ส่วนตัว</b>
                </v-list-item-title>
              </v-list-item>
              <v-divider v-if="!$store.getters.isRootAdmin"></v-divider>
              <v-list-item @click="changePassword()">
                <v-list-item-title><b>เปลี่ยนรหัสผ่าน</b> </v-list-item-title>
              </v-list-item>
              <v-divider v-if="!$store.getters.isRootAdmin"></v-divider>
              <v-list-item @click="dlgLogout = true">
                <v-list-item-title><b>ออกจากระบบ</b> </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
      width="20%"
      dark
      color="app-theme"
      v-if="$store.getters.isLoggedIn"
      v-model="drawer"
      clipped
      hide-overlay
      app
      :permanent="!$vuetify.breakpoint.xsOnly"
    >
      <v-list
        dark
        v-if="
          $store.getters.isRootAdmin || $store.state.isRootAdminMenu == true
        "
      >
        <template v-for="(item, idx) in menuRootAdmin">
          <v-list-group
            v-if="item.items"
            :key="item.title"
            :group="item.group"
            active-class="white--text app-active-nav-bar "
            :class="idx === 0 ? 'r-mt-8' : ''"
            :to="{ name: item.route || 'Abort' }"
          >
            <template v-slot:activator>
              <v-list-item-icon class="mx-0 mr-4" v-if="item.isIcon">
                <v-icon color="white" class="nav-icon-w-sm">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="nav-md-font"
                  v-text="item.title"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="{ name: child.route || 'Abort' }"
              active-class="white--text"
              :disabled="!child.route"
              link
            >
              <v-list-item-content class="ml-16">
                <v-list-item-title class="nav-md-font" v-if="child.badge">
                  <v-badge
                    tile
                    color="red"
                    inline
                    class="mt-0"
                    :content="child.badge"
                  >
                    {{ child.title }}
                  </v-badge>
                </v-list-item-title>
                <v-list-item-title
                  class="nav-md-font"
                  v-else
                  v-text="child.title"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            link
            :key="item.title"
            :to="{ name: item.route || 'Abort' }"
            active-class="white--text"
            :disabled="!item.route"
            dark
          >
            <v-list-item-icon class="mx-0 mr-4" v-if="item.isIcon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="nav-md-font"
                v-text="item.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-list dark v-else>
        <template v-for="(item, idx) in menuAdmin">
          <v-list-group
            v-if="item.items"
            :key="item.title"
            :group="item.group"
            active-class="white--text app-active-nav-bar "
            :class="idx === 0 ? 'r-mt-8' : ''"
            :to="{ name: item.route || 'Abort' }"
          >
            <template v-slot:activator>
              <v-list-item-icon class="mx-0 mr-4" v-if="item.isIcon">
                <v-icon color="white" class="nav-icon-w-sm">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="nav-md-font"
                  v-text="item.title"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="{ name: child.route || 'Abort' }"
              active-class="white--text"
              :disabled="!child.route"
              link
            >
              <v-list-item-content class="ml-16">
                <v-list-item-title class="nav-md-font" v-if="child.badge">
                  <v-badge
                    tile
                    color="red"
                    inline
                    class="mt-0"
                    :content="child.badge"
                  >
                    {{ child.title }}
                  </v-badge>
                </v-list-item-title>
                <v-list-item-title
                  class="nav-md-font"
                  v-else
                  v-text="child.title"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            link
            :key="item.title"
            :to="{ name: item.route || 'Abort' }"
            active-class="white--text app-active-nav-bar"
            :disabled="!item.route"
            dark
          >
            <v-list-item-icon class="mx-0 mr-4" v-if="item.isIcon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="nav-md-font"
                v-text="item.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <template v-slot:append>
        <v-list-item @click="dlgLogout = true" v-if="$store.state.isAdmin">
          <v-list-item-icon class="mr-4">
            <!-- <v-icon>mdi-logout</v-icon> -->
            <v-img
              class="mr-2 mb-2"
              src="@/assets/icons/ic_logout.svg"
              contain
              width="20"
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>ออกจากระบบ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <v-overlay :value="$store.state.loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog :value="$store.state.dialog.state" persistent max-width="500">
      <v-card class="py-10">
        <v-card-text class="text-center text-body-1">
          <v-icon
            v-if="$store.state.dialog.type == 'error'"
            color="#FF1047"
            size="100"
            >mdi-close-circle</v-icon
          >
          <v-icon
            v-if="$store.state.dialog.type == 'success'"
            color="#20AB2C"
            size="100"
          >
            mdi-check-circle
          </v-icon>
          <div
            class="mt-3"
            v-html="$store.state.dialog.message"
            style="font-weight: bold; color: black"
          ></div
        ></v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="app-theme"
            dark
            rounded
            class="px-10 py-6"
            @click="onClickButton()"
            >ตกลง</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgLogout" persistent max-width="600">
      <!-- <template v-slot:activator="{ on, attrs }"> </template> -->
      <v-card class="py-10">
        <v-card-text class="text-center text-body-1"> </v-card-text>
        <v-card-text class="text-center text-body-1">
          <div class="mt-1">
            <span class="font--delete">ยืนยันการออกจากระบบ</span>
          </div></v-card-text
        >

        <v-card-actions class="d-flex justify-center">
          <v-btn
            dark
            rounded
            color="app-disabled-button-theme"
            class="px-15 py-6 mr-4 btn--action"
            @click="dlgLogout = false"
            >ยกเลิก</v-btn
          >
          <v-btn
            color="app-theme"
            dark
            rounded
            class="px-15 py-6 btn--action"
            @click="logout()"
            >ตกลง</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<style scoped>
.btn--action {
  box-shadow: 1px 10px 20px 13px rgba(0, 0, 0, 0.02) !important;
}
.font--delete {
  font-size: 30px !important;
  font-weight: bold !important;
  color: #000000 !important;
}
</style>
<style>
@import "assets/fonts/stylesheet.css";
@import "assets/app.css";
.v-list-item__icon .mdi:before,
.mdi-set {
  color: #ffffff !important;
  font-size: 20px !important;
}
.nav-md-font {
  font-size: 20px !important;
}
</style>
<script>
export default {
  name: "App",

  components: {},
  mounted() {
    if (this.$route.name) {
      const routeName = this.$route.name.replace(/\..*/, "");
      if (this.$store.getters.isRootAdmin) {
        let routeAdmin = true;
        this.menuAdmin.map((item) => {
          if (item.route) {
            if (item.route.replace(/\..*/, "") == routeName) {
              routeAdmin = false;
            }
          }
          if (item.items) {
            if (item.items) {
              item.items.map((i) => {
                if (i.route.replace(/\..*/, "") == routeName) {
                  routeAdmin = false;
                }
              });
            }
          }
        });
        if (routeAdmin === false) {
          this.$router.push("/abort");
        }
      } else {
        let routeAdmin = true;
        this.menuRootAdmin.map((item) => {
          if (item.route) {
            if (item.route.replace(/\..*/, "") == routeName) {
              routeAdmin = false;
            }
          }
          if (item.items) {
            item.items.map((i) => {
              if (i.route.replace(/\..*/, "") == routeName) {
                routeAdmin = false;
              }
            });
          }
        });
        if (routeAdmin === false) {
          this.$router.push("/abort");
        }
      }
    } else {
      this.$router.push("/abort");
    }
  },
  data() {
    return {
      dlgLogout: false,
      drawer: null,
      menuAdmin: [
        {
          title: "จัดการ Labor, Driver Leader",
          group:
            "labor/employee_group|labor/employee_detail|labor/record_of_work|labor/record_leave_of_work|labor/schedule",
          items: [
            {
              title: "จัดการกลุ่มพนักงาน",
              route: "EmployeeGroup.Index",
            },
            {
              title: "จัดการข้อมูลพนักงาน",
              route: "EmployeeDetail.Index",
            },
            {
              title: "บันทึกการมาทำงานของพนักงาน",
              route: "RecordOfWork.Index",
            },
            {
              title: "บันทึกการลาของพนักงาน",
              route: "RecordLeaveOfWork.Index",
            },
            {
              title: "ตารางการทำงานของกำลังพล",
              route: "LaborSchedule.Index",
            },
          ],
          isIcon: true,
          icon: "mdi-account-group",
        },
        {
          title: "รับรถเข้าคลังจากลูกค้า",
          route: "ImportVehicle.Index",
          isIcon: true,
          icon: "mdi-cart-arrow-down",
        },
        {
          title: "ส่งออกรถ (ขาย)",
          route: "ExportVehicle.Index",
          isIcon: true,
          icon: "mdi-car-select",
        },
        {
          title: "ดูสต๊อกและสถานะรถในคลัง",
          route: "Stock.Index",
          isIcon: true,
          icon: "mdi-package-variant",
        },
        {
          title: "รายงาน",
          group: "report/import_vehicle|report/export_vehicle",
          items: [
            {
              title: "รายงานการนำรถเข้าคลัง",
              route: "ReportImportVehicle.Index",
            },
            {
              title: "รายงานการนำรถออกคลัง",
              route: "ReportExportVehicle.Index",
            },
          ],
          isIcon: true,
          icon: "mdi-chart-bar",
        },
        {
          title: "ตั้งค่า",
          group: "notification|leave_type|performance|close_job",
          items: [
            {
              title: "ตั้งค่าการแจ้งเตือน",
              route: "SettingNotification.Index",
            },
            {
              title: "ตั้งค่าประเภทการลา",
              route: "SettingLeaveType.Index",
            },
            {
              title: "ตั้งค่าประสิทธิภาพ",
              route: "SettingPerformance.Index",
            },
            {
              title: "ตั้งค่าการปิด Job",
              route: "SettingCloseJob.Index",
            },
          ],
          isIcon: true,
          icon: "mdi-package-variant",
        },
      ],
      menuRootAdmin: [
        {
          title: "จัดการผู้ดูแลระบบ",
          group: "admin|position",
          items: [
            {
              title: "จัดการตำแหน่งและสิทธิ์การใช้งานระบบ",
              route: "Position.Index",
            },
            {
              title: "จัดการข้อมูลผู้ดูแลระบบ",
              route: "Admin.Index",
            },
          ],
          isIcon: true,
          icon: "mdi-account-check",
        },
        {
          title: "จัดการผู้บริหาร",
          route: "Executive.Index",
          isIcon: true,
          icon: "mdi-account-tie",
        },
        {
          title: "จัดการลูกค้า",
          group: "customer/company|customer",
          items: [
            {
              title: "จัดการบริษัทลูกค้า",
              route: "CustomerCompany.Index",
            },
            {
              title: "จัดการข้อมูลลูกค้า",
              route: "Customer.Index",
            },
          ],

          isIcon: true,
          icon: "mdi-account-edit",
        },
        {
          title: "จัดการข้อมูลรถ",
          group:
            "vehicle/brand|vehicle/type|vehicle/model|vehicle/msc_mode|vehicle/color",
          items: [
            {
              title: "จัดการยี่ห้อรถ (Brand)",
              route: "VehicleBrand.Index",
            },
            {
              title: "จัดการประเภทรถ",
              route: "VehicleType.Index",
            },
            {
              title: "จัดการ Model รถ",
              route: "VehicleModel.Index",
            },
            {
              title: "จัดการ Msc Mode",
              route: "VehicleMscMode.Index",
            },
            {
              title: "จัดการสีรถ",
              route: "VehicleColor.Index",
            },
          ],
          isIcon: true,
          icon: "mdi-car",
        },
        {
          title: "จัดการข้อมูล Yard",
          group: "yard|phase|zone",
          items: [
            {
              title: "จัดการข้อมูล Yard",
              route: "Yard.Index",
            },
            {
              title: "จัดการข้อมูล Phase",
              route: "Phase.Index",
            },
            {
              title: "จัดการข้อมูล Zone",
              route: "Zone.Index",
            },
          ],

          isIcon: true,
          icon: "mdi-account-supervisor",
        },
      ],
      menus: [],
    };
  },
  methods: {
    onClickButton() {
      this.$store.state.dialog.state = false;
      if (this.$store.state.navigateTo) {
        this.$router.push(this.$store.state.navigateToRoute);
      }
    },
    logout: function () {
      this.dlgLogout = false;
      this.$store.dispatch("logout").then(() => {
        location.reload();
      });
    },
    profile() {
      this.$router.push("/profile");
    },
    changePassword() {
      this.$router.push("/change_password");
    },
    backTo(routeName) {
      this.$router.push(`${routeName}`);
    },
  },
};
</script>
