<template>
  <div class="my-10">
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title">
          <b>User ID</b>
        </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input--disabled"
            v-model="id"
            rounded
            hide-details="false"
            :disabled="true"
            required
            background-color="app-disabled-theme"
            height="48"
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> <b>ชื่อ</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="firstName"
            color="app-theme"
            placeholder="กรุณาระบุชื่อ"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุชื่อ',
              (v) =>
                /^[A-Za-zก-๏]+$/.test(v.trim()) ||
                'ใส่ได้เฉพาะอักษรภาษาไทยและภาษาอังกฤษ เท่านั้น',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>นามสกุล</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="lastName"
            color="app-theme"
            placeholder="กรุณาระบุนามสกุล"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุนามสกุล',
              (v) =>
                /^[A-Za-zก-๏]+$/.test(v.trim()) ||
                'ใส่ได้เฉพาะอักษรภาษาไทยและภาษาอังกฤษ เท่านั้น',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>อีเมล</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="email"
            color="app-theme"
            placeholder="อีเมล"
            outlined
            hide-details="auto"
            :rules="[(v) => /.+@.+/.test(v) || 'กรุณาระบุอีเมล']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>เบอร์โทรศัพท์</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="tel"
            color="app-theme"
            placeholder="เบอร์โทรศัพท์"
            outlined
            hide-details="auto"
            v-mask="telMask"
            :rules="[
              (v) =>
                v.length == 12 || 'กรุณาระบุเบอร์โทรศัพท์ให้ครบทั้ง 10 ตัว',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>ตำแหน่ง</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select select--icon--center"
            hide-details="auto"
            color="app-theme"
            placeholder="กรุณาเลือกตำแหน่ง"
            v-model="positionId"
            :items="ddlPostion"
            outlined
            rounded
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>Yard</b> </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select select--icon--center"
            hide-details="auto"
            color="app-theme"
            placeholder="กรุณาเลือก Yard"
            v-model="yardId"
            :items="ddlYard"
            outlined
            rounded
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"><b>ชื่อผู้ใช้</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="username"
            color="app-theme"
            placeholder="ชื่อผู้ใช้"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุชื่อผู้ใช้']"
            autocomplete="off"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" v-if="isAdd">
        <v-col cols="12" sm="3" class="pl--title"><b>รหัสผ่าน</b> </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="password"
            color="app-theme"
            placeholder="รหัสผ่าน"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุรหัสผ่าน',
              (v) => (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6',
              (v) =>
                /[A-Za-z]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
              (v) =>
                /[0-9]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
            ]"
            type="password"
            autocomplete="new-password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/admin/management/')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
      <v-row no-gutters class="my-5" v-if="!isAdd">
        <v-col cols="12" sm="3" class="mx-auto d-flex justify-center">
          <router-link
            :to="{
              name: 'Admin.ChangePassword',
              params: { id: $route.params.id },
            }"
            class="text-decoration-none mx-4 my-5"
          >
            <v-btn rounded dark color="app-danger" class="px-15 py-6"
              ><b>เปลี่ยนรหัสผ่าน</b></v-btn
            >
          </router-link>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import helper from "@/helpers/helper";
export default {
  data() {
    return {
      isAdd: this.$route.name === "Admin.Add",
      telMask: "###-###-####",

      id: `xxxxx`,
      firstName: ``,
      lastName: ``,
      email: ``,
      tel: ``,
      username: ``,
      password: ``,
      positionId: ``,
      yardId: ``,

      ddlPostion: [],
      ddlYard: [],
    };
  },
  mounted() {
    this.getYard();
    this.getPostion();
    this.$store.state.title = "เพิ่ม Admin";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/admin/management";
    if (this.$route.name === "Admin.Edit") {
      this.get();
      this.$store.state.title = "แก้ไข Admin";
    }
  },
  methods: {
    getYard() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlYard = resp.data.data;
          if (ddlYard) {
            this.ddlYard = ddlYard.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getPostion() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/position/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlPostion = resp.data.data;
          if (ddlPostion) {
            this.ddlPostion = ddlPostion.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/admin/" + this.$route.params.id,
        })
        .then((resp) => {
          const entity = resp.data.data;
          let tel = entity.tel;
          if (tel.length == 10) {
            tel = helper.insertString(entity.tel.toString(), 3, "-");
            tel = helper.insertString(tel.toString(), 7, "-");
          }
          this.id = entity.id.toString().padStart(5, "0");
          this.firstName = entity.firstname;
          this.lastName = entity.lastname;
          this.tel = tel;
          this.email = entity.email;
          this.username = entity.username;
          this.positionId = entity.adminPosition ? entity.adminPosition.id : "";
          if (entity.yard) {
            this.yardId = entity.yard.id;
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    add() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("firstName", this.firstName.trim());
      formData.append("lastName", this.lastName.trim());
      formData.append("email", this.email);
      formData.append("username", this.username);
      formData.append("password", this.password);
      formData.append("tel", this.tel);
      formData.append("adminPositionId", this.positionId);
      formData.append("yardId", this.yardId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/admin",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(resp.data.message, "/admin/management");
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    update() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("firstName", this.firstName.trim());
      formData.append("lastName", this.lastName.trim());
      formData.append("email", this.email);
      formData.append("username", this.username);
      formData.append("password", this.password);
      formData.append("tel", this.tel);
      formData.append("adminPositionId", this.positionId);
      formData.append("yardId", this.yardId);
      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/admin/" + this.$route.params.id,
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(resp.data.message, "/admin/management");
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
