<template>
  <div class="my-10">
    <v-form @submit.prevent="update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"
          ><req></req><b>รหัสผ่านเก่า</b>
        </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="oldPassword"
            color="app-theme"
            placeholder="ระบุรหัสผ่านเก่า"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุรหัสผ่าน',
              (v) => (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6',
              (v) =>
                /[A-Za-z]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
              (v) =>
                /[0-9]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
            ]"
            type="password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"
          ><req></req><b>รหัสผ่านใหม่</b>
        </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="newPassword"
            color="app-theme"
            placeholder="ระบุรหัสผ่านใหม่"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุรหัสผ่าน',
              (v) => (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6',
              ,
              (v) =>
                v === (confirmNewPassword || '') ||
                'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน',
              (v) =>
                /[A-Za-z]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
              (v) =>
                /[0-9]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
            ]"
            type="password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"
          ><req></req><b>ยืนยันระบุรหัสผ่านใหม่</b>
        </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="confirmNewPassword"
            color="app-theme"
            placeholder="ระบุยืนยันรหัสผ่านใหม่"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุยืนยันรหัสผ่าน',
              (v) => (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6',
              ,
              (v) =>
                v === (newPassword || '') ||
                'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน',
              (v) =>
                /[A-Za-z]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
              (v) =>
                /[0-9]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
            ]"
            type="password"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <router-link :to="{ name: 'Admin' }" class="text-decoration-none">
            <v-btn
              rounded
              color="app-disabled-button-theme"
              dark
              class="mr-4 my-2 px-8 py-6"
              >ยกเลิก</v-btn
            >
          </router-link>
          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  data: () => ({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  }),
  mounted() {
    if (this.$store.state.isRootAdmin) {
      this.$store.state.title = "เปลี่ยนรหัสผ่าน";
    } else {
      this.$store.state.title = "จัดการโปรไฟล์ส่วนตัว";
    }

    this.$store.state.showBackBtn = true;
  },
  watch: {
    newPassword() {
      if (!this.$refs.form.validate()) return;
    },
    confirmNewPassword() {
      if (!this.$refs.form.validate()) return;
    },
  },
  methods: {
    update() {
      if (!this.$refs.form.validate()) return null;
      this.$store.state.loading = true;
      const formData = new FormData();

      formData.append("password", this.oldPassword);
      formData.append("newpassword", this.newPassword);
      this.$root
        .appApi({
          method: "PUT",
          url: "admin/profile/password",
          data: formData,
        })
        .then((resp) => {
          this.$root.getSystemMessage(resp.data.message);
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
