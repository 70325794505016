import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        "app-theme": "#203486",
        "app-light-theme": "#5293fb",
        "app-pink-theme": "#eb3b93",
        "app-danger": "#fe000d",
        "app-disabled-theme": "#eeeeee",
        "app-disabled-button-theme": "#9f9fa0",
        "app-active-nav-bar": "#c9101f",
        "app-export-theme": "#F5A000",
        "app-edit-location": "#ff6201",
      },
    },
  },
});
