<template>
  <div class="my-10">
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"> ชื่อ Yard </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="name"
            color="app-theme"
            placeholder="ระบุชื่อ Yard"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุชื่อ Yard']"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title">
          เลขประจำตัวผู้เสียภาษี
        </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="taxId"
            color="app-theme"
            placeholder="เลขประจำตัวผู้เสียภาษี"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุเลขประจำตัวผู้เสียภาษี',
              (v) =>
                /^[0-9]+$/.test(v) ||
                'กรุณาระบุเลขประจำตัวผู้เสียภาษีเป็นตัวเลขเท่านั้น',
              (v) =>
                v.toString().length == 13 ||
                'กรุณาระบุเลขประจำตัวผู้เสียภาษีให้ครบทั้ง 13 หลัก',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="start">
        <v-col cols="12" sm="3" class="pl--title"> ที่อยู่ที่ตั้ง </v-col>
        <v-col cols="10" sm="8">
          <v-row>
            <v-col>
              เลขที่
              <v-text-field
                class="mt-2 pl--input"
                v-model="addr1"
                color="app-theme"
                placeholder="เลขที่"
                outlined
                hide-details="auto"
                :rules="[
                  (v) =>
                    /^[0-9/]+$/.test(v) || 'กรุณาระบุเลขที่เป็นตัวเลขเท่านั้น',
                ]"
              ></v-text-field
            ></v-col>
            <v-col
              >หมู่<v-text-field
                class="mt-2 pl--input"
                v-model="addr2"
                color="app-theme"
                placeholder="หมู่"
                outlined
                hide-details="auto"
                :rules="[
                  (v) =>
                    /^[0-9]+$/.test(v) || 'กรุณาระบุหมู่เป็นตัวเลขเท่านั้น',
                ]"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col>
              จังหวัด
              <v-select
                class="mt-2 select--icon--center placeholder--black"
                hide-details="auto"
                color="app-theme"
                placeholder="จังหวัด"
                v-model="addrChangwatId"
                :items="ddlProvince"
                outlined
                rounded
                ><template v-slot:append>
                  <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col
              >เขต/อำเภอ<v-select
                class="mt-2 select--icon--center placeholder--black"
                hide-details="auto"
                color="app-theme"
                placeholder="เขต/อำเภอ"
                v-model="addrAmphoeId"
                :items="ddlDistrict"
                outlined
                rounded
                ><template v-slot:append>
                  <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                </template></v-select
              ></v-col
            >
          </v-row>
          <v-row>
            <v-col
              >แขวง/ตำบล
              <v-select
                class="mt-2 select--icon--center placeholder--black"
                hide-details="auto"
                color="app-theme"
                placeholder="แขวง/ตำบล"
                v-model="addrTambonId"
                :items="ddlSubDistrict"
                outlined
                rounded
                ><template v-slot:append>
                  <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                </template></v-select
              >
            </v-col>
            <v-col
              >รหัสไปรษณีย์<v-text-field
                class="mt-2 pl--input"
                v-model="addrZipCode"
                color="app-theme"
                placeholder="รหัสไปรษณีย์"
                outlined
                hide-details="auto"
                :rules="[
                  (v) =>
                    /^[0-9]+$/.test(v) ||
                    'กรุณาระบุรหัสไปรษณีย์เป็นตัวเลขเท่านั้น',
                  (v) =>
                    (v && v.length == 5) || 'รหัสไปรษณีย์ต้องมี 5 ตัวเท่านั้น',
                ]"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title mt-3">
          ที่อยู่ออกใบกำกับภาษี
        </v-col>
        <v-col cols="10" sm="8">
          <v-checkbox
            class="pl--checkbox"
            v-model="isSameAddr"
            style="min-width: 300px"
            color="app-theme"
            hide-details="auto"
            label="ที่เดียวกับที่อยู่ที่ตั้ง"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row align="start" v-if="isAdd">
        <v-col cols="12" sm="3" class="pl--title"> </v-col>
        <v-col cols="10" sm="8">
          <v-row>
            <v-col>
              เลขที่
              <v-text-field
                class="mt-2 pl--input"
                v-model="addrTax1"
                color="app-theme"
                placeholder="เลขที่"
                outlined
                hide-details="auto"
                :rules="[
                  (v) =>
                    /^[0-9/]+$/.test(v) || 'กรุณาระบุเลขที่เป็นตัวเลขเท่านั้น',
                ]"
                :disabled="isSameAddr"
                :background-color="isSameAddr ? '#eeeeee' : ''"
              ></v-text-field
            ></v-col>
            <v-col
              >หมู่<v-text-field
                class="mt-2 pl--input"
                v-model="addrTax2"
                color="app-theme"
                placeholder="หมู่"
                outlined
                hide-details="auto"
                :rules="[
                  (v) =>
                    /^[0-9]+$/.test(v) || 'กรุณาระบุหมู่เป็นตัวเลขเท่านั้น',
                ]"
                :disabled="isSameAddr"
                :background-color="isSameAddr ? '#eeeeee' : ''"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col>
              จังหวัด
              <v-select
                class="mt-2 select--icon--center placeholder--black"
                hide-details="auto"
                color="app-theme"
                placeholder="จังหวัด"
                v-model="addrTaxChangwatId"
                :items="ddlProvince"
                outlined
                rounded
                :rules="[(v) => !!v || 'กรุณาเลือกจังหวัด']"
                :disabled="isSameAddr"
                :background-color="isSameAddr ? '#eeeeee' : ''"
                ><template v-slot:append>
                  <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col
              >เขต/อำเภอ<v-select
                class="mt-2 select--icon--center placeholder--black"
                hide-details="auto"
                color="app-theme"
                placeholder="เขต/อำเภอ"
                v-model="addrTaxAmphoeId"
                :items="ddlTaxDistrict"
                outlined
                rounded
                :rules="[(v) => !!v || 'กรุณาเลือกเขต/อำเภอ']"
                :disabled="isSameAddr"
                :background-color="isSameAddr ? '#eeeeee' : ''"
                ><template v-slot:append>
                  <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                </template></v-select
              ></v-col
            >
          </v-row>
          <v-row>
            <v-col>
              แขวง/ตำบล
              <v-select
                class="mt-2 select--icon--center placeholder--black"
                hide-details="auto"
                color="app-theme"
                placeholder="แขวง/ตำบล"
                v-model="addrTaxTambonId"
                :items="ddlTaxSubDistrict"
                outlined
                rounded
                :rules="[(v) => !!v || 'กรุณาเลือกแขวง/ตำบล']"
                :disabled="isSameAddr"
                :background-color="isSameAddr ? '#eeeeee' : ''"
                ><template v-slot:append>
                  <v-icon large color="app-theme">mdi-chevron-down</v-icon>
                </template></v-select
              ></v-col
            >
            <v-col
              >รหัสไปรษณีย์<v-text-field
                class="mt-2 pl--input"
                v-model="addrTaxZipCode"
                color="app-theme"
                placeholder="รหัสไปรษณีย์"
                outlined
                hide-details="auto"
                :rules="[
                  (v) =>
                    (v && v.length == 5) || 'รหัสไปรษณีย์ต้องมี 5 ตัวเท่านั้น',
                  (v) =>
                    /^[0-9]+$/.test(v) ||
                    'กรุณาระบุรหัสไปรษณีย์เป็นตัวเลขเท่านั้น',
                ]"
                :disabled="isSameAddr"
                :background-color="isSameAddr ? '#eeeeee' : ''"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="center" class="mt-8">
        <v-col cols="12" sm="3" class="pl--title"> เบอร์โทรศัพท์ </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="tel"
            color="app-theme"
            placeholder="เบอร์โทรศัพท์"
            outlined
            hide-details="auto"
            v-mask="'###-###-####'"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-8">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/yard/management')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<style scoped>
.placeholder--black >>> input::placeholder {
  color: black;
}
</style>
<script>
export default {
  data() {
    return {
      isAdd: this.$route.name === "Yard.Add",
      tel: "",
      name: ``,
      taxId: "",
      isSameAddr: false,

      addr1: ``,
      addr2: ``,
      addrChangwatId: ``,
      addrAmphoeId: ``,
      addrTambonId: ``,
      addrZipCode: ``,

      addrTax1: ``,
      addrTax2: ``,
      addrTaxChangwatId: ``,
      addrTaxAmphoeId: ``,
      addrTaxTambonId: ``,
      addrTaxZipCode: ``,

      ddlProvince: [],

      ddlDistrict: [],
      ddlSubDistrict: [],

      ddlTaxDistrict: [],
      ddlTaxSubDistrict: [],

      province: [],

      district: [],
      subDistrict: [],
      taxDistrict: [],
      taxSubDistrict: [],
    };
  },
  async mounted() {
    this.$store.state.title = "เพิ่ม Yard";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/yard/management";

    if (this.$route.name === "Yard.Edit") {
      await this.getProvince();
      await this.get();
      this.$store.state.title = "แก้ไข Yard";
    } else {
      this.getProvince();
    }
  },
  watch: {
    addrChangwatId() {
      if (this.addrChangwatId === undefined) return;
      this.district = this.province.find((w) => w.id === this.addrChangwatId);
      if (this.district) {
        this.ddlDistrict = this.district.amphoes.map((item) => {
          return {
            text: item.nameTh,
            value: item.id,
          };
        });
      }
      this.ddlSubDistrict = [];
      this.sameAddr();
    },
    addrAmphoeId() {
      if (this.addrAmphoeId === undefined) return;
      if (this.district) {
        this.subDistrict = this.district.amphoes.find(
          (w) => w.id === this.addrAmphoeId
        );
        this.ddlSubDistrict = this.subDistrict.tambons.map((item) => {
          return {
            text: item.nameTh,
            value: item.id,
          };
        });
      }
      this.addrZipCode = "";
      this.sameAddr();
    },
    addrTambonId() {
      const addrZipCode = this.subDistrict.tambons.find(
        (w) => w.id === this.addrTambonId
      );
      if (addrZipCode) {
        this.addrZipCode = addrZipCode.zipCode;
      }
      this.sameAddr();
    },
    addrTaxChangwatId() {
      if (this.addrTaxChangwatId === undefined) return;
      this.taxDistrict = this.province.find(
        (w) => w.id === this.addrTaxChangwatId
      );
      if (this.taxDistrict) {
        this.ddlTaxDistrict = this.taxDistrict.amphoes.map((item) => {
          return {
            text: item.nameTh,
            value: item.id,
          };
        });
      }
      this.ddlTaxSubDistrict = [];
    },
    addrTaxAmphoeId() {
      if (this.addrTaxAmphoeId === undefined) return;
      if (this.taxDistrict) {
        this.taxSubDistrict = this.taxDistrict.amphoes.find(
          (w) => w.id === this.addrTaxAmphoeId
        );
        this.ddlTaxSubDistrict = this.taxSubDistrict.tambons.map((item) => {
          return {
            text: item.nameTh,
            value: item.id,
          };
        });
      }

      this.addrTaxZipCode = "";
    },
    addrTaxTambonId() {
      const addrTaxZipCode = this.taxSubDistrict.tambons.find(
        (w) => w.id === this.addrTaxTambonId
      );
      if (addrTaxZipCode) {
        this.addrTaxZipCode = addrTaxZipCode.zipCode;
      }
    },
    isSameAddr() {
      this.sameAddr();
    },
    addr1() {
      this.sameAddr();
    },
    addr2() {
      this.sameAddr();
    },
  },
  methods: {
    sameAddr() {
      if (this.isSameAddr == true) {
        this.addrTax1 = this.addr1;
        this.addrTax2 = this.addr2;
        this.addrTaxChangwatId = this.addrChangwatId;
        this.addrTaxAmphoeId = this.addrAmphoeId;
        this.addrTaxTambonId = this.addrTambonId;
        this.addrTaxZipCode = this.addrZipCode;
      }
    },
    async getProvince() {
      this.$store.state.loading = true;
      await this.$root
        .appApi({ url: "/data/provinces" })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            this.province = entity;
            this.ddlProvince = entity.map((item) => {
              return {
                text: item.nameTh,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    async get() {
      this.$store.state.loading = true;
      await this.$root
        .appApi({ url: `admin/yard/${this.$route.params.id}` })
        .then((resp) => {
          const entity = resp.data.data;
          this.tel = entity.tel;
          this.name = entity.name;
          this.taxId = entity.taxId;
          this.isSameAddr = entity.isSameAddr;

          this.addr1 = entity.addr1;
          this.addr2 = entity.addr2;
          this.addrChangwatId = entity.addrChangwat.id;
          this.district = this.province.find(
            (w) => w.id === entity.addrChangwat.id
          );
          this.ddlDistrict = this.district.amphoes.map((item) => {
            return {
              text: item.nameTh,
              value: item.id,
            };
          });

          this.addrAmphoeId = entity.addrAmphoe.id;

          this.subDistrict = this.district.amphoes.find(
            (w) => w.id === entity.addrAmphoe.id
          );
          this.ddlSubDistrict = this.subDistrict.tambons.map((item) => {
            return {
              text: item.nameTh,
              value: item.id,
            };
          });

          this.addrTambonId = entity.addrTambon.id;
          this.addrZipCode = entity.addrZipCode;

          this.addrTax1 = entity.addrTax1;
          this.addrTax2 = entity.addrTax2;
          this.addrTaxChangwatId = entity.addrTaxChangwat.id;

          this.taxDistrict = this.province.find(
            (w) => w.id === entity.addrTaxChangwat.id
          );
          this.ddlTaxDistrict = this.taxDistrict.amphoes.map((item) => {
            return {
              text: item.nameTh,
              value: item.id,
            };
          });

          this.addrTaxAmphoeId = entity.addrTaxAmphoe.id;
          this.taxSubDistrict = this.taxDistrict.amphoes.find(
            (w) => w.id === entity.addrTaxAmphoe.id
          );
          this.addrTaxTambonId = entity.addrTaxTambon.id;
          this.addrTaxZipCode = entity.addrTaxZipCode;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },

    add() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();

      formData.append("name", this.name);
      formData.append("taxId", this.taxId);
      formData.append("tel", this.tel);

      formData.append("addr1", this.addr1);
      formData.append("addr2", this.addr2);
      formData.append("addrChangwatId", this.addrChangwatId);
      formData.append("addrAmphoeId", this.addrAmphoeId);
      formData.append("addrTambonId", this.addrTambonId);
      formData.append("addrZipCode", this.addrZipCode);

      formData.append("isSameAddr", this.isSameAddr);

      formData.append("addrTax1", this.addrTax1);
      formData.append("addrTax2", this.addrTax2);
      formData.append("addrTaxChangwatId", this.addrTaxChangwatId);
      formData.append("addrTaxAmphoeId", this.addrTaxAmphoeId);
      formData.append("addrTaxTambonId", this.addrTaxTambonId);
      formData.append("addrTaxZipCode", this.addrTaxZipCode);

      this.$root
        .appApi({
          method: "POST",
          url: "admin/yard/",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(resp.data.message, "/yard/management");
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    update() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();

      formData.append("id", this.$route.params.id);
      formData.append("name", this.name);
      formData.append("taxId", this.taxId);
      formData.append("tel", this.tel);

      formData.append("addr1", this.addr1);
      formData.append("addr2", this.addr2);
      formData.append("addrChangwatId", this.addrChangwatId);
      formData.append("addrAmphoeId", this.addrAmphoeId);
      formData.append("addrTambonId", this.addrTambonId);
      formData.append("addrZipCode", this.addrZipCode);

      formData.append("isSameAddr", this.isSameAddr);

      formData.append("addrTax1", this.addrTax1);
      formData.append("addrTax2", this.addrTax2);
      formData.append("addrTaxChangwatId", this.addrTaxChangwatId);
      formData.append("addrTaxAmphoeId", this.addrTaxAmphoeId);
      formData.append("addrTaxTambonId", this.addrTaxTambonId);
      formData.append("addrTaxZipCode", this.addrTaxZipCode);

      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/yard/",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(resp.data.message, "/yard/management");
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.v-text-field >>> input::-webkit-outer-spin-button,
.v-text-field >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.v-text-field >>> input[type="number"] {
  -moz-appearance: textfield;
}
</style>
