import { render, staticRenderFns } from "./StockDetail.vue?vue&type=template&id=dad2a240&scoped=true&"
import script from "./StockDetail.vue?vue&type=script&lang=js&"
export * from "./StockDetail.vue?vue&type=script&lang=js&"
import style0 from "./StockDetail.vue?vue&type=style&index=0&id=dad2a240&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dad2a240",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCol,VDataTable,VIcon,VRow,VSimpleTable})
