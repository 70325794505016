var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"section-container"},[_c('v-row',[_c('v-col',{staticClass:"left",attrs:{"cols":"4"}},[_c('span',{staticClass:"white--text login-text"},[_vm._v(" เปลี่ยนรหัสผ่าน ")])]),_c('v-col',{staticClass:"right",attrs:{"cols":"8"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-img',{attrs:{"min-width":"100","max-width":"200","src":require("@/assets/ic_logo_login.svg")}})],1),_c('v-col',{staticClass:"mb-0 pb-0 mt-5 pt-10",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',[_vm._v("ระบุรหัสผ่านใหม่ "),_c('v-text-field',{attrs:{"color":"app-theme","placeholder":"ระบุรหัสผ่านใหม่","outlined":"","hide-details":"auto","rules":[
                    function (v) { return !!v || 'กรุณาระบุรหัสผ่าน'; },
                    function (v) { return (v && v.length >= 6) ||
                      'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6'; },
                    ,
                    function (v) { return v === (_vm.confirmNewPassword || '') ||
                      'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน'; } ],"type":"password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1)],1)],1),_c('v-col',{staticClass:"mb-0 pb-0 mt-5 pt-10",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"ค"}},[_vm._v("ยืนยันระบุรหัสผ่านใหม่ "),_c('v-text-field',{attrs:{"color":"app-theme","placeholder":"ระบุยืนยันรหัสผ่านใหม่","outlined":"","hide-details":"auto","rules":[
                    function (v) { return !!v || 'กรุณาระบุยืนยันรหัสผ่าน'; },
                    function (v) { return (v && v.length >= 6) ||
                      'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6'; },
                    ,
                    function (v) { return v === (_vm.newPassword || '') ||
                      'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน'; } ],"type":"password"},model:{value:(_vm.confirmNewPassword),callback:function ($$v) {_vm.confirmNewPassword=$$v},expression:"confirmNewPassword"}})],1)],1)],1),_c('v-col',{staticClass:"my-5",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"px-15 py-6 mt-10",attrs:{"type":"submit","rounded":"","color":"app-theme","dark":""}},[_vm._v(" ตกลง ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }