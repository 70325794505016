<template>
  <v-container class="mt-10">
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"
          ><req></req><b>ตำแหน่ง</b></v-col
        >
        <v-col cols="10" sm="8">
          <v-text-field
            v-model="positionName"
            color="app-theme"
            placeholder="ระบุตำแหน่ง"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'ระบุตำแหน่ง']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="mt-4">
        <v-col cols="12" sm="3" class="pl--title py-0 mt-1"
          ><req></req><b>สิทธิ์การใช้งาน</b></v-col
        >
        <v-col cols="10" sm="8" class="pb-2">
          <div class="pb-0 mb-0">
            <v-checkbox
              class="mr-0 mt-0 pl--checkbox"
              @change="selectAll"
              v-model="selectedAll"
              label="ทั้งหมด"
              hide-details
              color="app-theme"
            ></v-checkbox>
          </div>
          <div class="v-text-field__details mt-2 ml-3" v-if="validPermission">
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">กรุณาเลือกสิทธิ์ใช้งาน</div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" class="mt-0">
        <v-col cols="12" sm="3"></v-col>
        <v-col cols="10" sm="8" class="pl-15 pt-0 mt-0">
          <div v-for="(permission, index) in permissions" :key="index">
            <v-checkbox
              class="pt-0 pl--checkbox pb-1"
              v-model="selected"
              color="app-theme"
              hide-details="auto"
              :label="permission.name"
              :value="permission.id"
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/position/management')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<style scoped></style>
<script>
export default {
  data() {
    return {
      validPermission: false,
      isAdd: this.$route.name === "Position.Add",
      positionName: "",
      selectedAll: false,
      selected: [],
      permissions: [
        {
          id: 1,
          name: "จัดการกลุ่มและข้อมูลพนักงาน",
        },
        {
          id: 2,
          name: "จัดการข้อมูลการทำงานของพนักงาน",
        },
        {
          id: 3,
          name: "จัดการข้อมูลการลาของพนักงาน",
        },
        {
          id: 4,
          name: "จัดการข้อมูลลูกค้า",
        },
        {
          id: 5,
          name: "จัดการข้อมูลรถ",
        },
        {
          id: 6,
          name: "จัดการรับเข้าคลัง",
        },
        {
          id: 7,
          name: "จัดการนำรถออกจากคลัง",
        },
        {
          id: 8,
          name: "จัดการสถานะของรถและดูสต๊อกในคลัง",
        },
        {
          id: 9,
          name: "ดูรายงานต่างๆ",
        },
        {
          id: 10,
          name: "จัดการและตั้งค่าข้อมูลต่างๆ",
        },
      ],
    };
  },
  mounted() {
    this.getSystemUses();
    this.$store.state.title = "เพิ่มตำแหน่ง";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/role/management";
    if (this.$route.name === "Position.Edit") {
      this.positionName = "Admin Edit";

      this.$store.state.title = "แก้ไขตำแหน่ง";
      this.get();
    }
  },
  watch: {
    selected() {
      this.selectedAll = this.selected.length == this.permissions.length;
      if (!(this.selected.length > 0)) {
        this.validPermission = true;
      } else {
        this.validPermission = false;
      }
    },
  },
  methods: {
    getSystemUses() {
      this.$store.state.loading = true;
      this.$root
        .appApi({ url: "admin/system-uses" })
        .then((resp) => {
          this.permissions = resp.data.data;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({ url: `admin/position/${this.$route.params.id}` })
        .then((resp) => {
          const entity = resp.data.data;
          this.positionName = entity.name;
          const adminSystemUses = resp.data.data.adminSystemUses;
          if (adminSystemUses) {
            adminSystemUses.map((item) => {
              this.selected.push(item.id);
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    selectAll(checked) {
      if (checked) {
        const permissions = this.permissions;
        permissions.forEach((element) => {
          this.selected.push(element.id);
        });
      } else {
        this.selected = [];
      }
    },
    add() {
      if (!this.$refs.form.validate()) {
        if (!(this.selected.length > 0)) {
          this.validPermission = true;
        } else {
          this.validPermission = false;
        }
        return;
      }
      const formData = new FormData();
      formData.append("name", this.positionName);
      formData.append("adminSystemUses[]", this.selected);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/position",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(resp.data.message);
            this.$router.push("/position/management");
          }
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
        });
    },
    update() {
      if (!this.$refs.form.validate()) {
        if (!(this.selected.length > 0)) {
          this.validPermission = true;
        } else {
          this.validPermission = false;
        }
        return;
      }
      const formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("name", this.positionName);
      formData.append("adminSystemUses[]", this.selected);
      this.$root
        .appApi({
          method: "PUT",
          url: "admin/position",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(resp.data.message);
            this.$router.push("/position/management");
          }
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
        });
    },
  },
};
</script>

<style></style>
