<template>
  <v-container>
    <v-row class="my-1 mb-5">
      <v-col cols="9" class="px-15"> </v-col>
      <v-col cols="3" class="text-right">
        <v-btn
          class="py-6"
          :to="{ name: 'VehicleMscMode.Add' }"
          rounded
          color="app-theme"
          dark
          style="text-transform: none !important"
          width="220px"
        >
          <v-icon>mdi-plus</v-icon>
          <b> เพิ่ม MSC Code</b>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="items"
      class="elevation-1"
      :page.sync="page"
      :items-per-page="pageSize"
      @page-count="pageCount = $event"
      :header-props="{
        sortIcon: 'mdi-menu-up',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        'items-per-page-options': rowsPerPage,
      }"
    >
      <template v-slot:item.actions="{ item }">
        <router-link
          :to="{ name: 'VehicleMscMode.Edit', params: { id: item.id } }"
          class="text-decoration-none mr-3"
        >
          <v-btn dark color="app-theme"><b>แก้ไข</b></v-btn>
        </router-link>

        <v-btn
          dark
          @click="deleteDialog(item.id)"
          color="app-danger"
          min-width="20"
          class="btn-p-10"
          ><v-icon color="app-primary">mdi-trash-can</v-icon></v-btn
        >
      </template>
      <template v-slot:footer> </template>
    </v-data-table>
    <DialogDelete :dialog.sync="showDialog" :deleteId="deleteId"></DialogDelete>
    <pagination
      ref="Pagination"
      :page="page"
      :page-size="pageSize"
      @changePage="page = $event"
      @changePageSize="pageSize = $event"
    />
  </v-container>
</template>

<script>
import DialogDelete from "@/components/DialogDelete";
import Pagination from "@/components/Pagination";
export default {
  components: {
    DialogDelete,
    Pagination,
  },

  data: () => ({
    showDialog: false,
    deleteId: null,

    page: 1,
    pageCount: 0,
    pageSize: 10,
    itemsPerPage: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "MSC Code",
        sortable: false,
        align: "center",
        value: "code",
        width: "20%",
      },
      {
        text: "Model Description",
        sortable: false,
        align: "center",
        value: "description",
        width: "20%",
      },
      {
        text: "Brand",
        sortable: false,
        align: "center",
        value: "carBrand.name",
        width: "20%",
      },
      {
        text: "Model Code",
        sortable: false,
        align: "center",
        value: "carModel.code",
        width: "20%",
      },
      {
        text: "",
        sortable: false,
        align: "center",
        value: "",
      },
      {
        text: "จัดการ",
        sortable: false,
        align: "center",
        value: "actions",
        width: "180px",
      },
    ],
    items: [],
  }),
  mounted() {
    this.$store.state.title = "จัดการ MSC code (Model Serial Code)";
    this.$store.state.showBackBtn = false;
    this.get();
  },
  watch: {
    page() {
      this.get();
    },
    pageSize() {
      this.get();
    },
  },
  methods: {
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/car-model-msc/all",
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.items = resp.data.data;
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data.length
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    confirmDelete(id) {
      this.$store.state.loading = true;
      this.$root
        .appApi({ method: "DELETE", url: "admin/car-model-msc/" + id })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.$root.getSystemMessage(resp.data.message);
          }
          this.get();
        })
        .catch((err) => {
          if (err.message) {
            this.$root.getErrorSystemMessage(err.message);
          } else {
            this.$root.getErrorSystemMessage(err);
          }
        });
    },
    deleteDialog(id) {
      this.showDialog = true;
      this.deleteId = id;
    },
  },
};
</script>

<style></style>
