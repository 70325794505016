<template>
  <div class="my-10">
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center" class="mb-2">
        <v-col cols="12" sm="3" class="pl--title"> ชื่อ </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="firstname"
            color="app-theme"
            placeholder="ชื่อ"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุชื่อ']"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center" class="mb-2">
        <v-col cols="12" sm="3" class="pl--title"> นามสกุล </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="lastname"
            color="app-theme"
            placeholder="นามสกุล"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุนามสกุล']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="mb-2">
        <v-col cols="12" sm="3" class="pl--title"> บริษัท </v-col>
        <v-col cols="12" sm="8">
          <v-select
            class="pl--select2 select--icon--center"
            hide-details="auto"
            color="app-theme"
            placeholder="กรุณาเลือกบริษัท"
            v-model="companyId"
            :items="ddlCompany"
            outlined
            ><template v-slot:append>
              <v-icon large color="app-theme">mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center" class="mb-2">
        <v-col cols="12" sm="3" class="pl--title"> อีเมล </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="email"
            color="app-theme"
            placeholder="อีเมล"
            outlined
            hide-details="auto"
            :rules="[(v) => /.+@.+/.test(v) || 'กรุณาระบุอีเมล']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="mb-2">
        <v-col cols="12" sm="3" class="pl--title"> เบอร์โทรศัพท์ </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="tel"
            color="app-theme"
            placeholder="เบอร์โทรศัพท์"
            outlined
            hide-details="auto"
            v-mask="telMask"
            :rules="[
              (v) =>
                v.length == 12 || 'กรุณาระบุเบอร์โทรศัพท์ให้ครบทั้ง 10 ตัว',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center" class="mb-2" v-if="isAdd">
        <v-col cols="12" sm="3" class="pl--title"> ชื่อผู้ใช้ </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="username"
            color="app-theme"
            placeholder="ชื่อผู้ใช้"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'กรุณาระบุชื่อผู้ใช้']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="mb-2" v-if="!isAdd">
        <v-col cols="12" sm="3" class="pl--title"> ชื่อผู้ใช้ </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="username"
            rounded
            hide-details="false"
            :disabled="true"
            required
            background-color="app-disabled-theme"
            height="48"
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" v-if="isAdd">
        <v-col cols="12" sm="3" class="pl--title"> รหัสผ่าน </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="password"
            color="app-theme"
            placeholder="รหัสผ่าน"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุรหัสผ่าน',
              (v) => (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6',
            ]"
            type="password"
            autocomplete="new-password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/customer/management/')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            :color="
              firstname.length > 0 &&
              lastname.length > 0 &&
              companyId !== '' &&
              email.length > 0 &&
              tel.length > 0 &&
              username.length > 0
                ? 'app-theme'
                : 'app-disabled-button-theme'
            "
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!isAdd">
        <v-col cols="12" sm="3" class="mx-auto d-flex justify-center">
          <router-link
            :to="{ name: 'Customer.ChangePassword', params: { id: 1 } }"
            class="text-decoration-none mx-4"
          >
            <v-btn rounded dark color="app-danger" class="px-15 py-6"
              ><b>เปลี่ยนรหัสผ่าน</b></v-btn
            >
          </router-link>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import helper from "@/helpers/helper";
export default {
  data() {
    return {
      isAdd: this.$route.name === "Customer.Add",
      telMask: "###-###-####",
      firstname: ``,
      lastname: ``,
      companyId: ``,
      email: ``,
      tel: ``,
      username: ``,
      password: ``,

      ddlCompany: [],
    };
  },
  mounted() {
    this.$store.state.title = "เพิ่มข้อมูลลูกค้า";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/customer/management";
    this.getCompany();
    if (this.$route.name === "Customer.Edit") {
      this.get();
      this.$store.state.title = "แก้ไขข้อมูลลูกค้า";
    }
  },
  methods: {
    getCompany() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/user-company/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlCompany = resp.data.data;
          if (ddlCompany) {
            this.ddlCompany = ddlCompany.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/user/" + this.$route.params.id,
        })
        .then((resp) => {
          const entity = resp.data.data;
          let tel = entity.tel;
          if (tel.length == 10) {
            tel = helper.insertString(entity.tel.toString(), 3, "-");
            tel = helper.insertString(tel.toString(), 7, "-");
          }
          this.firstname = entity.firstname;
          this.lastname = entity.lastname;
          this.tel = tel;
          this.email = entity.email;
          this.username = entity.username;
          this.companyId = entity.userCompany ? entity.userCompany.id : "";
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    add() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("firstname", this.firstname);
      formData.append("lastname", this.lastname);
      formData.append("email", this.email);
      formData.append("username", this.username);
      formData.append("password", this.password);
      formData.append("tel", this.tel);
      formData.append("userCompanyId", this.companyId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/user",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/customer/management"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    update() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("firstname", this.firstname);
      formData.append("lastname", this.lastname);
      formData.append("email", this.email);
      formData.append("username", this.username);
      formData.append("password", this.password);
      formData.append("tel", this.tel);
      formData.append("userCompanyId", this.companyId);
      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/user/",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/customer/management"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
