<template>
  <div>
    <v-row class="my-1 pa-5">
      <div width="200px" style="padding-right: 80px">
        <b>{{ yard }}</b>
      </div>
      <div width="200px" style="padding-right: 80px">
        <b>{{ phase }}</b>
      </div>
      <div width="200px">
        <b>{{ zone }}</b>
      </div>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      hide-default-footer
      expand-icon="mdi-chevron-right"
    >
    </v-data-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    yard: "OTTOLand",
    phase: "Phase 2",
    zone: "Zone F",
    expanded: [],
    singleExpand: false,

    showDialog: false,
    deleteId: null,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "Zone",
        sortable: false,
        align: "center",
        value: "zone",
        width: "180px",
      },
      {
        text: "ลูกค้า",
        sortable: false,
        align: "center",
        value: "company",
        width: "180px",
      },
      {
        text: "Row",
        sortable: false,
        align: "center",
        value: "row",
        width: "180px",
      },
      {
        text: "Row No.",
        sortable: false,
        align: "center",
        value: "rowNo",
        width: "180px",
      },
      {
        text: "Per Row",
        sortable: false,
        align: "center",
        value: "perRow",
        width: "180px",
      },
      {
        text: "จอดแล้ว",
        sortable: false,
        align: "center",
        value: "unavailable",
        width: "180px",
      },
      {
        text: "",
        sortable: false,
        align: "center",
        value: "actions",
      },
    ],

    items: [],
  }),
  mounted() {
    this.$store.state.title = "รายละเอียด";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/yard/management/info";
    if (!this.$route.params.yardId) {
      this.$router.push("/yard/management/info");
    } else {
      this.getZone();
      this.get();
    }
  },
  watch: {
    page() {
      this.get();
    },
  },
  methods: {
    getZone() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-zone/" + this.$route.params.id,
        })
        .then((resp) => {
          this.yard = resp.data.data.yard.name;
          this.phase = resp.data.data.yardPhase.name;
          this.zone = resp.data.data.name;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("yardId", this.$route.params.yardId);
      formData.append("yardPhaseId", this.$route.params.phaseId);
      formData.append("yardZoneId", this.$route.params.zoneId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/yard/search",
          data: formData,
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              let yardZones = [];
              this.items = entity.map((item) => {
                let company = "-";
                let row = 0;
                let qty = 0;
                let qtyIn = 0;
                if (item.yardZones) {
                  company = item.yardZones[0].userCompany.name;
                  item.yardZones.forEach((yz) => {
                    row = row + yz.rowNumbers;
                    yz.yardZoneRows.forEach((item) => {
                      qty = qty + item.qty;
                      qtyIn = qtyIn + item.qtyIn;
                    });
                  });

                  yardZones = item.yardZones[0].yardZoneRows.map((yzr) => {
                    return {
                      id: "yzr" + yzr.id,
                      zone: "",
                      company: company,
                      row: "",
                      rowNo: yzr.no,
                      perRow: yzr.qty,
                      unavailable: yzr.qtyIn,
                    };
                  });
                }
                return {
                  id: "zone" + item.id,
                  zone: item.yardZones[0].name,
                  company: company,
                  row: item.yardZones[0].rowNumbers,
                  rowNo: "",
                  perRow: "",
                  unavailable: qtyIn,
                };
              });

              this.items = this.items.concat(yardZones);
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>
