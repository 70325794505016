<template>
  <div>
    <v-row class="my-1">
      <v-col cols="12" sm="2" class="pt-5 text-center">
        <b>{{ yard }}</b>
      </v-col>
      <v-col cols="12" sm="2" class="pt-5 text-center">
        <b>{{ phase }}</b>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="pageSize"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      class="elevation-1"
      hide-default-footer
      expand-icon="mdi-chevron-right"
      fixed-header
      height="600px"
    >
      <template v-slot:item.actions="{}"> </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0 ma-0">
          <div>
            <v-data-table
              hide-default-header
              :headers="subHeaders"
              :items="item.yardZones"
              :single-expand="subSingleExpand"
              :expanded.sync="subExpanded"
              item-key="id"
              show-expand
              class="elevation-2 subtable"
              hide-default-footer
              expand-icon="mdi-chevron-right"
              :items-per-page="9999"
            >
              <template v-slot:item.vinNo="{}"></template>
              <template v-slot:item.actions="{}"
                ><div style="width: 5vw"></div
              ></template>
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="pa-0 ma-0"
                  v-if="item.vinNo.length > 0"
                >
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody class="pa-0 ma-0">
                        <tr v-for="(subItem, idx) in item.vinNo" :key="idx">
                          <td
                            :colspan="headers.length"
                            class="text-right"
                            width="100%"
                          >
                            {{ subItem.vinNo }}
                            <router-link
                              :to="{
                                name: 'Stock.VinNoDetail',
                                params: {
                                  id: subItem.id,
                                  vinNo: subItem.vinNo,
                                },
                              }"
                              class="text-decoration-none mx-1 ml-5"
                            >
                              <v-btn
                                min-width="20"
                                class="btn-p-10"
                                dark
                                color="app-pink-theme"
                                ><b
                                  ><v-icon color="">mdi-magnify</v-icon></b
                                ></v-btn
                              >
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
                <td
                  class="text-center no--data--text"
                  :colspan="headers.length"
                  v-else
                >
                  No data available
                </td>
              </template>
            </v-data-table>
          </div>
        </td>
      </template>
    </v-data-table>

    <Pagination
      ref="Pagination"
      :page="$store.state.tempStockPage"
      :page-size="pageSize"
      @changePage="page = $event"
      @changePageSize="pageSize = $event"
    />
  </div>
</template>
<style scoped>
.subtable >>> td.text-start {
  width: 190px;
}
.v-data-table >>> tr > td > button {
  color: #08318a;
  justify-content: right;
  align-content: right;
}
.v-data-table >>> tr:nth-child(n) > td.text-start:nth-child(1) {
  padding: 0 !important;
  text-align: right !important;
}

.subtable >>> tr:nth-child(1) > td:nth-child(2) {
  width: 180px;
}
.subtable >>> tr:nth-child(1) > td:nth-child(3) {
  width: 160px;
}
.subtable >>> tr:nth-child(1) > td:nth-child(4) {
  width: 160px;
}
.subtable >>> tr:nth-child(1) > td:nth-child(5) {
  width: 160px;
}
.subtable >>> tr:nth-child(1) > td:nth-child(6) {
  width: 160px;
}
.no--data--text {
  color: rgba(0, 0, 0, 0.38);
}

/* .subtable */
</style>
<script>
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  data: () => ({
    yard: "",
    phase: "",

    expanded: [],
    singleExpand: true,

    subExpanded: [],
    subSingleExpand: false,

    showDialog: false,
    deleteId: null,
    page: 1,
    pageCount: 0,
    pageSize: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "Zone",
        sortable: false,
        align: "center",
        value: "zone",
        width: "160px",
      },
      {
        text: "ลูกค้า",
        sortable: false,
        align: "center",
        value: "customer",
        width: "180px",
      },
      {
        text: "Row",
        sortable: false,
        align: "center",
        value: "row",
        width: "160px",
      },
      {
        text: "Row No.",
        sortable: false,
        align: "center",
        value: "rowNo",
        width: "160px",
      },
      {
        text: "Per Row",
        sortable: false,
        align: "center",
        value: "perRow",

        width: "160px",
      },
      {
        text: "จอดแล้ว",
        sortable: false,
        align: "center",
        value: "unavailable",

        width: "160px",
      },
      {
        text: "Vin No.",
        sortable: false,
        align: "center",
        value: "vinNo",

        width: "180px",
      },
      {
        text: "",
        sortable: false,
        align: "center",
        value: "actions",
      },
    ],
    subHeaders: [
      {
        text: "ลูกค้า",
        sortable: false,
        align: "center",
        value: "customer",
      },
      {
        text: "Row",
        sortable: false,
        align: "center",
        value: "row",
      },
      {
        text: "Row No.",
        sortable: false,
        align: "center",
        value: "rowNo",
      },
      {
        text: "Per Row",
        sortable: false,
        align: "center",
        value: "perRow",
      },
      {
        text: "จอดแล้ว",
        sortable: false,
        align: "center",
        value: "unavailable",
      },
      {
        text: "Vin No.",
        sortable: false,
        align: "center",
        value: "vinNo",
      },
      {
        text: "",
        sortable: false,
        align: "center",
        value: "actions",
      },
    ],
    items: [],
    countData: 0,
    flagPage: false,
  }),
  async mounted() {
    this.$store.state.isStockPage = true;
    this.$store.state.isBackToStock = true;
    this.$store.state.title = "รายละเอียด";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/stock";
    if (!this.$route.params.id) {
      this.$router.push("/stock");
    } else {
      this.$store.commit("setTempStock", {
        tempStockId: this.$route.params.id,
        tempStockYardId: this.$route.params.yardId,
        tempStockPhaseId: this.$route.params.phaseId,
        tempStockZoneId: this.$route.params.zoneId,
      });
      this.getPhase();
      await this.get();
      if (this.flagPage) {
        await this.get();
      }
    }
  },
  watch: {
    page() {
      this.$store.state.tempStockPage = this.page;
      this.get();
    },
    pageSize() {
      this.$store.state.tempStockPage = this.page;
      this.get();
    },
  },
  methods: {
    getPhase() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-phase/" + this.$route.params.id,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          this.phase = resp.data.data.name;
          this.yard = resp.data.data.yard.name;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    async get() {
      this.$store.state.loading = true;
      const formData = new FormData();
      await this.$root
        .appApi({
          url: "admin/car-in/yard-phase/" + this.$route.params.id,
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then(async (resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            entity.sort(function (a, b) {
              if (a.id < b.id) {
                return -1;
              }
              if (a.id > b.id) {
                return 1;
              }
              return 0;
            });
            if (entity) {
              this.items = await Promise.all(
                entity.map(async (ent) => {
                  let unavailable = 0;
                  let customer = ent.userCompany.name;
                  let yardZones = [];
                  if (ent.yardZoneRows) {
                    yardZones = await Promise.all(
                      ent.yardZoneRows.map(async (yzr) => {
                        unavailable += yzr.qtyInStock;
                        let vinNo = [];
                        if (yzr.carIns) {
                          vinNo = yzr.carIns;
                        }
                        return {
                          _id: yzr.id,
                          id: "yzr" + yzr.id,
                          zone: "",
                          customer: customer,
                          row: "",
                          rowNo: yzr.no,
                          perRow: yzr.qty,
                          unavailable: yzr.qtyInStock,
                          vinNo: vinNo,
                        };
                      })
                    );
                  }
                  return {
                    id: "zone" + ent.id,
                    zone: ent.name,
                    customer: customer,
                    row: ent.rowNumbers,
                    rowNo: "",
                    perRow: "",
                    unavailable: unavailable,
                    yardZones: yardZones,
                  };
                })
              );
            }
            if (this.flagPage) {
              this.$refs.Pagination.getPagination(
                this.countData,
                resp.data.data.length
              );
            } else {
              this.countData = resp.data.count;
              this.$refs.Pagination.getPagination(
                resp.data.count,
                resp.data.data.length
              );
            }
            this.flagPage = false;
            if (this.$store.state.tempStockPage != 1) {
              this.page = this.$store.state.tempStockPage;
              this.flagPage = true;
            }
            // this.$refs.Pagination.getPagination(
            //   resp.data.count,
            //   resp.data.data.length
            // );
          }

          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>
