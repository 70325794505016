<template>
  <v-container>
    <v-form @submit.prevent="isAdd ? add() : update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"
          ><req></req> <b>ระบุกลุ่มพนักงาน</b>
        </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="name"
            color="app-theme"
            placeholder="ระบุกลุ่มพนักงาน"
            outlined
            hide-details="auto"
            :rules="[(v) => !!v || 'ระบุกลุ่มพนักงาน']"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/labor/employee_group')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      validPermission: false,
      isAdd: this.$route.name === "EmployeeGroup.Add",
      name: "",
    };
  },
  mounted() {
    this.$store.state.title = "เพิ่มกลุ่มพนักงาน";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/labor/employee_group";
    if (this.$route.name === "EmployeeGroup.Edit") {
      this.get();
      this.$store.state.title = "แก้ไขกลุ่มพนักงาน";
    }
  },
  methods: {
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({ url: `admin/staff-group/${this.$route.params.id}` })
        .then((resp) => {
          const entity = resp.data.data;
          this.name = entity.name;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    add() {
      if (!this.$refs.form.validate()) return;
      const formData = new FormData();
      formData.append("name", this.name);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff-group",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/labor/employee_group"
            );
          }
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
        });
    },
    update() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("name", this.name);
      this.$root
        .appApi({
          method: "PATCH",
          url: "admin/staff-group",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(
              resp.data.message,
              "/labor/employee_group"
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
