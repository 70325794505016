<template>
  <v-container>
    <v-form @submit.prevent="search()" ref="form">
      <v-row class="mt-1" align="center">
        <v-col cols="1" class="text-center">
          <b style="color: #041e41">ค้นหา</b>
        </v-col>
        <v-col cols="8">
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                label="ลูกค้า"
                hide-details="auto"
                color="app-theme"
                solo
                v-model="customer"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="yard"
                hide-details="auto"
                color="app-theme"
                solo
                v-model="yard"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                class="select--icon--center"
                hide-details="auto"
                color="app-theme"
                placeholder="สถานะ"
                v-model="statusId"
                :items="ddlStatus"
                solo
                ><template v-slot:append>
                  <v-icon large>mdi-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" class="text-right pr-10">
          <v-btn
            class="py-6"
            rounded
            outlined
            color="app-theme"
            dark
            style="text-transform: none !important"
            type="submit"
          >
            <b> ค้นหา</b>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-5">
        <v-col cols="1" class="text-center"> </v-col>
        <v-col cols="8" class="">
          <v-row>
            <v-col cols="12" sm="4">
              <v-menu
                v-model="menuStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="input--icon--center"
                    v-model="dateStartFormatted"
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                    solo
                    required
                    hide-details
                    placeholder="วันที่"
                  >
                    <template v-slot:append>
                      <v-img
                        class="pa-0 ma-0"
                        src="@/assets/icons/ic_calendar.svg"
                        contain
                        width="26"
                      ></v-img>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dateStart"
                  no-title
                  @change="menuStart = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" class="text-right">
          <v-btn
            class="py-6"
            :to="{ name: 'ImportVehicle.Add' }"
            rounded
            color="app-theme"
            dark
            style="text-transform: none !important"
            width="220px"
          >
            <v-icon class="pr-2">mdi-plus</v-icon>
            <b> รับรถเข้าคลัง</b>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="items"
      class="elevation-1"
      :page.sync="page"
      :items-per-page="pageSize"
      @page-count="pageCount = $event"
      :header-props="{
        sortIcon: 'mdi-menu-up',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
      }"
    >
      <template v-slot:item.status="{ item }">
        <span v-if="item.status == 'request'" class="text-red"
          >รอมอบหมาย Driver Leader
        </span>
        <span v-else-if="item.status == 'wait'" class="text-orange"
          >รอดำเนินการ
        </span>
        <span v-else-if="item.status == 'doing'" class="text-primary"
          >กำลังดำเนินการ
        </span>
        <span v-else-if="item.status == 'done'" class="text-green"
          >ดำเนินการเสร็จสิ้น
        </span>
        <span v-else-if="item.status == 'cancel'" class="text-disable"
          >ยกเลิก
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <router-link
          :to="{ name: 'ImportVehicle.Edit', params: { id: item.id } }"
          class="text-decoration-none mr-4"
        >
          <v-btn dark color="app-light-theme" class="px-2"
            ><b>ดูรายละเอียด</b></v-btn
          >
        </router-link>

        <v-btn
          v-if="item.status == 'request'"
          dark
          @click="deleteDialog(item.id)"
          color="app-danger"
          min-width="0"
          width="40"
          ><v-icon color="app-primary">mdi-trash-can</v-icon></v-btn
        >
      </template>
      <template v-slot:footer> </template>
    </v-data-table>
    <DialogDelete :dialog.sync="showDialog" :deleteId="deleteId"></DialogDelete>
    <pagination
      ref="Pagination"
      :page="page"
      :page-size="pageSize"
      @changePage="page = $event"
      @changePageSize="pageSize = $event"
    />
  </v-container>
</template>
<style scoped>
.text-red {
  color: red;
}
.text-orange {
  color: orange;
}
.text-primary {
  color: #203486;
}
.text-green {
  color: #508b21;
}
.text-disable {
  color: #ddd;
}
.input--icon--center >>> .v-input__append-inner {
  margin: 0 !important;
}
</style>
<script>
import DialogDelete from "@/components/DialogDelete";
import Pagination from "@/components/Pagination";
import helper from "@/helpers/helper";
export default {
  components: {
    DialogDelete,
    Pagination,
  },
  data: () => ({
    helper: helper,
    customer: "",
    yard: "",
    statusId: "all",
    ddlStatus: [
      { text: "ทั้งหมด", value: "all" },
      { text: "รอมอบหมาย Driver Leader", value: "request" },
      { text: "รอดำเนินการ", value: "wait" },
      { text: "กำลังดำเนินการ", value: "doing" },
      { text: "ดำเนินการเสร็จสิ้น", value: "done" },
      { text: "ยกเลิก", value: "cancel" },
    ],
    ddlYard: [],
    ddlCustomerCompany: [],
    dateStart: "",
    dateStartFormatted: "",
    menuStart: false,

    showDialog: false,
    deleteId: null,
    page: 1,
    pageCount: 0,
    pageSize: 10,
    // rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "Job No.",
        align: "center",
        sortable: false,
        value: "jobNo",
      },
      {
        text: "บริษัทลูกค้า",
        align: "center",
        value: "customerCompany",
      },
      { text: "Yard", align: "center", value: "yard" },
      { text: "วันที่", align: "center", value: "date" },
      { text: "เวลา", align: "center", value: "time" },
      {
        text: "สถานะ",
        align: "center",
        value: "status",
      },
      {
        text: "",
        sortable: false,
        align: "left",
        value: "actions",
        width: "230px",
      },
    ],
    items: [],
  }),
  mounted() {
    this.$store.state.title = "รับรถเข้าคลังจากลูกค้า";
    this.$store.state.showBackBtn = false;
    this.search();
  },
  watch: {
    dateStart() {
      this.dateStartFormatted = this.helper.formatDate(this.dateStart);
      this.search();
    },
    statusId() {
      this.search();
    },
    yard() {
      this.search();
    },
    customer() {
      this.search();
    },
    page() {
      // if (
      //   helper.isNullOrEmpty(this.customer) &&
      //   helper.isNullOrEmpty(this.yard) &&
      //   helper.isNullOrEmpty(this.dateStartFormatted) &&
      //   this.statusId === "all"
      // ) {
      //   this.get();
      // } else {
      this.search();
      // }
    },
    pageSize() {
      this.search();
    },
  },
  methods: {
    getYard() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard/all",
        })
        .then((resp) => {
          const ddlYard = resp.data.data;
          if (ddlYard) {
            this.ddlYard = ddlYard.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getCompany() {
      this.$store.state.loading = true;
      this.$root
        .appApi({ url: "admin/user-company/all" })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.ddlCustomerCompany = entity.map((item) => {
                return {
                  value: item.id,
                  text: item.name,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/car-importing/all",
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                return {
                  id: item.id,
                  jobNo: item.jobNo,
                  customerCompany: item.userCompany.name ?? "-",
                  yard: item.yard.name ?? "-",
                  date: helper.formatDate(item.importDate),
                  time: `${item.importTime} น.`,
                  status: item.status,
                };
              });
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data.length
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    search() {
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("status", this.statusId);
      if (!helper.isNullOrEmpty(this.customer)) {
        formData.append("userCompanyKeyword", this.customer);
      }
      if (!helper.isNullOrEmpty(this.yard)) {
        formData.append("yardKeyword", this.yard);
      }
      if (!helper.isNullOrEmpty(this.dateStartFormatted)) {
        formData.append("importDate", this.dateStart);
      }
      this.$root
        .appApi({
          method: "POST",
          url: "admin/car-importing/search",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            let entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                return {
                  id: item.id,
                  jobNo: item.jobNo,
                  customerCompany: item.userCompany.name ?? "-",
                  yard: item.yard.name ?? "-",
                  date: helper.formatDate(item.importDate),
                  time: `${item.importTime} น.`,
                  status: item.status,
                };
              });
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data.length
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    confirmDelete(id) {
      this.$store.state.loading = true;
      this.$root
        .appApi({ method: "DELETE", url: "admin/car-importing/" + id })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.$root.getSystemMessage(resp.data.message);
          }
          this.get();
        })
        .catch((err) => {
          if (err.message) {
            this.$root.getErrorSystemMessage(err.message);
          } else {
            this.$root.getErrorSystemMessage(err);
          }
        });
    },
    deleteDialog(id) {
      this.showDialog = true;
      this.deleteId = id;
    },
  },
};
</script>

<style></style>
