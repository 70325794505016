<template>
  <div class="my-10">
    <v-form @submit.prevent="update()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"
          ><req></req>ระบุรหัสผ่านใหม่
        </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="newPassword"
            color="app-theme"
            placeholder="ระบุรหัสผ่านใหม่"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุรหัสผ่าน',
              (v) => (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6',
              ,
              (v) =>
                v === (confirmNewPassword || '') ||
                'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน',
              (v) =>
                /[A-Za-z]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
              (v) =>
                /[0-9]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
            ]"
            type="password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="3" class="pl--title"
          ><req></req>ยืนยันระบุรหัสผ่านใหม่
        </v-col>
        <v-col cols="10" sm="8">
          <v-text-field
            class="pl--input"
            v-model="confirmNewPassword"
            color="app-theme"
            placeholder="ระบุยืนยันรหัสผ่านใหม่"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุยืนยันรหัสผ่าน',
              (v) => (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6',
              ,
              (v) =>
                v === (newPassword || '') ||
                'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน',

              (v) =>
                /[A-Za-z]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษา อังกฤษกับตัวเลขปนกัน',
              (v) =>
                /[0-9]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษา อังกฤษกับตัวเลขปนกัน',
            ]"
            type="password"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-5 mt-15">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/executive/management/')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >
          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  data: () => ({
    newPassword: "",
    confirmNewPassword: "",
  }),
  mounted() {
    this.$store.state.title = "เปลี่ยนรหัสผ่าน";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/executive/management";
  },
  watch: {
    newPassword() {
      if (!this.$refs.form.validate()) return;
    },
    confirmNewPassword() {
      if (!this.$refs.form.validate()) return;
    },
  },
  methods: {
    update() {
      if (!this.$refs.form.validate()) return;
      if (
        this.newPassword === "111111" &&
        this.confirmNewPassword === "111111"
      ) {
        this.$root.getErrorSystemMessage(
          "เปลี่ยนรหัสผ่านไม่สำเร็จกรุณาลองอีกครั้ง"
        );
      } else {
        this.$root.getSystemMessage("บันทึกรหัสผ่านสำเร็จ");
        this.$router.push("/executive/management");
      }
    },
  },
};
</script>

<style></style>
