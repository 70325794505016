var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-10"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.update()}}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pl--title",attrs:{"cols":"12","sm":"3"}},[_c('req'),_c('b',[_vm._v("รหัสผ่านเก่า")])],1),_c('v-col',{attrs:{"cols":"10","sm":"8"}},[_c('v-text-field',{staticClass:"pl--input",attrs:{"color":"app-theme","placeholder":"ระบุรหัสผ่านเก่า","outlined":"","hide-details":"auto","rules":[
            function (v) { return !!v || 'กรุณาระบุรหัสผ่าน'; },
            function (v) { return (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6'; },
            function (v) { return /[A-Za-z]/.test(v) ||
              'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน'; },
            function (v) { return /[0-9]/.test(v) ||
              'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน'; } ],"type":"password"},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pl--title",attrs:{"cols":"12","sm":"3"}},[_c('req'),_c('b',[_vm._v("รหัสผ่านใหม่")])],1),_c('v-col',{attrs:{"cols":"10","sm":"8"}},[_c('v-text-field',{staticClass:"pl--input",attrs:{"color":"app-theme","placeholder":"ระบุรหัสผ่านใหม่","outlined":"","hide-details":"auto","rules":[
            function (v) { return !!v || 'กรุณาระบุรหัสผ่าน'; },
            function (v) { return (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6'; },
            ,
            function (v) { return v === (_vm.confirmNewPassword || '') ||
              'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน'; },
            function (v) { return /[A-Za-z]/.test(v) ||
              'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน'; },
            function (v) { return /[0-9]/.test(v) ||
              'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน'; } ],"type":"password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pl--title",attrs:{"cols":"12","sm":"3"}},[_c('req'),_c('b',[_vm._v("ยืนยันระบุรหัสผ่านใหม่")])],1),_c('v-col',{attrs:{"cols":"10","sm":"8"}},[_c('v-text-field',{staticClass:"pl--input",attrs:{"color":"app-theme","placeholder":"ระบุยืนยันรหัสผ่านใหม่","outlined":"","hide-details":"auto","rules":[
            function (v) { return !!v || 'กรุณาระบุยืนยันรหัสผ่าน'; },
            function (v) { return (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6'; },
            ,
            function (v) { return v === (_vm.newPassword || '') ||
              'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน'; },
            function (v) { return /[A-Za-z]/.test(v) ||
              'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน'; },
            function (v) { return /[0-9]/.test(v) ||
              'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน'; } ],"type":"password"},model:{value:(_vm.confirmNewPassword),callback:function ($$v) {_vm.confirmNewPassword=$$v},expression:"confirmNewPassword"}})],1)],1),_c('v-row',{staticClass:"my-5 mt-15",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mx-auto d-flex justify-center",attrs:{"cols":"12","sm":"8"}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'Admin' }}},[_c('v-btn',{staticClass:"mr-4 my-2 px-8 py-6",attrs:{"rounded":"","color":"app-disabled-button-theme","dark":""}},[_vm._v("ยกเลิก")])],1),_c('v-btn',{staticClass:"my-2 px-8 py-6",attrs:{"rounded":"","color":"app-theme","dark":"","type":"submit"}},[_vm._v("บันทึก")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }