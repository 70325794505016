<template>
  <v-container>
    <v-form @submit.prevent="add()" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="auto" class="pl--title">
          <div style="width: 200px">นำเข้าข้อมูลการทำงาน</div>
        </v-col>
        <v-col cols="12" sm="6">
          <v-file-input
            class="placeholder--black"
            ref="upload"
            name="upload"
            color="app-theme"
            hide-details
            placeholder="เลือกไฟล์"
            outlined
            persistent-hint
            prepend-icon=""
            @change="onFileChange"
            accept=".csv"
          ></v-file-input>
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn
            @click="$refs.upload.$refs.input.click()"
            class="py-6"
            rounded
            color="green"
            dark
            style="text-transform: none !important"
          >
            <b> Browse</b>
          </v-btn>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="auto" class="pl--title">
          <div style="width: 200px"></div>
        </v-col>
        <v-col cols="12" sm="6" class="py-0 my-0">
          <div style="font-size: 16px; color: grey">ชนิดไฟล์ .csv</div>
        </v-col>
      </v-row>
      <v-row align="center" class="mb-10">
        <v-col cols="12" sm="auto" class="pl--title">
          <div style="width: 200px">Yard</div>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            class="pl--select select--icon--center"
            hide-details="auto"
            color="app-theme"
            outlined
            v-model="yardId"
            :items="ddlYard"
            :rules="[(v) => !!v || 'กรุณาเลือก Yard']"
            ><template v-slot:append>
              <v-icon large>mdi-chevron-down</v-icon>
            </template></v-select
          >
        </v-col>
      </v-row>
      <v-row no-gutters class="my-5" v-if="showBtn">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="$router.push('/labor/record_of_work')"
            rounded
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6"
            >ยกเลิก</v-btn
          >

          <v-btn
            rounded
            color="app-theme"
            dark
            class="my-2 px-8 py-6"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      v-if="showTable"
      hide-default-footer
      :headers="headers"
      :items="items"
      class="elevation-1 border-1"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :header-props="{
        sortIcon: 'mdi-menu-up',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        'items-per-page-options': rowsPerPage,
      }"
    >
      <template v-slot:item.inDatetime="{ item }">
        <div style="color: #203486">{{ item.inDatetime }}</div>
      </template>
      <template v-slot:item.outDatetime="{ item }">
        <div style="color: #203486">{{ item.outDatetime }}</div>
      </template>
      <template v-slot:item.leave="{ item }">
        <div style="color: #203486">{{ item.leave }}</div>
      </template>
      <template v-slot:item.absent="{ item }">
        <div style="color: #203486">{{ item.absent }}</div>
      </template>
      <template v-slot:footer> </template>
    </v-data-table>
  </v-container>
</template>
<style scoped>
.border-1 >>> td {
  border-left: 1px solid #ddd;
}
.border-1 >>> th {
  border-left: 1px solid #ddd;
}
.placeholder--black >>> .v-file-input__text--placeholder {
  color: black;
}
</style>
<script>
export default {
  data() {
    return {
      showBtn: false,
      showTable: false,
      file: null,
      yardId: "",
      ddlYard: [
        { text: "OTTOLand", value: 1 },
        { text: "Yard", value: 2 },
      ],

      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
      headers: [
        {
          text: "ลำดับ",
          align: "center",
          value: "runningNo",
        },
        {
          text: "ชื่อพนักงาน",
          align: "center",
          value: "fullname",
          width: "180px",
        },
        { text: "ตำแหน่ง", align: "center", value: "position", width: "150px" },
        {
          text: "เวลาเข้างาน",
          align: "center",
          value: "inDatetime",
          sortable: false,
        },
        {
          text: "เวลาออกงาน",
          align: "center",
          value: "outDatetime",
          sortable: false,
        },
        { text: "ลางาน", align: "center", value: "leave", sortable: false },
        { text: "ขาดงาน", align: "center", value: "absent", sortable: false },
        {
          text: "",
          align: "center",
          value: "actions",
          width: "180px",
          sortable: false,
        },
      ],
      items: [],
    };
  },
  mounted() {
    this.$store.state.title = "นำเข้าข้อมูลการทำงาน";
    this.$store.state.showBackBtn = false;
    this.getYard();
  },
  methods: {
    getYard() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard/all",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlYard = resp.data.data;
          if (ddlYard) {
            this.ddlYard = ddlYard.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    convertDateToHHMM(date) {
      if (!date) return date;
      const time = new Date(date);
      return (
        time.getHours().toString().padStart(2, "0") +
        ":" +
        time.getMinutes().toString().padStart(2, "0")
      );
    },
    add() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("yardId", this.yardId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/staff-work/upload",
          data: formData,
        })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(errors);
          } else {
            this.$root.getSystemMessage(resp.data.message);
          }
          if (resp.data.data) {
            const entity = resp.data.data;
            this.items = entity.map((item, idx) => {
              return {
                id: item.id,
                runningNo: idx + 1,
                fullname: `${item.firstname} ${item.lastname}`,
                position: item.staff.staffPosition.name || "-",
                inDatetime: this.convertDateToHHMM(item.inDatetime) || "-",
                outDatetime: this.convertDateToHHMM(item.outDatetime) || "-",
                leave: "-",
                absent: "-",
              };
            });
          } else {
            this.items = [];
          }
          this.showBtn = false;
          this.showTable = true;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },

    onFileChange(file) {
      this.file = file;
      this.showBtn = true;
    },
  },
};
</script>

<style></style>
