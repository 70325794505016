<template>
  <div>
    <v-row class="my-1 px-1">
      <v-col cols="12" sm="2" class="pt-5 text-left">
        <b>{{ yard }}</b>
      </v-col>
      <v-col cols="12" sm="3" class="pt-5 text-left">
        <b>{{ phase }}</b>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="pageSize"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      class="elevation-1"
      hide-default-footer
      expand-icon="mdi-chevron-right"
    >
      <template v-slot:item.actions="{}">
        <div width="100px"></div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          v-if="item.yardZones.length > 0"
          :colspan="headers.length"
          class="pa-0 ma-0"
        >
          <div>
            <v-simple-table>
              <template v-slot:default>
                <tbody class="pa-0 ma-0">
                  <tr v-for="subItem in item.yardZones" :key="subItem.id">
                    <td class="text-center" width="2%"></td>
                    <td class="text-center" width="180px">
                      {{ subItem.zone }}
                    </td>
                    <td class="text-center" width="180px">
                      {{ subItem.company }}
                    </td>
                    <td class="text-center" width="180px">
                      {{ subItem.row }}
                    </td>
                    <td class="text-center" width="180px">
                      {{ subItem.rowNo }}
                    </td>
                    <td class="text-center" width="180px">
                      {{ subItem.perRow }}
                    </td>
                    <td class="text-center" width="180px">
                      {{ subItem.unavailable }}
                    </td>
                    <td class="text-center"></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </td>

        <div v-else></div>
      </template>
    </v-data-table>
    <Pagination
      ref="Pagination"
      :page="page"
      :page-size="pageSize"
      @changePage="page = $event"
      @changePageSize="pageSize = $event"
    />
  </div>
</template>
<style scoped>
.v-data-table >>> tr > th > span {
  font-weight: 0 !important;
  font-size: 18px !important;
}
.v-data-table >>> tr > td > button {
  color: #08318a;
  justify-content: right;
  align-content: right;
}
.v-data-table >>> tr:nth-child(n) > td.text-start:nth-child(1) {
  padding: 0 !important;
  text-align: right !important;
}
</style>
<script>
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  data: () => ({
    yard: "OTTOLand",
    phase: "Phase 1",

    expanded: [],
    singleExpand: false,

    showDialog: false,
    deleteId: null,
    page: 1,
    pageCount: 0,
    pageSize: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "Zone",
        sortable: false,
        align: "center",
        value: "zone",
        width: "180px",
      },
      {
        text: "ลูกค้า",
        sortable: false,
        align: "center",
        value: "company",
        width: "180px",
      },
      {
        text: "Row",
        sortable: false,
        align: "center",
        value: "row",
        width: "180px",
      },
      {
        text: "Row No.",
        sortable: false,
        align: "center",
        value: "rowNo",
        width: "180px",
      },
      {
        text: "Per Row",
        sortable: false,
        align: "center",
        value: "perRow",
        width: "180px",
      },
      {
        text: "จอดแล้ว",
        sortable: false,
        align: "center",
        value: "unavailable",
        width: "180px",
      },
      {
        text: "",
        sortable: false,
        align: "center",
        value: "",
      },
    ],

    items: [],
  }),
  watch: {
    page() {
      this.get();
    },
    pageSize() {
      this.get();
    },
  },
  mounted() {
    this.$store.state.title = "รายละเอียด";
    this.$store.state.showBackBtn = true;
    this.$store.state.isBackToName = "/yard/management/info";
    if (!this.$route.params.yardId) {
      this.$router.push("/yard/management/info");
    } else {
      this.getPhase();
      this.get();
    }
  },
  methods: {
    getPhase() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "admin/yard-phase/" + this.$route.params.id,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          this.phase = resp.data.data.name;
          this.yard = resp.data.data.yard.name;
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    get() {
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("yardId", this.$route.params.yardId);
      formData.append("yardPhaseId", this.$route.params.phaseId);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/yard/search",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              entity.map((ent) => {
                if (ent.yardZones) {
                  this.items = ent.yardZones.map((item) => {
                    let yardZones = [];
                    let company = "-";
                    let unavailable = 0;
                    company = item.userCompany.name;

                    yardZones = item.yardZoneRows.map((yzr) => {
                      unavailable += yzr.qtyIn;
                      return {
                        id: "yzr" + yzr.id,
                        zone: "",
                        company: company,
                        row: "",
                        rowNo: yzr.no,
                        perRow: yzr.qty,
                        unavailable: yzr.qtyIn,
                      };
                    });

                    return {
                      id: "zone" + item.id,
                      zone: item.name,
                      company: company,
                      row: item.rowNumbers,
                      rowNo: "",
                      perRow: "",
                      unavailable: unavailable,
                      yardZones: yardZones,
                    };
                  });
                }
                return;
              });
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data.length
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>
