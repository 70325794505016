<template>
  <v-container>
    <v-form @submit.prevent="searchDate()" ref="form">
      <v-row class="my-1" align="center">
        <v-col cols="9">
          <v-row align="center">
            <v-col cols="auto" align="center" class="text-center">
              <b>ค้นหาวันที่</b>
            </v-col>
            <v-col cols="10" sm="10">
              <v-row align="center">
                <v-col cols="12" sm="5">
                  <v-menu
                    v-model="menuStart"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateStartFormatted"
                        class="input--icon--center"
                        persistent-hint
                        append-icon="mdi-calendar-month"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        placeholder="เริ่มต้น"
                        hide-details="auto"
                        :rules="[(v) => !!v || 'กรุณาระบุวันที่เริ่มต้น']"
                        ><template v-slot:append>
                          <v-img
                            class="pa-0 ma-0"
                            src="@/assets/icons/ic_calendar.svg"
                            contain
                            width="24"
                          ></v-img></template
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateStart"
                      no-title
                      @change="menuStart = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                -
                <v-col cols="12" sm="5">
                  <v-menu
                    v-model="menuEnd"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateEndFormatted"
                        class="input--icon--center"
                        persistent-hint
                        append-icon="mdi-calendar-month"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details="auto"
                        placeholder="สิ้นสุด"
                        :rules="[(v) => !!v || 'กรุณาระบุวันที่สิ้นสุด']"
                        ><template v-slot:append>
                          <v-img
                            class="pa-0 ma-0"
                            src="@/assets/icons/ic_calendar.svg"
                            contain
                            width="24"
                          ></v-img></template
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateEnd"
                      no-title
                      @change="menuEnd = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" class="text-right pr-10">
          <v-btn
            class="pa-6"
            rounded
            outlined
            color="app-theme"
            dark
            style="text-transform: none !important"
            type="submit"
          >
            <b> ค้นหา</b>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="my-1">
      <v-col cols="12" class="text-right">
        <v-btn
          class="py-6 mr-4"
          rounded
          color="app-export-theme"
          dark
          style="text-transform: none !important"
          width="200px"
          @click="downloadExcel"
        >
          <b>ส่งออก Excel</b>
        </v-btn>
        <v-btn
          class="py-6"
          :to="{ name: 'RecordLeaveOfWork.Add' }"
          rounded
          color="app-theme"
          dark
          style="text-transform: none !important"
          width="220px"
        >
          <v-icon class="pr-2">mdi-plus</v-icon>
          <b> เพิ่มบันทึกการลา</b>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="items"
      class="elevation-1"
      :page.sync="page"
      :items-per-page="pageSize"
      @page-count="pageCount = $event"
      :header-props="{
        sortIcon: 'mdi-menu-up',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        'items-per-page-options': rowsPerPage,
      }"
    >
      <template v-slot:item.name="{ item }">
        <div class="text-left">{{ item.name }}</div>
      </template>
      <template v-slot:item.staffPosition="{ item }">
        <div class="text-left" style="padding-left: 20%">
          {{ item.staffPosition }}
        </div>
      </template>
      <template v-slot:item.leaveType="{ item }">
        <div class="text-left" style="padding-left: 30%">
          {{ item.leaveType }}
        </div>
      </template>
      <template v-slot:item.numberOfLeave="{ item }">
        <div class="text-left" style="padding-left: 30%">
          {{ item.numberOfLeave }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <router-link
          :to="{ name: 'RecordLeaveOfWork.Edit', params: { id: item.id } }"
          class="text-decoration-none mr-1"
        >
          <v-btn dark color="app-theme"><b>แก้ไข</b></v-btn>
        </router-link>
        <v-btn
          dark
          @click="deleteDialog(item.id)"
          color="app-danger"
          min-width="20"
          class="btn-p-10"
          ><v-icon color="app-primary">mdi-trash-can</v-icon></v-btn
        >
      </template>
      <template v-slot:footer> </template>
    </v-data-table>
    <DialogDelete :dialog.sync="showDialog" :deleteId="deleteId"></DialogDelete>
    <Pagination
      ref="Pagination"
      :page="page"
      :page-size="pageSize"
      @changePage="page = $event"
      @changePageSize="pageSize = $event"
    />
  </v-container>
</template>

<script>
import DialogDelete from "@/components/DialogDelete";
import Pagination from "@/components/Pagination";
import helper from "@/helpers/helper";
export default {
  components: {
    DialogDelete,
    Pagination,
  },

  data: () => ({
    showDialog: false,
    deleteId: null,
    helper: helper,
    dateStart: "",
    dateEnd: "",
    dateStartFormatted: "",
    dateEndFormatted: "",
    menuStart: false,
    menuEnd: false,

    page: 1,
    pageCount: 0,
    pageSize: 10,
    itemsPerPage: 10,
    rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
    headers: [
      {
        text: "วันที่",
        align: "center",
        value: "leaveDate",
        width: "15%",
      },
      { text: "ชื่อพนักงาน", align: "center", value: "name", width: "20%" },
      {
        text: "ตำแหน่ง",
        align: "center",
        value: "staffPosition",
        width: "20%",
      },
      {
        text: "ประเภทการลา",
        align: "center",
        value: "leaveType.name",
        width: "20%",
      },
      {
        text: "จำนวนวันที่ลา",
        align: "center",
        value: "numberOfLeave.name",
        width: "20%",
      },
      {
        text: "",
        sortable: false,
        align: "center",
        value: "actions",
        width: "180px",
      },
    ],
    items: [],
    staff: [],
  }),

  watch: {
    dateStart() {
      this.dateStartFormatted = this.helper.formatDate(this.dateStart);
      this.search();
    },
    dateEnd() {
      this.dateEndFormatted = this.helper.formatDate(this.dateEnd);
      this.search();
    },
    page() {
      this.search();
    },
    pageSize() {
      this.search();
    },
  },
  async mounted() {
    this.$store.state.title = "บันทึกการลาของพนักงาน";
    this.$store.state.showBackBtn = false;
    await this.getStaff();
    // await this.search();
  },
  methods: {
    downloadExcel() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("startDate", this.dateStart);
      formData.append("endDate", this.dateEnd);
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/leave/export",
          data: formData,
          responseType: "blob",
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `leave_${this.dateStart}_to_${this.dateEnd}`
          );
          document.body.appendChild(link);
          link.click();
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    search() {
      const formData = new FormData();
      if (!helper.isNullOrEmpty(this.dateStart)) {
        formData.append("startDate", this.dateStart || "");
      } else {
        formData.append("startDate", "0000-01-01" || "");
      }
      if (!helper.isNullOrEmpty(this.dateEnd)) {
        formData.append("endDate", this.dateEnd || "");
      } else {
        const now = helper.newDateFormatted();
        formData.append("endDate", helper.parseDateYMD(now) || "");
      }
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/leave/search",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                const staff = this.staff.find((w) => w.id === item.staffId);
                return {
                  id: item.id,
                  leaveDate: this.helper.formatShortDateTh(item.leaveDate),
                  name: staff ? staff.fullName : "",
                  staffPosition: staff ? staff.staffPosition.name : "",
                  leaveType: item.leaveType,
                  numberOfLeave: item.numberOfLeave,
                };
              });
            } else {
              this.items = [];
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data ? resp.data.data.length : 0
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    searchDate() {
      if (!this.$refs.form.validate()) return;
      const formData = new FormData();
      formData.append("startDate", this.dateStart || "");
      formData.append("endDate", this.dateEnd || "");
      this.$root
        .appApi({
          method: "POST",
          url: "admin/staff/leave/search",
          data: formData,
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                const staff = this.staff.find((w) => w.id === item.staffId);
                return {
                  id: item.id,
                  leaveDate: this.helper.formatShortDateTh(item.leaveDate),
                  name: staff ? staff.fullName : "",
                  staffPosition: staff ? staff.staffPosition.name : "",
                  leaveType: item.leaveType,
                  numberOfLeave: item.numberOfLeave,
                };
              });
            } else {
              this.items = [];
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data ? resp.data.data.length : 0
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    async getStaff() {
      this.$store.state.loading = true;
      await this.$root
        .appApi({ url: "admin/staff/all" })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.staff = entity.map((item) => {
                return {
                  id: item.id,
                  userId: item.id,
                  fullName: `${item.firstname} ${item.lastname}`,
                  email: item.email,
                  tel: item.tel,
                  staffPosition: item.staffPosition,
                  groupName: item.staffGroup,
                };
              });
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    async get() {
      this.$store.state.loading = true;
      await this.$root
        .appApi({
          url: "admin/staff/leave/all",
          params: { page: this.page, pageSize: this.pageSize },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const entity = resp.data.data;
            if (entity) {
              this.items = entity.map((item) => {
                const staff = this.staff.find((w) => w.id === item.staffId);
                return {
                  id: item.id,
                  leaveDate: this.helper.formatShortDateTh(item.leaveDate),
                  name: staff ? staff.fullName : "",
                  staffPosition: staff ? staff.staffPosition.name : "",
                  leaveType: item.leaveType,
                  numberOfLeave: item.numberOfLeave,
                };
              });
            } else {
              this.items = [];
            }
            this.$refs.Pagination.getPagination(
              resp.data.count,
              resp.data.data ? resp.data.data.length : 0
            );
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    confirmDelete(id) {
      this.$store.state.loading = true;
      this.$root
        .appApi({ method: "DELETE", url: "admin/staff/leave/" + id })
        .then((resp) => {
          const errors = resp.data.errors;
          if (errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            this.$root.getSystemMessage(resp.data.message);
          }
          this.get();
        })
        .catch((err) => {
          if (err.message) {
            this.$root.getErrorSystemMessage(err.message);
          } else {
            this.$root.getErrorSystemMessage(err);
          }
        });
    },
    deleteDialog(id) {
      this.showDialog = true;
      this.deleteId = id;
    },
  },
};
</script>

<style></style>
