<template>
  <v-app class="section-container">
    <v-row>
      <v-col cols="4" class="left">
        <span class="white--text login-text"> ลืมรหัสผ่าน </span>
      </v-col>
      <v-col cols="8" class="right">
        <v-form @submit.prevent="submit" ref="form">
          <v-row>
            <v-col cols="12" align="center">
              <v-img
                min-width="100"
                max-width="200"
                src="@/assets/ic_logo_login.svg"
              />
            </v-col>
            <v-col cols="12" class="mb-0 pb-0 mt-5 pt-10">
              <v-row class="justify-center">
                <v-col cols="8">
                  <v-text-field
                    color="app-theme"
                    height="48"
                    v-model="email"
                    placeholder="example@gmail.com"
                    required
                    outlined
                    dense
                    :rules="[
                      (v) => !!v || 'กรุณากรอก อีเมล',
                      (v) => /.+@.+/.test(v) || 'กรุณากรอก อีเมล',
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0">
              <v-row class="justify-center">
                <v-col cols="8">
                  <p class="text-black">
                    กรุณากรอกที่อยู่อีเมลของคุณเพื่อ Reset รหัสผ่านใหม่
                    ทางระบบจะส่งไปยังอีเมลของคุณ
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="my-5">
              <v-row class="justify-center">
                <v-btn
                  class="px-15 py-6"
                  type="submit"
                  rounded
                  color="app-theme"
                  dark
                >
                  ตกลง
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    email: "",
  }),
  methods: {
    submit() {
      this.email = this.email.trim();
      if (!this.$refs.form.validate()) return;

      this.$store.state.loading = true;
      const formData = new FormData();
      formData.append("email", this.email);
      formData.append(
        "callback",
        // "https://dev-ottoland-yard-backend.netlify.app/reset_password/"
        process.env.VUE_APP_CALL_BACK_FORGET_PASSWORD
      );

      this.$root
        .appApi({
          method: "POST",
          url: "admin/forgot-password",
          data: formData,
        })
        .then(() => {
          this.$root.getSystemMessage("ระบบส่งรหัสผ่านไปยังอีเมล์เรียบร้อย");
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.section-container {
  padding: 0;
  margin: 0;
  background: #fff;
  width: 100%;
}
.left {
  padding: 20px;
  padding-bottom: 7%;
  flex: start;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  background: var(--v-app-theme-base);
}
.right {
  padding-bottom: 10%;
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(--v-app-theme-base);
}
.login-text {
  margin: 70px;
  border-bottom: 1px solid #000;
  width: 80%;
  font-size: 48px;
  font-weight: bold;
  border-color: white;
}
.text-black {
  color: black;
}
</style>
